import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";

function Footer() {
  const [Contact, setContact] = useState([]);

  useEffect(() => {
    datas();
  }, []);

  const api = "https://api.odcards.com/";

  const datas = () => {
    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setContact(res.data.contactus[0]);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("Data was not getting");
          }
        }
      );
  };

  return (
    <div>
      <footer>
        <div className="cp-footer-wrap cp-bg-6 pt-50 pb-50">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="cp-footer-widget mb-50">
                  <h3 className="text-danger" style={{ paddingTop: "30px" }}>
                    <img src={api + Contact.logo} alt="logo" />
                  </h3>
                  <p className=" text-white" style={{ paddingTop: "30px" }}>
                    Printing for what’s to come. What’s more, we do it right! A
                    full administration printing Get the latest news, events
                    &amp; more delivered to your inbox.
                  </p>
                </div>
              </div>
              <div className="col-md-2">
                <div className="cp-footer-widget mb-50">
                  <h4
                    className="cp-footer-widget-title"
                    style={{ paddingLeft: "20px" }}
                  >
                    Company
                  </h4>

                  <ul className="cp-footer-widget-link">
                    <li>
                      <NavLink to="/">Home </NavLink>
                    </li>
                    <li>
                      <NavLink to="/aboutus">About Us  </NavLink>
                    </li>
                    <li>
                      <NavLink to="/product">Products</NavLink>
                    </li>
                    <li>
                      <NavLink to="/offers">Offers</NavLink>
                    </li>
                    <li>
                      <NavLink to="/contactus">Contact</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3">
                <div className="cp-footer-widget mb-50">
                  <h4
                    className="cp-footer-widget-title"
                    style={{ paddingLeft: "20px" }}
                  >
                    Our Terms
                  </h4>
                  <ul className="cp-footer-widget-link">
                  <li>
                      <NavLink to="/faqs">
                      Faq's
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/termsandconditions">
                        Terms & Conditions
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/privacypolicy">Privacy Policy</NavLink>
                    </li>
                    <li>
                      <NavLink to="/refundpolicy">Refund Policy</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3">
                <div className="cp-footer-widget mb-50">
                  <h4 className="cp-footer-widget-title">Contact Us</h4>
                  <ul className="cp-footer-widget-link">
                    <li className="text-white">{Contact.contactNumber1}</li>
                    <li className="text-white"> {Contact.email}</li>
                    <li className="text-white">{Contact.address}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cp-copy-right cp-footer-bg pt-35 pb-20">
          <div className="container">
            <div className="cp-copy-item-wrap d-flex align-items-center justify-content-center">
              <div className="cp-copy-item">
                <div className="cp-footer-logo mb-15">
                  <p className="mb-0 white-color ">© 2024 OD Cards , Inc. All Rights Reserved</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
