import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";

function Faqs() {
  const [isLoading, setIsLoading] = useState(true);

  const [AboutUs, setAboutUs] = useState([]);

  const [AboutUs1, setAboutUs1] = useState([]);

 

  useEffect(() => {
    datas();
  }, []);

  const api = "https://api.odcards.com/";

  const datas = () => {
    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setAboutUs(res.data.aboutus[0]);
            setAboutUs1(res.data.aboutus[0].points);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("Data was not getting");
          }
        }
      );
  };
  return (
    <div>
      {isLoading == true ? (
        <>
          <div id="preloader">
            <div id="ctn-preloader" class="ctn-preloader">
              <div class="animation-preloader">
                <div class="spinner"></div>
                <div class="txt-loading">
                  <span data-text-preloader="O" class="letters-loading">
                    O
                  </span>
                  <span data-text-preloader="D" class="letters-loading">
                    D
                  </span>
                  <span data-text-preloader="C" class="letters-loading">
                    C
                  </span>
                  <span data-text-preloader="A" class="letters-loading">
                    A
                  </span>
                  <span data-text-preloader="R" class="letters-loading">
                    R
                  </span>
                  <span data-text-preloader="D" class="letters-loading">
                    D
                  </span>
                  <span data-text-preloader="S" class="letters-loading">
                    S
                  </span>
                </div>
              </div>
              <div class="loader">
                <div class="row">
                  <div class="col-3 loader-section section-left">
                    <div class="bg"></div>
                  </div>
                  <div class="col-3 loader-section section-left">
                    <div class="bg"></div>
                  </div>
                  <div class="col-3 loader-section section-right">
                    <div class="bg"></div>
                  </div>
                  <div class="col-3 loader-section section-right">
                    <div class="bg"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Header />
          <main>
            <section
              className="page-title-area breadcrumb-spacing cp-bg-14"
              id="fdd"
            >
              <div className="container">
                <div className="row justify-content-start">
                  <div className="col-md-5">
                    <div
                      className="page-title-wrapper t-center blurred"
                      id="tor3"
                    >
                      <h2 className=" mb-10 text-white" id="tor">
                        About Us
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="cp-feature-area  pt-30 pb-85">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-6">
                    <div
                      className="cp-section-title t-center mb-10 mt-20 wow fadeInUp"
                      data-wow-duration="1.5s"
                      data-wow-delay="0.5s"
                    >
                      <h2 style={{ color: "#D73135" }}>About Us</h2>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-lg-3  col-sm-3">
                    <div
                      className="cp-feature-item two mb-40 wow fadeInUp"
                      data-wow-duration="1.5s"
                      data-wow-delay="0.5s"
                    >
                      <p className="cp-feature-text">{AboutUs.description}</p>
                    </div>
                  </div>

                  <div className="col-lg-6 col-sm-3">
                    <div
                      className="cp-feature3-img mb-30 wow fadeInUp"
                      data-wow-duration="1.5s"
                      data-wow-delay="0.5s"
                    >
                      <div>
                        <img
                          src={api + AboutUs.image1}
                          alt="feature"
                          className="yoop"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-3">
                    {AboutUs1.map((data, i) => (
                      <div
                        className="cp-feature-item two mb-30 wow fadeInUp animated"
                        data-wow-duration="1.5s"
                        data-wow-delay="0.8s"
                        key={i}
                      >
                        <h2 className="cp-feature-title mb-20">60+</h2>
                        <p className="cp-feature-text">{data.points}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </section>
          </main>
          <Footer />
        </>
      )}
    </div>
  );
}

export default Faqs;
