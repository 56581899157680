import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";

function Products() {
  const databyid = (data) => {
    sessionStorage.setItem("productdetailsid", data._id);
    window.location.href = "/productdetails";
  };

  const [isLoading, setIsLoading] = useState(true);

  const [Category, setCategory] = useState([]);

  const [ProductsSubCat, setProductsSubCat] = useState([]);

  const ids = sessionStorage.getItem("subproductid");

  useEffect(() => {
    datas();
  }, []);

  const datas = () => {
    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/getsubcategorybyid",
        { id: ids },
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setProductsSubCat(res.data.products);
            setCategory(res.data.subcategory);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
           console.log("Data was not getting")
          }
        }
      );
  };

  const api = "https://api.odcards.com/";

  return (
    <div>
      {isLoading == true ? (
        <>
          <div id="preloader">
            <div id="ctn-preloader" class="ctn-preloader">
              <div class="animation-preloader">
                <div class="spinner"></div>
                <div class="txt-loading">
                  <span data-text-preloader="O" class="letters-loading">
                    O
                  </span>
                  <span data-text-preloader="D" class="letters-loading">
                    D
                  </span>
                  <span data-text-preloader="C" class="letters-loading">
                    C
                  </span>
                  <span data-text-preloader="A" class="letters-loading">
                    A
                  </span>
                  <span data-text-preloader="R" class="letters-loading">
                    R
                  </span>
                  <span data-text-preloader="D" class="letters-loading">
                    D
                  </span>
                  <span data-text-preloader="S" class="letters-loading">
                    S
                  </span>
                </div>
              </div>
              <div class="loader">
                <div class="row">
                  <div class="col-3 loader-section section-left">
                    <div class="bg"></div>
                  </div>
                  <div class="col-3 loader-section section-left">
                    <div class="bg"></div>
                  </div>
                  <div class="col-3 loader-section section-right">
                    <div class="bg"></div>
                  </div>
                  <div class="col-3 loader-section section-right">
                    <div class="bg"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Header />
          <main>
            <section
              className="page-title-area breadcrumb-spacing cp-bg-14"
              id="fdd"
            >
              <div className="container">
                <div className="row justify-content-start">
                  <div className="col-md-5">
                    <div className="page-title-wrapper t-center blurred" id="tor3">
                      <h2 className=" mb-10 text-white" id="tor">{Category.name}</h2>
                      <p className="text-white"></p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="product-area pt-30 ">
              <div className="container">
                <div
                  className="cp-product-wrap mb-20 wow fadeInUp animated"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.3s"
                >
                  <div className="row align-items-center mb-20">
                    <div className=" col-md-4">
                      <h2 style={{ color: "#D73135" }}>{Category.name}</h2>
                    </div>
                  </div>

                  <div className="row">
                    {ProductsSubCat?.map((data, i) => (
                      <>
                        <div className="col-xl-3 col-lg-4 col-md-6 p-3" key={i}>
                          <div
                            className="cp-services-item t-center mb-30 wow fadeInUp animated"
                            data-wow-duration="1.5s"
                            data-wow-delay=".3s"
                          >
                            <div
                              className="cp-services-img w-img"
                              id="toapss"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                databyid(data);
                              }}
                            >
                              <img
                                src={api + data.image}
                                alt="img not found"
                                style={{ height: "320px" }}
                              />
                            </div>
                            <div className="product-description">
                              <h4 className="product-name">{data.name}</h4>
                              <div className="product-price">
                                <span className="price-now">
                                  100 Starting at ₹ 199
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </>
      )}
    </div>
  );
}

export default Products;
