import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";

function Faqs() {
  const [isLoading, setIsLoading] = useState(true);

  const [AboutUs, setAboutUs] = useState([]);

  const [AboutUs1, setAboutUs1] = useState([]);

  const [Faq, setFaq] = useState([]);

  useEffect(() => {
    datas();
  }, []);

  const api = "https://api.odcards.com/";

  const datas = () => {
    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setAboutUs(res.data.aboutus[0]);
            setAboutUs1(res.data.aboutus[0].points);
            setFaq(res.data.faqs);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("Data was not getting");
          }
        }
      );
  };
  return (
    <div>
      {isLoading == true ? (
        <>
          <div id="preloader">
            <div id="ctn-preloader" class="ctn-preloader">
              <div class="animation-preloader">
                <div class="spinner"></div>
                <div class="txt-loading">
                  <span data-text-preloader="O" class="letters-loading">
                    O
                  </span>
                  <span data-text-preloader="D" class="letters-loading">
                    D
                  </span>
                  <span data-text-preloader="C" class="letters-loading">
                    C
                  </span>
                  <span data-text-preloader="A" class="letters-loading">
                    A
                  </span>
                  <span data-text-preloader="R" class="letters-loading">
                    R
                  </span>
                  <span data-text-preloader="D" class="letters-loading">
                    D
                  </span>
                  <span data-text-preloader="S" class="letters-loading">
                    S
                  </span>
                </div>
              </div>
              <div class="loader">
                <div class="row">
                  <div class="col-3 loader-section section-left">
                    <div class="bg"></div>
                  </div>
                  <div class="col-3 loader-section section-left">
                    <div class="bg"></div>
                  </div>
                  <div class="col-3 loader-section section-right">
                    <div class="bg"></div>
                  </div>
                  <div class="col-3 loader-section section-right">
                    <div class="bg"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Header />
          <main>
            <section
              className="page-title-area breadcrumb-spacing cp-bg-14"
              id="fdd"
            >
              <div className="container">
                <div className="row justify-content-start">
                  <div className="col-md-5">
                    <div
                      className="page-title-wrapper t-center blurred"
                      id="tor3"
                    >
                      <h2 className=" mb-10 text-white" id="tor">
                       FAQ'S
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="cp-feature-area  pt-100 pb-85">
              <div className="cp-faq-shape-area">
                <div className="cp-faq-shape-img cp-faq-shape1 m-img p-absolute zi--1 cp-bg-move-x">
                  <img src="assets/img/faq/faq-shape-1.png" alt="shape" />
                </div>
                <div className="cp-faq-shape2 br-50 p-absolute cp-round-rotation1" />
                <div className="cp-faq-shape3 br-50 p-absolute cp-round-rotation2" />
                <div className="cp-faq-shape4 br-50 p-absolute cp-round-rotation1" />
                <div className="cp-faq-shape5 p-absolute d-none d-md-block cp-rotation">
                  <svg
                    width={77}
                    height={91}
                    viewBox="0 0 77 91"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M55.1403 75.1239C66.7298 66.6461 72.5829 54.9314 68.2136 48.9584C63.8442 42.9854 50.9071 45.0159 39.3176 53.4937C27.7281 61.9714 21.875 73.6862 26.2444 79.6592C30.6137 85.6322 43.5508 83.6017 55.1403 75.1239Z"
                      stroke="#FBD017"
                      strokeWidth="2.5"
                      strokeMiterlimit={10}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M24.8245 75.4001C24.8245 76.0001 24.9245 76.5001 25.0245 77.0001L1.72447 3.1001C1.52447 2.5001 2.22447 2.0001 2.72447 2.4001L66.1245 47.0001"
                      stroke="#FBD017"
                      strokeWidth="2.5"
                      strokeMiterlimit={10}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="cp-faq-shape6 br-50 p-absolute cp-round-rotation2" />
                <div className="cp-faq-shape7 br-50 p-absolute" />
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-md-5">
                    <div
                      className="cp-faq-img-wrap mb-20 wow fadeInLeft animated"
                      data-wow-delay="0.3s"
                    >
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="cp-faq-img-item p-relative w-img mb-30 js-tilt">
                            <div className="cp-img-overlay wow" />
                            <div
                              className="cp-img-blur "
                              style={{ height: "600px", width: "100%" }}
                            >
                              <img src={api + AboutUs.image2} alt="faq" />
                              <img src={api + AboutUs.image2} alt="faq" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="cp-faq-wrap cp-faq-space pl-60 pr-60 mb-50">
                      <div className="cp-faq-title-wrap mt--5">
                        <div className="cp-section-title mb-35">
                          <h2
                            className="cp-title wow fadeInUp animated"
                            data-wow-delay="0.4s"
                          >
                            Frequently <span>Asked</span> Questions
                          </h2>
                        </div>

                        <div className="accordion" id="accordionExample">
                          {Faq.map((data, i) => (
                            <div
                              className="accordion-item wow fadeInUp animated"
                              data-wow-delay={`0.${i + 6}s`}
                              key={i}
                            >
                              <h2
                                className="accordion-header"
                                id={`heading${i}`}
                              >
                                <button
                                  className="accordion-button"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#collapse${i}`}
                                  aria-expanded={i === 0 ? "true" : "false"} // Expand the first item by default
                                  aria-controls={`collapse${i}`}
                                >
                                  {data.question}
                                </button>
                              </h2>
                              <div
                                id={`collapse${i}`}
                                className={`accordion-collapse collapse ${
                                  i === 0 ? "show" : ""
                                }`}
                                aria-labelledby={`heading${i}`}
                                data-bs-parent="#accordionExample"
                              >
                                <div className="accordion-body">
                                  {data.answer}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
          <Footer />
        </>
      )}
    </div>
  );
}

export default Faqs;
