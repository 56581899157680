import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavLink } from "react-router-dom";
import ManageAddress from "./ManageAddress";
import ReactPaginate from "react-paginate";

function MyProfile() {
  const token = sessionStorage.getItem("token");

  const [Orders, setOrders] = useState([]);

  const MyOrders = () => {
    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/order/getuserorders",
        { status: "All" },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setOrders(res.data.order);
        }
      });
  };

  const [Orders2, setOrders2] = useState([]);

  const MyOrders2 = () => {
    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/order/getusercancelledorders",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setOrders2(res.data.order);
        }
      });
  };

  const databyidS = () => {
    sessionStorage.clear();
    window.location.href = "/";
  };

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  useEffect(() => {
    const userid = sessionStorage.getItem("token");
    const emailotp = sessionStorage.getItem("eamilVerfied");
    if (userid == null) {
      handleShow();
    } else if (emailotp == "yes") {
      handleShow1();
    } else if (emailotp == "no") {
      MyOrders();
      MyOrders2();
      GetProfile();
      GetPlans();
    }
  }, []);

  const [showOtpForm, setShowOtpForm] = useState(false);

  const [form, setform] = useState([]);

  const formsubmit = (e) => {
    e.preventDefault();
    Add();
  };

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const Add = () => {
    const dataArray = {
      phone: form.phone,
    };

    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/auth/logincheck",
        dataArray
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            sessionStorage.setItem("eamilotpid", res.data.data._id);
            sessionStorage.setItem("eamilVerfied", res.data.data.new_user);
            sessionStorage.setItem("mobilenos", res.data.data.phone);
            setform({
              phone: "",
            });
            setShowOtpForm(true);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const [form2, setform2] = useState([]);

  const formsubmit2 = (e) => {
    e.preventDefault();
    Add2();
  };

  const handleChange2 = (e) => {
    let myUser = { ...form2 };
    myUser[e.target.name] = e.target.value;
    setform2(myUser);
  };

  const emaildid = sessionStorage.getItem("eamilotpid");
  const emailotp = sessionStorage.getItem("eamilVerfied");

  const Add2 = () => {
    const dataArray = {
      emailOtp: form2.otp,
      _id: emaildid,
    };

    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/auth/compareotp",
        dataArray
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            sessionStorage.setItem("token", res.data.token);
            sessionStorage.setItem("userid", res.data.data._id);
            setShow(false);
            if (emailotp == "yes") {
              sessionStorage.setItem("token", res.data.token);
              handleShow1();
            }
            if (emailotp == "no") {
              window.location.reload();
            }
            setform({
              otp: "",
            });
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const api = "https://api.odcards.com/";

  const [form3, setform3] = useState([]);

  const [form4, setform4] = useState([]);

  const phone = sessionStorage.getItem("mobilenos");

  const GetProfile = () => {
    const userid = sessionStorage.getItem("userid");

    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/auth/getuserprofile",
        { userId: userid },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setform3(res.data.userData);
            setform4(res.data.userData);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("Data was not getting");
          }
        }
      );
  };

  const formsubmit3 = (e) => {
    e.preventDefault();
    Add3();
  };

  const handleChange3 = (e) => {
    let myUser = { ...form3 };
    myUser[e.target.name] = e.target.value;
    setform3(myUser);
  };

  const Add3 = () => {
    const dataArray = {
      firstName: form3.firstName,
      lastName: form3.lastName,
      phone: phone,
      gender: form3.gender,
      email: form3.email,
    };

    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/auth/userRegistration",
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )

      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            sessionStorage.setItem("eamilVerfied", "no");
            handleClose1();
            window.location.reload();
            setform({
              firstName: "",
              lastName: "",
              phone: "",
              gender: "",
              email: "",
            });
            GetProfile();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const formsubmit4 = (e) => {
    e.preventDefault();
    Add4();
  };

  const handleChange4 = (e) => {
    let myUser = { ...form4 };
    myUser[e.target.name] = e.target.value;
    setform4(myUser);
  };

  const Add4 = () => {
    const dataArray = {
      firstName: form4.firstName,
      lastName: form4.lastName,
      phone: form4.phone,
      email: form4.email,
      gender: form4.gender,
    };

    axios
      .put(
        "https://api.odcards.com/v1/odcardsapi/web/auth/edituserprofile",
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )

      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            GetProfile();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const [Plans, setPlans] = useState([]);

  const GetPlans = () => {
    const userid = sessionStorage.getItem("userid");

    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/subscription/myplans",
        { userId: userid },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setPlans(res.data.data);
        }
      });
  };

  const formlogin = () => {
    sessionStorage.clear();
    window.location.reload();
  };

  const [ins, setins] = useState(false);
  const [Instructions, setInstructions] = useState([]);
  const [InstructionsImage, setInstructionsImage] = useState([]);
  const [InstructionsAddress, setInstructionsAddress] = useState([]);
  const [Payments, setPayments] = useState([]);

  function inst() {
    setins(!ins);
  }

  const getinc = (data, datas) => {
    setInstructions(datas);
    setInstructionsImage(datas.orderImage);
    setInstructionsAddress(data.userAddress);
    setPayments(data.payment);
    inst();
  };

  const [ins1, setins1] = useState(false);
  const [Instructionss, setInstructionss] = useState([]);
  const [Instructionss2, setInstructionss2] = useState([]);

  function inst1() {
    setins1(!ins1);
  }

  const getinc1 = (data) => {
    setInstructionss(data);
    setInstructionss2(data.benefits);
    inst1();
  };

  const [listPerPage] = useState(5);
  const [pageNumber, setPageNumber] = useState(0);

  const pagesVisited = pageNumber * listPerPage;
  const lists = Orders.slice(pagesVisited, pagesVisited + listPerPage);
  const pageCount = Math.ceil(Orders.length / listPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [listPerPage1] = useState(5);
  const [pageNumber1, setPageNumber1] = useState(0);

  const pagesVisited1 = pageNumber1 * listPerPage1;
  const lists1 = Orders2.slice(pagesVisited1, pagesVisited1 + listPerPage1);
  const pageCount1 = Math.ceil(Orders2.length / listPerPage1);
  const changePage1 = ({ selected1 }) => {
    setPageNumber1(selected1);
  };

  const [Cancelled, setCancelled] = useState(false);
  const [cancel, setcancel] = useState([]);

  const Cancelledsubmit = (e) => {
    e.preventDefault();
    AddCancel();
  };

  const cancelle = (data) => {
    setcancel(data);
    cancelleds();
  };

  function cancelleds() {
    setCancelled(!Cancelled);
  }

  const handleChangeCancelled = (e) => {
    let myUser = { ...cancel };
    myUser[e.target.name] = e.target.value;
    setcancel(myUser);
  };

  const AddCancel = () => {
    const dataArray = {
      cancellationReason: cancel.cancellationReason,
    };

    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/order/cancellorder/" +
          cancel._id,
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )

      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            setCancelled(false);
            MyOrders2();
            MyOrders();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const handleDownload = (url) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "image.jpg"); // You can set a default filename here
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => console.error("Error downloading file:", error));
  };

  return (
    <div>
      <Header />
      <section className="cp-faq2-area pt-55 pb-80">
        <div className="container">
          <div className="row wow fadeInUp animated" data-wow-duration="1.5s">
            <div className="col-xl-4 col-lg-4 col-md-5">
              <div className="cp-faq2-left-wrap mb-70">
                <div className="cp-faq2-left-content mb-20">
                  <div className="card">
                    <div className="card-body">
                      <div class="cp-news-widget-post-item mb-30">
                        <div class="cp-news-widget-post-img br-img-50">
                          <a href="#">
                            <img src="assets/imgs.png" alt="news" />
                          </a>
                        </div>
                        <div class="cp-news-widget-post-text">
                          <span class="cp-news-widget-meta">Hello</span>
                          <h4>
                            <a href="#">
                              {form3.firstName}
                              {form3.lastName}
                            </a>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cp-faq2-cat-wrap">
                  <div class="cp-faq2-cat-list">
                    <div
                      class="nav"
                      id="v-pills-tab"
                      role="tablist"
                      aria-orientation="vertical"
                    >
                      <i
                        class="fa fa-user"
                        aria-hidden="true"
                        style={{ paddingRight: "5px", color: "#D83135" }}
                      ></i>
                      Account Settings
                      <button
                        class="nav-link active"
                        id="v-pills-efficient-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-efficient"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-efficient"
                        aria-selected="false"
                        style={{ paddingLeft: "25px", paddingTop: "8px" }}
                      >
                        Profile Information
                      </button>
                      <button
                        class="nav-link"
                        id="v-pills-efficient-tab1"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-efficient1"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-efficient1"
                        aria-selected="false"
                        style={{ paddingLeft: "25px" }}
                      >
                        Manage address
                      </button>
                      <i
                        className="fa fa-shopping-bag"
                        aria-hidden="true"
                        style={{ paddingRight: "5px", color: "#D83135" }}
                      ></i>
                      My Oders
                      <button
                        class="nav-link"
                        id="v-pills-shopping-tab1"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-shopping1"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-shopping1"
                        aria-selected="true"
                        style={{ paddingLeft: "25px", paddingTop: "8px" }}
                      >
                        {/* <i
                          className="fa fa-check-circle"
                          style={{ paddingRight: "5px" }}
                        /> */}
                        My Orders
                      </button>
                      <button
                        class="nav-link"
                        id="v-pills-shopping-tab3"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-shopping3"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-shopping3"
                        aria-selected="true"
                        style={{ paddingLeft: "25px" }}
                      >
                        {/* <i
                          className="fa fa-check-circle"
                          style={{ paddingRight: "5px" }}
                        /> */}
                        Cancelled
                      </button>
                      <button
                        class="nav-link"
                        id="v-pills-privacy-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-privacy"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-privacy"
                        aria-selected="false"
                      >
                        <i
                          className="fa fa-server"
                          style={{ paddingRight: "5px" }}
                        />
                        My Plans
                      </button>
                      <button
                        onClick={() => {
                          databyidS();
                        }}
                      >
                        <i
                          class="fa fa-sign-out"
                          aria-hidden="true"
                          style={{ paddingRight: "5px" }}
                        ></i>
                        Logout
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-7">
              <div className="cp-faq2-right-wrap mb-70">
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="v-pills-efficient"
                    role="tabpanel"
                    aria-labelledby="v-pills-efficient-tab"
                    tabindex="0"
                  >
                    <div class="cp-faq2-right-content-wrap">
                      <div class="cp-faq2-right-content-item">
                        <div className="card">
                          <div className="card-body">
                            <h4>Profile Information</h4>
                            <div className="cp-contact-form mt-50">
                              <form
                                onSubmit={(e) => {
                                  formsubmit4(e);
                                }}
                              >
                                <div className="row">
                                  <div className="col mt-30">
                                    <div className="cp-input-field">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="firstName"
                                        required
                                        placeholder="Enter First Name"
                                        onChange={(e) => {
                                          handleChange4(e);
                                        }}
                                        value={form4.firstName}
                                      />
                                    </div>
                                  </div>

                                  <div className="col mt-30">
                                    <div className="cp-input-field">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="lastName"
                                        required
                                        placeholder="Enter Last Name"
                                        onChange={(e) => {
                                          handleChange4(e);
                                        }}
                                        value={form4.lastName}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="cp-input-field mt-30">
                                  <select
                                    value={form4.gender}
                                    name="gender"
                                    onChange={(e) => {
                                      handleChange4(e);
                                    }}
                                    className="form-selects"
                                  >
                                    <option value="">Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                  </select>
                                </div>

                                <div className="cp-input-field mt-30">
                                  <input
                                    type="email"
                                    className="form-control"
                                    name="email"
                                    required
                                    placeholder="Enter Email"
                                    onChange={(e) => {
                                      handleChange4(e);
                                    }}
                                    value={form4.email}
                                  />
                                </div>

                                <div className="cp-input-field mt-30">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="phone"
                                    required
                                    maxlength="10"
                                    minLength="10"
                                    pattern="[0-9]{10}"
                                    placeholder="Enter Mobile Number"
                                    disabled
                                    onChange={(e) => {
                                      handleChange4(e);
                                    }}
                                    value={form4.phone}
                                  />
                                </div>

                                <button
                                  type="submit"
                                  className="cp-border-btn  mt-30 mb-30"
                                >
                                  Submit
                                  <span className="cp-border-btn__inner">
                                    <span className="cp-border-btn__blobs">
                                      <span className="cp-border-btn__blob" />
                                      <span className="cp-border-btn__blob" />
                                      <span className="cp-border-btn__blob" />
                                      <span className="cp-border-btn__blob" />
                                    </span>
                                  </span>
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade show"
                    id="v-pills-efficient1"
                    role="tabpanel"
                    aria-labelledby="v-pills-efficient-tab1"
                    tabindex="0"
                  >
                    <div class="cp-faq2-right-content-wrap">
                      <div class="cp-faq2-right-content-item">
                        <h4>Manage address</h4>
                        <ManageAddress />
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade show"
                    id="v-pills-shopping1"
                    role="tabpanel"
                    aria-labelledby="v-pills-shopping-tab1"
                    tabindex="0"
                  >
                    <div class="cp-faq2-right-content-wrap">
                      <div class="cp-faq2-right-content-item">
                        <h4>My Orders</h4>
                        {lists.map((data, i) => (
                          <>
                            <div className="card mt-4" key={i}>
                              <div className="card-body">
                                <div>
                                  <h5>
                                    Order Id :
                                    <span style={{ color: "#D83135" }}>
                                      {data.orderId}
                                    </span>
                                    | Order date :
                                    <span style={{ color: "#D83135" }}>
                                      {data.date}/{data.time}
                                    </span>
                                    | Total Amount :
                                    <span style={{ color: "#D83135" }}>
                                      {data.amount}
                                    </span>
                                  </h5>
                                </div>

                                {data.products.map((datas, is) => (
                                  <div className="row" key={is}>
                                    <div className="col-md-3">
                                      <div
                                        className="cp-services-item t-center mb-20 mt-20 wow fadeInUp animated"
                                        data-wow-duration="1.5s"
                                        data-wow-delay=".3s"
                                      >
                                        <div className="cp-services-img w-img">
                                          <img
                                            src={api + datas.productImage}
                                            alt="img not found"
                                            style={{
                                              height: "80px",
                                              width: "80px",
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-8">
                                      <div className="row mt-20">
                                        <div className="col">
                                          <h4 className="mt-20">
                                            {datas.productName}
                                          </h4>
                                        </div>
                                        <div className="col">
                                          <h5
                                            className="mt-20"
                                            style={{ float: "right" }}
                                          >
                                            Status :
                                            <span style={{ fontSize: "14px" }}>
                                              {data.status == "completed" ? (
                                                <>
                                                  <span className="badge bg-success ">
                                                    {data.status}
                                                  </span>
                                                </>
                                              ) : (
                                                <>
                                                  <span className="badge bg-danger ">
                                                    {data.status}
                                                  </span>
                                                </>
                                              )}
                                            </span>
                                          </h5>
                                        </div>
                                      </div>

                                      <div className="row mt-20">
                                        <div className="col">
                                          <span>
                                            Quantity :
                                            {datas.productvariationvalue}
                                          </span>
                                        </div>
                                        <div className="col">
                                          <span>
                                            Cost :{datas.productvariationprice}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="row mt-20">
                                        <div className="col">
                                          <button
                                            type="button"
                                            className="btn btn-outline-danger btn-sm m-1"
                                            style={{ float: "right" }}
                                            onClick={() => {
                                              getinc(data, datas);
                                            }}
                                          >
                                            View Details
                                          </button>

                                          {data.status == "pending" ? (
                                            <>
                                              <button
                                                type="button"
                                                className="btn btn-outline-warning btn-sm m-1"
                                                style={{ float: "right" }}
                                                onClick={() => {
                                                  cancelle(data);
                                                }}
                                              >
                                                Cancel Booking
                                              </button>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                      <hr></hr>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>

                            <div></div>
                          </>
                        ))}

                        <div className="mt-3" style={{ float: "right" }}>
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists.length}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade show"
                    id="v-pills-shopping3"
                    role="tabpanel"
                    aria-labelledby="v-pills-shopping-tab3"
                    tabindex="0"
                  >
                    <div class="cp-faq2-right-content-wrap">
                      <div class="cp-faq2-right-content-item">
                        <h4>Cancelled</h4>
                        {lists1.map((data, i) => (
                          <>
                            <div className="card mt-4" key={i}>
                              <div className="card-body">
                                <div>
                                  <h5>
                                    Order Id :
                                    <span style={{ color: "#D83135" }}>
                                      {data.orderId}
                                    </span>
                                    | Order date :
                                    <span style={{ color: "#D83135" }}>
                                      {data.date}/{data.time}
                                    </span>
                                    | Total Amount :
                                    <span style={{ color: "#D83135" }}>
                                      {data.amount}
                                    </span>
                                  </h5>
                                </div>

                                {data.products.map((datas, is) => (
                                  <div className="row" key={is}>
                                    <div className="col-md-3">
                                      <div
                                        className="cp-services-item t-center mb-20 mt-20 wow fadeInUp animated"
                                        data-wow-duration="1.5s"
                                        data-wow-delay=".3s"
                                      >
                                        <div className="cp-services-img w-img">
                                          <img
                                            src={api + datas.productImage}
                                            alt="img not found"
                                            style={{
                                              height: "80px",
                                              width: "80px",
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-8">
                                      <div className="row mt-20">
                                        <div className="col">
                                          <h4 className="mt-20">
                                            {datas.productName}
                                          </h4>
                                        </div>
                                        <div className="col">
                                          <h5
                                            className="mt-20"
                                            style={{ float: "right" }}
                                          >
                                            Status :
                                            <span style={{ fontSize: "14px" }}>
                                              {data.status == "completed" ? (
                                                <>
                                                  <span className="badge bg-success ">
                                                    {data.status}
                                                  </span>
                                                </>
                                              ) : (
                                                <>
                                                  <span className="badge bg-danger ">
                                                    {data.status}
                                                  </span>
                                                </>
                                              )}
                                            </span>
                                          </h5>
                                        </div>
                                      </div>

                                      <div className="row mt-20">
                                        <div className="col">
                                          <span>
                                            Quantity :
                                            {datas.productvariationvalue}
                                          </span>
                                        </div>
                                        <div className="col">
                                          <span>
                                            Cost :{datas.productvariationprice}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="row mt-20">
                                        <div className="col">
                                          <button
                                            type="button"
                                            className="btn btn-outline-danger btn-sm"
                                            style={{ float: "right" }}
                                            onClick={() => {
                                              getinc(data, datas);
                                            }}
                                          >
                                            View Details
                                          </button>
                                        </div>
                                      </div>
                                      <hr></hr>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>

                            <div></div>
                          </>
                        ))}

                        <div className="mt-3" style={{ float: "right" }}>
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            pageCount={pageCount1}
                            onPageChange={changePage1}
                            containerClassName={"pagination"}
                            previousLinkClassName={"previousBttn"}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            total={lists1.length}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade"
                    id="v-pills-privacy"
                    role="tabpanel"
                    aria-labelledby="v-pills-privacy-tab"
                    tabindex="0"
                  >
                    <div class="cp-faq2-right-content-wrap">
                      <div class="cp-faq2-right-content-item">
                        <h4>Subscribed Plans</h4>

                        <div className="row align-items-center">
                          {Plans.map((data, key) => {
                            return (
                              <div className="col-md-6 mt-4" key={key}>
                                <div className="card">
                                  <div className="card-Body">
                                    <div className="cp-plan2-item white-bg mb-40">
                                      <div
                                        className="cp-plan-item-img"
                                        data-background="assets/img/plan/plan.png"
                                      />
                                      <div className="cp-plan2-header">
                                        <div className="cp-plan2-icon">
                                          <i className="far fa-crown" />
                                        </div>
                                        <div className="cp-plan2-duration">
                                          <h3 className="cp-plan2-title">
                                            {data.name}
                                          </h3>
                                          <h5 className="cp-plan2-title mt-3 text-center">
                                            <button
                                              type="button"
                                              className="btn-sm btn-dark"
                                              onClick={() => {
                                                getinc1(data);
                                              }}
                                            >
                                              planDetails
                                            </button>
                                          </h5>
                                        </div>
                                      </div>
                                      <div className="cp-plan2-body">
                                        <div className="cp-plan2-list">
                                          <ul>
                                            {data.benefits.map((datas, i) => (
                                              <li key={i}>{datas.benefits}</li>
                                            ))}
                                          </ul>
                                        </div>
                                      </div>

                                      <h3 className="text-center">
                                        Plan Price: ₹ {data.price} /-
                                      </h3>

                                      <div className="cp-plan-btn pt-4">
                                        <button
                                          type="button"
                                          className="cp-border-btn"
                                          style={{
                                            width: "100%",
                                          }}
                                        >
                                          Subscribed
                                          <span className="cp-border-btn__inner">
                                            <span className="cp-border-btn__blobs">
                                              <span className="cp-border-btn__blob" />
                                              <span className="cp-border-btn__blob" />
                                              <span className="cp-border-btn__blob" />
                                              <span className="cp-border-btn__blob" />
                                            </span>
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal show={show} size="lg" centered>
        <div className="container">
          <div className="row">
            <div className="col-md-6" id="opp">
              <h2
                className="text-white"
                style={{ paddingTop: "80px", paddingLeft: "50px" }}
              >
                Login
              </h2>
              <p className="text-white" style={{ paddingLeft: "50px" }}>
                Get access to your Orders, Wishlist and Recommendations
              </p>
              <img
                src="assets/img/jkl.png"
                style={{ width: "70%", margin: "40px" }}
              ></img>
            </div>
            <div className="col-md-6" id="opps">
              <div className="cp-signin-wrap">
                {!showOtpForm && (
                  <form
                    onSubmit={(e) => {
                      formsubmit(e);
                    }}
                  >
                    <div className=" mt-40">
                      <label htmlFor="name">Enter Mobile Number</label>
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        required
                        maxlength="10"
                        minLength="10"
                        pattern="[0-9]{10}"
                        placeholder="Enter Mobile Number"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        value={form.phone}
                      />

                      <small style={{ fontSize: "11px", marginTop: "20px" }}>
                        By continuing, you to OD Card’s
                        <span
                          style={{
                            color: "blue",
                          }}
                        >
                          <NavLink
                            to="/termsandconditions"
                            style={{
                              margin: "2px",
                            }}
                          >
                            Terms & Conditions
                          </NavLink>
                        </span>
                        <br></br>
                        and
                        <span
                          style={{
                            color: "blue",
                            margin: "2px",
                          }}
                        >
                          <NavLink to="/privacypolicy">Privacy policy</NavLink>
                        </span>
                      </small>
                    </div>

                    <div className="cp-input-field sign-flex checkbox d-flex align-items-center justify-content-between"></div>
                    <div className="cp-sign-btn mt-35 mb-30">
                      <button
                        type="submit"
                        className="cp-border-btn"
                        style={{ width: "100%" }}
                      >
                        Request OTP
                      </button>
                    </div>
                  </form>
                )}

                {showOtpForm && (
                  <form
                    onSubmit={(e) => {
                      formsubmit2(e);
                    }}
                  >
                    <div className=" mt-40">
                      <label htmlFor="name">Enter OTP</label>
                      <input
                        type="text"
                        className="form-control"
                        name="otp"
                        required
                        maxlength="6"
                        minLength="6"
                        pattern="[0-9]{6}"
                        placeholder="Enter OTP"
                        onChange={(e) => {
                          handleChange2(e);
                        }}
                        value={form2.otp}
                      />

                      <small style={{ fontSize: "11px", marginTop: "20px" }}>
                        By continuing, you to OD Card’s
                        <span
                          style={{
                            color: "blue",
                          }}
                        >
                          <NavLink
                            to="/termsandconditions"
                            style={{
                              margin: "2px",
                            }}
                          >
                            Terms & Conditions
                          </NavLink>
                        </span>
                        <br></br>
                        and
                        <span
                          style={{
                            color: "blue",
                            margin: "2px",
                          }}
                        >
                          <NavLink to="/privacypolicy">Privacy policy</NavLink>
                        </span>
                      </small>
                    </div>

                    <div className="cp-input-field sign-flex checkbox d-flex align-items-center justify-content-between"></div>
                    <div className="cp-sign-btn mt-35 mb-30">
                      <button
                        type="submit"
                        className="cp-border-btn"
                        style={{ width: "100%" }}
                      >
                        Verify
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal show={show1} size="lg" centered>
        <div className="container">
          <div className="row">
            <div className="col-md-6" id="opp">
              <h2
                className="text-white"
                style={{ paddingTop: "80px", paddingLeft: "50px" }}
              >
                Register
              </h2>
              <p className="text-white" style={{ paddingLeft: "50px" }}>
                Get access to your Orders, Wishlist and Recommendations
              </p>
              <img
                src="assets/img/jkl.png"
                style={{ width: "70%", margin: "50px" }}
              ></img>
            </div>
            <div className="col-md-6" id="opps">
              <div className="cp-signin-wrap">
                <form
                  onSubmit={(e) => {
                    formsubmit3(e);
                  }}
                >
                  <div className=" mt-40">
                    <div className="mb-10">
                      <label htmlFor="name">Enter First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="firstName"
                        required
                        placeholder="Enter First Name"
                        onChange={(e) => {
                          handleChange3(e);
                        }}
                        value={form3.firstName}
                      />
                    </div>

                    <div className="mb-10">
                      <label htmlFor="name">Enter Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="lastName"
                        required
                        placeholder="Enter Last Name"
                        onChange={(e) => {
                          handleChange3(e);
                        }}
                        value={form3.lastName}
                      />
                    </div>

                    <div className="mb-3">
                      <label for="basicpill-firstname-input1">
                        Gender
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        value={form3.gender}
                        name="gender"
                        onChange={(e) => {
                          handleChange3(e);
                        }}
                        className="form-select"
                      >
                        <option value="">Select</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    </div>

                    <div className="mb-10">
                      <label htmlFor="name">Enter Mobile Number</label>
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        required
                        maxlength="10"
                        minLength="10"
                        pattern="[0-9]{10}"
                        placeholder="Enter Mobile Number"
                        disabled
                        value={phone}
                      />
                    </div>

                    <div className="mb-10">
                      <label htmlFor="name">Enter Email</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        required
                        placeholder="Enter Email"
                        onChange={(e) => {
                          handleChange3(e);
                        }}
                        value={form3.email}
                      />
                    </div>

                    <small style={{ fontSize: "11px", marginTop: "20px" }}>
                      By continuing, you to OD Card’s
                      <span
                        style={{
                          color: "blue",
                        }}
                      >
                        <NavLink
                          to="/termsandconditions"
                          style={{
                            margin: "2px",
                          }}
                        >
                          Terms & Conditions
                        </NavLink>
                      </span>
                      <br></br>
                      and
                      <span
                        style={{
                          color: "blue",
                          margin: "2px",
                        }}
                      >
                        <NavLink to="/privacypolicy">Privacy policy</NavLink>
                      </span>
                    </small>
                  </div>
                  <div className="text-center">
                    <span
                      onClick={() => {
                        formlogin();
                      }}
                      style={{
                        color: "blue",
                        margin: "2px",
                        cursor: "pointer",
                        fontSize: "14px",
                        color: "red",
                      }}
                    >
                      Change Number
                    </span>
                  </div>

                  <div className="cp-input-field sign-flex checkbox d-flex align-items-center justify-content-between"></div>
                  <div className="cp-sign-btn mt-35 mb-30">
                    <button
                      type="submit"
                      className="cp-border-btn"
                      style={{ width: "100%" }}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal show={ins} size="lg" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="mySmallModalLabel">
            View :-
          </h5>
          <button
            onClick={() => {
              setins(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h5 className="mb-2 text-primary">Order Address</h5>
          <div className="row m-1 mb-30">
            <div className="col-md-12 pt-20">
              <p>
                <div className="row">
                  <div className="col-md-6">
                    Business Name :
                    <span style={{ color: "#D83135" }}>
                      {InstructionsAddress.businessName}
                    </span>
                  </div>
                  <div className="col-md-6 ">
                    Gst Number :
                    <span style={{ color: "#D83135" }}>
                      {InstructionsAddress.gstNumber}
                    </span>
                  </div>
                  <div className="col-md-6 mt-4">
                    Alternate Phone Number :
                    <span style={{ color: "#D83135" }}>
                      {InstructionsAddress.alternatePhone}
                    </span>
                  </div>
                  <div className="col-md-6 mt-4">
                    Address :
                    <span style={{ color: "#D83135" }}>
                      {InstructionsAddress.address}
                    </span>
                  </div>
                </div>
              </p>
              <div className="col-md-6 mt-4"></div>
              <p>
                LandMark :
                <span style={{ color: "#D83135" }}>
                  {InstructionsAddress.landmark}
                </span>
                , City :
                <span style={{ color: "#D83135" }}>
                  {InstructionsAddress.city}
                </span>
                 - Pincode :
                <span style={{ color: "#D83135" }}>
                  {InstructionsAddress.pincode}
                </span>
              </p>
            </div>
          </div>
          <hr></hr>

          <h5 className="mb-2 text-primary">Payments Details : </h5>
          <div className="row m-1 mb-10">
            <div className="col-md-12 pt-20">
              <div className="row">
                <div className="col-md-6">
                  Transaction Id :
                  <span style={{ color: "#D83135" }}>
                    {Payments.transactionId}
                  </span>
                </div>
                <div className="col-md-6">
                  Coupon Code :
                  <span style={{ color: "#D83135" }}>
                    {Payments.couponCode}
                  </span>
                </div>
                <div className="col-md-6 mt-3">
                  Coupon Discount :
                  <span style={{ color: "#D83135" }}>
                    {Payments.couponDiscount}
                  </span>
                </div>
                <div className="col-md-6 mt-3">
                  Delivery :<span style={{ color: "#D83135" }}>40</span>
                </div>
                <div className="col-md-6  mt-3">
                  Total Amount :
                  <span style={{ color: "#D83135" }}>
                    {Payments.totalAmount}
                  </span>
                </div>
                <div className="col-md-6  mt-3">
                  Status :
                  <span style={{ color: "#D83135" }}>{Payments.status}</span>
                </div>
              </div>
            </div>
          </div>
          <hr></hr>

          {Instructions.status == "cancelled" ? (
            <>
              <h5 className="mb-2 text-primary">Refund Amount : </h5>
              <div className="row m-1 mb-10">
                <div className="col-md-12 pt-20">
                  <div className="row">
                    <div className="col-md-6">
                      Refund Type :
                      <span style={{ color: "#D83135" }}>
                        {Instructions.refundType}
                      </span>
                    </div>
                    <div className="col-md-6">
                      Cancelled Transaction Id :
                      <span style={{ color: "#D83135" }}>
                        {Instructions.cancelledTransactionId}
                      </span>
                    </div>
                    <div className="col-md-6">
                      Cancellation Charges :
                      <span style={{ color: "#D83135" }}>
                        {Instructions.cancellationCharges}
                      </span>
                    </div>
                    <div className="col-md-6">
                      Refund Status :
                      <span style={{ color: "#D83135" }}>
                        {Instructions.cancellationStatus}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          <div>
            <div>
              <Row>
                <Col lg={4}>
                  <ul className="list-unstyled vstack gap-2 mb-0">
                    <h5 className="mb-2 text-primary">Order Image </h5>

                    {InstructionsImage.map((file, index) => (
                      <>
                        <a
                          href={api + file}
                          target="_blank"
                          rel="noreferrer"
                          key={index}
                        >
                          <img
                            src={api + file}
                            height="140px"
                            width="100%"
                          ></img>
                        </a>
                        <button
                          onClick={() => handleDownload(api + file)}
                          className="btn-success"
                        >
                          Download File
                        </button>
                      </>
                    ))}
                  </ul>
                </Col>
                <Col lg={8}>
                  <div className="table-rep-plugin table-responsive">
                    <h5 className="mb-4 text-primary">Order Details</h5>
                    <Table hover bordered responsive>
                      <tbody>
                        <tr>
                          <th>Catagory Name</th>
                          <td>{Instructions.categoryName}</td>
                        </tr>
                        <tr>
                          <th>Sub Category Name</th>
                          <td>{Instructions.subcategoryName}</td>
                        </tr>
                        <tr>
                          <th>Product Image</th>
                          <td>
                            <img
                              src={api + Instructions.productImage}
                              height="50px"
                            ></img>
                          </td>
                        </tr>
                        <tr>
                          <th>Product Name</th>
                          <td>{Instructions.productName}</td>
                        </tr>
                        <tr>
                          <th>Product Price </th>
                          <td>₹ {Instructions.productvariationprice}</td>
                        </tr>
                        {Instructions.productVariationSizes == "YES" ? (
                          <>
                            <tr>
                              <th>Product Height</th>
                              <td>{Instructions.productVariationHeight}</td>
                            </tr>
                            <tr>
                              <th>Product Width</th>
                              <td>{Instructions.productVariationWidth}</td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                        <tr>
                          <th>Product Quantity </th>
                          <td>Qnt : {Instructions.productvariationvalue} </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Modal>

      <Modal show={ins1} size="lg" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="mySmallModalLabel">
            View Plan Details :-
          </h5>
          <button
            onClick={() => {
              setins1(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div>
            <div>
              <Row>
                <Col lg={12}>
                  <div className="table-rep-plugin table-responsive">
                    <Table hover bordered responsive>
                      <tbody>
                        <tr className="text-center">
                          <th>Plan Name</th>
                          <td>{Instructionss.name}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Plan Validity</th>
                          <td>{Instructionss.validity}</td>
                        </tr>

                        <tr className="text-center">
                          <th>Plan Price</th>
                          <td>{Instructionss.price}</td>
                        </tr>
                        <tr className="text-center">
                          <th>Discount</th>
                          <td>
                            ₹
                            {Instructionss.discountType == "percentage" ? (
                              <>{Instructionss.discount} % </>
                            ) : (
                              <>{Instructionss.discount} /-</>
                            )}
                          </td>
                        </tr>

                        <tr className="text-center">
                          <th>Plan Benefits</th>
                          <td>
                            {Instructionss2.map((datas, i) => (
                              <p key={i}>{datas.benefits}</p>
                            ))}
                          </td>
                        </tr>
                        <tr className="text-center">
                          <th>Description</th>
                          <td>{Instructionss.description}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Modal>

      <Modal show={Cancelled} size="lg" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="mySmallModalLabel">
            View Plan Details :-
          </h5>
          <button
            onClick={() => {
              setCancelled(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="cp-signin-wrap">
                  <form
                    onSubmit={(e) => {
                      Cancelledsubmit(e);
                    }}
                  >
                    <div className="col-lg-12">
                      <div>
                        <label>Cancellation Reason :</label>
                        <textarea
                          type="text"
                          placeholder="Enter Cancellation Reason"
                          name="cancellationReason"
                          rows={5}
                          onChange={(e) => {
                            handleChangeCancelled(e);
                          }}
                          className="form-control mt-2"
                          required
                          value={cancel.cancellationReason}
                          id="cancellationReason"
                        />
                      </div>
                    </div>

                    <div
                      className="cp-sign-btn mt-35 mb-30"
                      style={{ float: "right" }}
                    >
                      <button type="submit" className="cp-border-btn">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <ToastContainer />
      <Footer />
    </div>
  );
}

export default MyProfile;
