import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import Whatsapp from "./Whatsapp";

function Home() {
  const [isLoading, setIsLoading] = useState(true);

  const databyid = (data) => {
    sessionStorage.setItem("productid", data._id);
    window.location.href = "/Products";
  };

  const [Sliders, setSliders] = useState([]);

  const [SlidersContent, setSlidersContent] = useState([]);

  const [AboutUs, setAboutUs] = useState([]);

  const [AboutUs1, setAboutUs1] = useState([]);

  const [ProductsCat, setProductsCat] = useState([]);

  const [LatestProducts, setLatestProducts] = useState([]);

  const [OffersProducts, setOffersProducts] = useState([]);

  const [Arrivals, setArrivals] = useState([]);

  useEffect(() => {
    datas();
  }, []);

  const api = "https://api.odcards.com/";

  const datas = () => {
    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setSliders(res.data.homesliders);
            setSlidersContent(res.data.homeimagesliders[0]);
            setAboutUs(res.data.aboutus[0]);
            setAboutUs1(res.data.aboutus[0].points);
            setProductsCat(res.data.categorys);
            setLatestProducts(res.data.popularProducts);
            setArrivals(res.data.latestproducts);
            setOffersProducts(res.data.productoffers);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("Data was not getting");
          }
        }
      );
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var settings1 = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    afterChange: function (index) {
      console.log(
        `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
      );
    },
  };

  var settings4 = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    afterChange: function (index) {
      console.log(
        `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
      );
    },
  };
  const [show, setShow] = useState(false);

  var navigate = useNavigate();

  const [form1, setform1] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    let myUser = { ...form1 };
    myUser[e.target.name] = e.target.value;
    setform1(myUser);
  };

  const databyids = (data) => {
    setform1(data);
    handleShow();
  };

  const formsubmit = (e) => {
    e.preventDefault();
    const uuid = Date.now();
    sessionStorage.setItem("uuid", parseFloat(uuid));
    Add();
  };

  const Add = () => {
    const carts = sessionStorage.getItem("cartitems");
    if (carts == "0" || carts == null) {
      const dataArray = {
        uniqueid: sessionStorage.getItem("uuid"),
        productId: form1._id,
        orders: form1.nooforders,
      };

      axios
        .post(
          "https://api.odcards.com/v1/odcardsapi/web/addcartitem",
          dataArray
        )

        .then(
          (res) => {
            if (res.status === 200) {
              sessionStorage.setItem("cartitems", "0");
              navigate(
                "/productdetails",
                sessionStorage.setItem("subproductid", form1._id)
              );
            }
          },
          (error) => {
            if (error.response && error.response.status === 400) {
              toast(error.response.data.message);
            }
          }
        );
    } else {
      toast("Your Cart Was Already Added");
    }
  };

  const userid = sessionStorage.getItem("token");

  return (
    <>
      {isLoading == true ? (
        <>
          <div id="preloader">
            <div id="ctn-preloader" class="ctn-preloader">
              <div class="animation-preloader">
                <div class="spinner"></div>
                <div class="txt-loading">
                  <span data-text-preloader="O" class="letters-loading">
                    O
                  </span>
                  <span data-text-preloader="D" class="letters-loading">
                    D
                  </span>
                  <span data-text-preloader="C" class="letters-loading">
                    C
                  </span>
                  <span data-text-preloader="A" class="letters-loading">
                    A
                  </span>
                  <span data-text-preloader="R" class="letters-loading">
                    R
                  </span>
                  <span data-text-preloader="D" class="letters-loading">
                    D
                  </span>
                  <span data-text-preloader="S" class="letters-loading">
                    S
                  </span>
                </div>
              </div>
              <div class="loader">
                <div class="row">
                  <div class="col-3 loader-section section-left">
                    <div class="bg"></div>
                  </div>
                  <div class="col-3 loader-section section-left">
                    <div class="bg"></div>
                  </div>
                  <div class="col-3 loader-section section-right">
                    <div class="bg"></div>
                  </div>
                  <div class="col-3 loader-section section-right">
                    <div class="bg"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Header />

          <main>
            <section className="">
              <div className="zi-500 p-relative">
                <div className="container">
                  <div className="cp-banner-content-space">
                    <div className="row align-items-center">
                      <div className="col-md-7 col-sm-6" id="ldd">
                        <div className="cp-banner-content">
                          <div>
                            <h2
                              className="cp-banner-title mb-30 wow fadeInUp animated"
                              data-wow-duration="1.5s"
                              data-wow-delay="1.1s"
                              style={{ color: "#74572D" }}
                            >
                              {SlidersContent.title}
                            </h2>
                            <p
                              className="cp-banner-text mb-30 wow fadeInUp animated"
                              data-wow-duration="1.5s"
                              data-wow-delay="1.4s"
                            >
                              {SlidersContent.description}
                            </p>
                            <div
                              className="cp-banner-btn cp-two-btn wow fadeInUp animated"
                              data-wow-duration="1.5s"
                              data-wow-delay="1.7s"
                            >
                              <NavLink
                                to="/myprofile"
                                className="cp-border-btn"
                                id="ldds"
                              >
                                <i
                                  class="fa fa-arrow-circle-right"
                                  aria-hidden="true"
                                  style={{ paddingRight: "10px" }}
                                ></i>
                                {userid != null ? (
                                  <>My Profile</>
                                ) : (
                                  <>Sign Up</>
                                )}

                                <span className="cp-border-btn__inner">
                                  <span className="cp-border-btn__blobs">
                                    <span className="cp-border-btn__blob" />
                                    <span className="cp-border-btn__blob" />
                                    <span className="cp-border-btn__blob" />
                                    <span className="cp-border-btn__blob" />
                                  </span>
                                </span>
                              </NavLink>
                            </div>
                          </div>
                          {/* <div className="row" id="ldds">
                            <div className="col-md-4">
                              <h1
                                style={{ textAlign: "end", paddingTop: "50px" }}
                                className="text-muted"
                              >
                                01
                              </h1>
                              <h6
                                className="text-center"
                                style={{
                                  borderRight: "15px solid yellow",
                                  borderLeft: "15px solid yellow",
                                }}
                              >
                                - 01/10 Next -
                              </h6>
                            </div>
                            <div className="col-md-6">
                              <div
                                style={{
                                  backgroundImage: `url(${
                                    api + SlidersContent.image1
                                  })`,
                                  backgroundSize: "cover",
                                  backgroundRepeat: "no-repeat",
                                  backgroundPosition: "center",
                                  borderRadius: "20px",
                                }}
                              >
                                <p
                                  style={{
                                    color: "white",
                                    paddingTop: "30px",
                                    paddingLeft: "10px",
                                    paddingBottom: "5px",
                                    fontSize: "20px",
                                  }}
                                >
                                  <i
                                    class="fa fa-chevron-circle-right"
                                    aria-hidden="true"
                                    style={{ paddingRight: "5px" }}
                                  ></i>
                                  Our Products
                                </p>
                                <h3
                                  style={{
                                    color: "white",
                                    paddingLeft: "60px",
                                  }}
                                >
                                  {SlidersContent.deliveryCount}
                                  <span style={{ fontSize: "20px" }}>
                                    Deliveries
                                  </span>
                                </h3>
                                <p
                                  style={{
                                    color: "white",
                                    paddingLeft: "200px",
                                    paddingTop: "20px",
                                    paddingBottom: "20px",
                                    textDecoration: "underline",
                                    fontSize: "20px",
                                  }}
                                >
                                  Explore
                                  <i
                                    class="fa fa-external-link-square"
                                    aria-hidden="true"
                                    style={{ paddingLeft: "5px" }}
                                  ></i>
                                </p>
                              </div>
                            </div>

                            <div className="col-md-2">
                              <div style={{ paddingTop: "100px" }}>
                                <img
                                  src={api + SlidersContent.image2}
                                  style={{ height: "100px", width: "100px" }}
                                />
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>

                      <div className="col-md-5 col-sm-6" id="lddk">
                        <Slider {...settings}>
                          {Sliders.map((data, i) => {
                            return (
                              <div className="w-img cp-banner-main-img">
                                <div key={i}>
                                  <img
                                    src={api + data.image}
                                    alt="banner"
                                    className="kyp"
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className=" mt-20">
              <div className="container">
                <div className="row pt-20">
                  <div className="col-md-12">
                    <div
                      className="cp-case-study-title"
                      style={{ textAlign: "center" }}
                    >
                      <div className="cp-section-title">
                        <h2 style={{ color: "#D73135" }} id="h20">
                          Explore All Categories
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <NavLink to="/product">
                      <p
                        type="button"
                        style={{
                          color: "#4D4D4D",
                          fontWeight: "bold",
                          float: "right",
                        }}
                      >
                        Know More{" "}
                        <i
                          class="fa fa-arrow-circle-right"
                          aria-hidden="true"
                          style={{ paddingRight: "10px" }}
                        ></i>
                      </p>
                    </NavLink>
                  </div>
                </div>

                <div className="row">
                  <Slider {...settings1}>
                    {ProductsCat.map((data, i) => (
                      <div className="col-md-3 p-2 pt-4" key={i}>
                        <div className="d-flex justify-content-center">
                          <div class="corners ">
                            <div class="top left"></div>
                            <div class="top right"></div>
                            <div class="bottom right"></div>
                            <div class="bottom left"></div>
                            <img
                              src={api + data.image}
                              alt="img not found"
                              style={{
                                height: "250px",
                                width: "100%",
                                cursor: "pointer",
                              }}
                              id="toaps1"
                              onClick={() => {
                                databyid(data);
                              }}
                            />
                          </div>
                        </div>

                        <div>
                          <p
                            className="text-center mt-3"
                            style={{ color: "black", fontWeight: "bold" }}
                          >
                            {data.name}
                          </p>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </section>

            {LatestProducts.length == 0 ? (
              ""
            ) : (
              <section className="mt-30" style={{ background: "#F9F9F9" }}>
                <div className="container pt-10 pb-30">
                  <div className="row pb-20 pt-20">
                    <div className="col-md-12">
                      <div
                        className="cp-case-study-title"
                        style={{ textAlign: "center" }}
                      >
                        <div className="cp-section-title">
                          <h2 style={{ color: "#D73135" }} id="h20">
                            Our Most Popular Products
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <NavLink to="/Ourmostpopularproducts">
                        <p
                          type="button"
                          style={{
                            color: "#4D4D4D",
                            fontWeight: "bold",
                            float: "right",
                          }}
                        >
                          Know More{" "}
                          <i
                            class="fa fa-arrow-circle-right"
                            aria-hidden="true"
                            style={{ paddingRight: "10px" }}
                          ></i>
                        </p>
                      </NavLink>
                    </div>
                  </div>
                  <div className="row">
                    {LatestProducts.slice(0, 4).map((data, i) => (
                      <div
                        className="col-md-3  p-2"
                        id={i == 0 ? "kklp1" : "kklp"}
                        key={i}
                      >
                        <div className="card" id="ops">
                          <div className="card-body"></div>
                          <p
                            className="text-center"
                            style={{ color: "black", fontWeight: "bold" }}
                          >
                            {data.name.slice(0, 20)}...
                            <span className="text-danger">
                              <img
                                src="assets/right.png"
                                alt="logo"
                                onClick={() => {
                                  databyids(data);
                                }}
                                style={{
                                  paddingLeft: "10px",
                                  height: "32px",
                                  cursor: "pointer",
                                }}
                              />
                            </span>
                          </p>
                        </div>
                        <div
                          className="cp-services-item t-center mb-30 wow fadeInUp animated"
                          id="opsd"
                          data-wow-duration="1.5s"
                          data-wow-delay=".4s"
                        >
                          <div className=" float-right">
                            <img
                              src={api + data.image}
                              alt="img not found"
                              style={{
                                height: "250px",
                                width: "250px",
                                cursor: "pointer",
                              }}
                              id="toaps"
                              onClick={() => {
                                databyids(data);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ))}{" "}
                  </div>
                </div>
              </section>
            )}

            {OffersProducts.length == 0 ? (
              ""
            ) : (
              <section className="pt-20 mb-30 ">
                <div className="container">
                  <div className="row  mb-30">
                    <div className="col-md-12">
                      <div
                        className="cp-case-study-title"
                        style={{ textAlign: "center" }}
                      >
                        <div className="cp-section-title">
                          <h2 style={{ color: "#D73135" }} id="h20">
                            Offers
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <NavLink to="/offers">
                        <p
                          type="button"
                          style={{
                            color: "#4D4D4D",
                            fontWeight: "bold",
                            float: "right",
                          }}
                        >
                          Know More{" "}
                          <i
                            class="fa fa-arrow-circle-right"
                            aria-hidden="true"
                            style={{ paddingRight: "10px" }}
                          ></i>
                        </p>
                      </NavLink>
                    </div>
                  </div>

                  <div className="row">
                    {OffersProducts.slice(0, 4).map((data, i) => (
                      <div
                        className="col-md-3  p-2"
                        id={i == 0 ? "kklp1" : "kklp"}
                        key={i}
                      >
                        <div className="card" id="ops">
                          <div className="card-body"></div>
                          <p
                            className="text-center"
                            style={{ color: "black", fontWeight: "bold" }}
                          >
                            {data.name.slice(0, 20)}...
                            <span className="text-danger">
                              <img
                                src="assets/right.png"
                                alt="logo"
                                onClick={() => {
                                  databyids(data);
                                }}
                                style={{
                                  paddingLeft: "10px",
                                  height: "32px",
                                  cursor: "pointer",
                                }}
                              />
                            </span>
                          </p>
                        </div>
                        <div
                          className="cp-services-item t-center mb-30 wow fadeInUp animated"
                          id="opsd"
                          data-wow-duration="1.5s"
                          data-wow-delay=".4s"
                        >
                          <div className=" float-right">
                            <img
                              src={api + data.image}
                              alt="img not found"
                              style={{
                                height: "250px",
                                width: "250px",
                                cursor: "pointer",
                              }}
                              id="toaps"
                              onClick={() => {
                                databyids(data);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            )}

            {Arrivals.length == 0 ? (
              ""
            ) : (
              <section className=" pt-30" style={{ background: "#F9F9F9" }}>
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        className="cp-case-study-title"
                        style={{ textAlign: "center" }}
                      >
                        <div className="cp-section-title">
                          <h2 style={{ color: "#D73135" }} id="h20">
                            New Arrivals
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <NavLink to="/newarrivals">
                        <p
                          type="button"
                          style={{
                            color: "#4D4D4D",
                            fontWeight: "bold",
                            float: "right",
                          }}
                        >
                          Know More{" "}
                          <i
                            class="fa fa-arrow-circle-right"
                            aria-hidden="true"
                            style={{ paddingRight: "10px" }}
                          ></i>
                        </p>
                      </NavLink>
                    </div>
                  </div>

                  <div className="row">
                    <Slider {...settings4}>
                      {Arrivals.map((data, i) => (
                        <div className="col-md-3 p-2" key={i}>
                          <div
                            className=" t-center mb-30 wow fadeInUp animated"
                            data-wow-duration="1.5s"
                            data-wow-delay=".3s"
                            style={{ background: "#D731354D" }}
                            id="toas"
                          >
                            <div className="w-img" id="toas">
                              <img
                                src={api + data.image}
                                alt="img not found"
                                style={{
                                  height: "250px",
                                  width: "250px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  databyids(data);
                                }}
                              />
                            </div>
                          </div>
                          <p
                            className="text-center"
                            style={{ color: "black", fontWeight: "bold" }}
                          >
                            {data.name}
                          </p>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </section>
            )}

            <section className="cp-feature-area pb-15">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-6">
                    <div
                      className="cp-section-title t-center mb-10 mt-20 wow fadeInUp"
                      data-wow-duration="1.5s"
                      data-wow-delay="0.5s"
                    >
                      <h2 style={{ color: "#D73135" }}>About Us</h2>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-lg-3  col-sm-3">
                    <div
                      className="cp-feature-item two mb-40 wow fadeInUp"
                      data-wow-duration="1.5s"
                      data-wow-delay="0.5s"
                    >
                      <p className="cp-feature-text">{AboutUs.description}</p>
                    </div>
                  </div>

                  <div className="col-lg-6 col-sm-3">
                    <div
                      className="cp-feature3-img mb-30 wow fadeInUp"
                      data-wow-duration="1.5s"
                      data-wow-delay="0.5s"
                    >
                      <div>
                        <img
                          src={api + AboutUs.image1}
                          alt="feature"
                          className="yoop"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-3">
                    {AboutUs1.map((data, i) => (
                      <div
                        className="cp-feature-item two mb-30 wow fadeInUp animated"
                        data-wow-duration="1.5s"
                        data-wow-delay="0.8s"
                        key={i}
                      >
                        <h2 className="cp-feature-title mb-20">60+</h2>
                        <p className="cp-feature-text">{data.points}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </section>
          </main>
          <Modal show={show} onHide={handleClose} size="md" centered>
            <Modal.Header closeButton>
              <Modal.Title>No Of Orders </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <form
                onSubmit={(e) => {
                  formsubmit(e);
                }}
              >
                <div className="col-md-12 mt-20">
                  <select
                    name="nooforders"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={form1.nooforders}
                    required
                    className="form-select mt-30"
                  >
                    <option value="">Select </option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>

                <div className="cp-input-field sign-flex checkbox d-flex align-items-center justify-content-between"></div>
                <div className="cp-sign-btn mt-35 mb-30">
                  <button
                    type="submit"
                    className="cp-border-btn"
                    style={{ width: "100%" }}
                  >
                    Proceed
                  </button>
                </div>
              </form>
            </Modal.Body>
          </Modal>
          <ToastContainer />
          <div class="cp-floating-area d-none d-md-block zi-1100 p-relative d-none">
            <div class="cp-floating-action cp-bg-move-y">
              <a
                href="https://web.whatsapp.com/send?phone=9988776655&amp;text="
                target="_blank"
                class="sbutton whatsapp "
                tooltip="WhatsApp"
              >
                <img src="assets/wh2.png" alt="news" height="62px" />
              </a>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
}

export default Home;
