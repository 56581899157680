import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

function ManageAddress() {
  const token = sessionStorage.getItem("token");

  const [show, setshow] = useState(false);

  const [form, setform] = useState([]);

  const [form3, setform3] = useState([]);
  const formsubmit3 = (e) => {
    e.preventDefault();
    Add3();
  };

  const handleChange3 = (e) => {
    let myUser = { ...form3 };
    myUser[e.target.name] = e.target.value;
    setform3(myUser);
  };

  const Add3 = () => {
    const dataArray = {
      businessName: form3.businessName,
      gstNumber: form3.gstNumber,
      addressType: form3.addressType,
      alternatePhone: form3.alternatePhone,
      landmark: form3.landmark,
      city: form3.city,
      pincode: form3.pincode,
      address: form3.address,
    };

    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/address/adduseraddress",
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )

      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            setshow(false);
            GetProfile();
            setform3({
              businessName: "",
              gstNumber: "",
              addressType: "",
              alternatePhone: "",
              landmark: "",
              city: "",
              pincode: "",
            });
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  useEffect(() => {
    const userid = sessionStorage.getItem("token");
    if (userid != null) {
      GetProfile();
    }
  }, []);

  const GetProfile = () => {
    const token = sessionStorage.getItem("token");

    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/address/getalluseraddress",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setform(res.data.userAddresses);
        }
      });
  };

  const manageDelete = (data) => {
    const confirmBox = window.confirm("Do you really want to Delete?");
    if (confirmBox === true) {
      DeleteBanner(data);
    }
  };
  const DeleteBanner = (data) => {
    var remid = data._id;
    axios
      .delete(
        "https://api.odcards.com/v1/odcardsapi/web/address/deleteuseraddress/" +
          remid,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            GetProfile();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  return (
    <div>
      {show == true ? (
        <div className="col-md-12">
          <div className="row wow fadeInUp animated" data-wow-duration="1.5s">
            <div className="col-md-12">
              <div className="card mt-20">
                <div className="card-body">
                  <div className="cp-checkout-left mb-30 mr-10">
                    <div className="cp-checkout-field-area">
                      <div className="cp-checkout-box mb-30">
                        <h6 className="cp-checkout-title">
                          Add Address Details
                        </h6>
                        <form
                          onSubmit={(e) => {
                            formsubmit3(e);
                          }}
                        >
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="cp-input-field">
                                <label htmlFor="phone">Business Name</label>
                                <input
                                  type="text"
                                  name="businessName"
                                  placeholder="Enter Business Name"
                                  onChange={(e) => {
                                    handleChange3(e);
                                  }}
                                  value={form3.businessName}
                                  id="businessName"
                                />
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="cp-input-field">
                                <label htmlFor="LandMark">Gst Number</label>
                                <input
                                  type="text"
                                  name="gstNumber"
                                  placeholder="Enter Gst Number"
                                  onChange={(e) => {
                                    handleChange3(e);
                                  }}
                                 
                                  value={form3.gstNumber}
                                  id="gstNumber"
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="cp-input-field ">
                                <label htmlFor="LandMark">Address Type</label>
                                <select
                                  value={form3.addressType}
                                  name="addressType"
                                  onChange={(e) => {
                                    handleChange3(e);
                                  }}
                                  className="form-selects"
                                >
                                  <option value="">Select </option>
                                  <option value="office">Office</option>
                                  <option value="home">Home</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="cp-input-field">
                                <label htmlFor="phone">
                                  Alternate Phone Number
                                </label>
                                <input
                                  type="text"
                                  maxlength="10"
                                  minLength="10"
                                  pattern="[0-9]{10}"
                                  name="alternatePhone"
                                  placeholder="Enter Alternate Phone Number"
                                  onChange={(e) => {
                                    handleChange3(e);
                                  }}
                                  value={form3.alternatePhone}
                                  id="alternatePhone"
                                />
                              </div>
                            </div>

                            <div className="col-lg-12">
                              <div className="cp-input-field">
                                <label htmlFor="LandMark">LandMark</label>
                                <input
                                  type="text"
                                  required
                                  name="landmark"
                                  placeholder="Enter LandMark"
                                  onChange={(e) => {
                                    handleChange3(e);
                                  }}
                                  value={form3.landmark}
                                  id="landmark"
                                />
                              </div>
                            </div>

                            <div className="col-lg-12">
                              <div className="cp-input-field">
                                <label htmlFor="address">Address</label>
                                <input
                                  type="text"
                                  placeholder="Enter Address"
                                  name="address"
                                  onChange={(e) => {
                                    handleChange3(e);
                                  }}
                                  required
                                  value={form3.address}
                                  id="address"
                                />
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="cp-input-field">
                                <label htmlFor="city">Town / City</label>
                                <input
                                  type="text"
                                  name="city"
                                  placeholder="Town / City"
                                  required
                                  onChange={(e) => {
                                    handleChange3(e);
                                  }}
                                  value={form3.city}
                                  id="city"
                                />
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="cp-input-field">
                                <label htmlFor="zip">Pin Code </label>
                                <input
                                  type="text"
                                  name="pincode"
                                  maxlength="6"
                                  minLength="6"
                                  pattern="[0-9]{6}"
                                  placeholder="ENTER Pin Code"
                                  onChange={(e) => {
                                    handleChange3(e);
                                  }}
                                  required
                                  value={form3.pincode}
                                  id="pincode"
                                />
                              </div>
                            </div>

                            <div className="col-lg-12">
                              <button
                                type="submit"
                                style={{ float: "right" }}
                                className="cp-border-btn  mt-30 mb-30"
                              >
                                Submit
                                <span className="cp-border-btn__inner">
                                  <span className="cp-border-btn__blobs">
                                    <span className="cp-border-btn__blob" />
                                    <span className="cp-border-btn__blob" />
                                    <span className="cp-border-btn__blob" />
                                    <span className="cp-border-btn__blob" />
                                  </span>
                                </span>
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="col-md-12">
        <div className="card mt-20">
          <div className="card-body">
            <div className="row m-1">
              <div
                className="col-md-1"
                type="button"
                onClick={() => {
                  setshow(!show);
                }}
              >
                <h4>
                  <i
                    class="fa fa-plus"
                    style={{ color: "#FF0000" }}
                    aria-hidden="true"
                  ></i>
                </h4>
              </div>
              <div className="col-md-10">
                <h4>Add Address</h4>
              </div>
            </div>
          </div>
        </div>

        {form.map((data, i) => (
          <div key={i}>
            <div className="card mt-4">
              <div className="card-body">
                <div className="row m-1">
                  <div className="col-md-8">
                    <button
                      className="btn btn-sm  btn-info text-white"
                      type="button"
                    >
                      {data.addressType}
                    </button>
                  </div>

                  <div className="col-md-2">
                    <div style={{ float: "right" }}>
                      <button
                        className="btn-sm btn btn-danger"
                        onClick={() => {
                          manageDelete(data);
                        }}
                      >
                        Remove
                      </button>
                    </div>
                  </div>

                  <div className="col-md-12 pt-20">
                    <p>
                      <div className="row">
                        <div className="col-md-6">
                          Business Name :{" "}
                          <span style={{ color: "#D83135" }}>
                            {data.businessName}
                          </span>
                        </div>
                        <div className="col-md-6 ">
                          Gst Number :{" "}
                          <span style={{ color: "#D83135" }}>
                            {data.gstNumber}
                          </span>
                        </div>
                        <div className="col-md-6 mt-4">
                          Alternate Phone Number :{" "}
                          <span style={{ color: "#D83135" }}>
                            {data.alternatePhone}
                          </span>
                        </div>
                        <div className="col-md-6 mt-4">
                          Address :{" "}
                          <span style={{ color: "#D83135" }}>
                            {data.address}
                          </span>
                        </div>
                      </div>
                    </p>
                    <p>
                      LandMark :{" "}
                      <span style={{ color: "#D83135" }}>{data.landmark}</span>{" "}
                      , City :{" "}
                      <span style={{ color: "#D83135" }}>{data.city}</span>  -
                      Pincode :{" "}
                      <span style={{ color: "#D83135" }}>{data.pincode}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ManageAddress;
