import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";

function TermsAndConditions() {
  const [isLoading, setIsLoading] = useState(true);

  const [Settings, setSettings] = useState([]);

  useEffect(() => {
    datas();
  }, []);

  const datas = () => {
    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setSettings(res.data.setting[0]);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("Data was not getting");
          }
        }
      );
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <div id="preloader">
            <div id="ctn-preloader" class="ctn-preloader">
              <div class="animation-preloader">
                <div class="spinner"></div>
                <div class="txt-loading">
                  <span data-text-preloader="O" class="letters-loading">
                    O
                  </span>
                  <span data-text-preloader="D" class="letters-loading">
                    D
                  </span>
                  <span data-text-preloader="C" class="letters-loading">
                    C
                  </span>
                  <span data-text-preloader="A" class="letters-loading">
                    A
                  </span>
                  <span data-text-preloader="R" class="letters-loading">
                    R
                  </span>
                  <span data-text-preloader="D" class="letters-loading">
                    D
                  </span>
                  <span data-text-preloader="S" class="letters-loading">
                    S
                  </span>
                </div>
              </div>
              <div class="loader">
                <div class="row">
                  <div class="col-3 loader-section section-left">
                    <div class="bg"></div>
                  </div>
                  <div class="col-3 loader-section section-left">
                    <div class="bg"></div>
                  </div>
                  <div class="col-3 loader-section section-right">
                    <div class="bg"></div>
                  </div>
                  <div class="col-3 loader-section section-right">
                    <div class="bg"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Header />
          <main>
            <section
              className="page-title-area breadcrumb-spacing cp-bg-14"
              id="fdd"
            >
              <div className="container">
                <div className="row justify-content-start">
                  <div className="col-md-5">
                    <div
                      className="page-title-wrapper t-center blurred"
                      id="tor3"
                    >
                      <h2 className=" mb-10 text-white" id="tor">
                        Privacy & Policy{" "}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="product-area pt-30 ">
              <div className="container">
                <div
                  className="cp-product-wrap mb-20 wow fadeInUp animated"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.3s"
                >
                  <div className="row align-items-center mb-20">
                    <div className=" col-md-12">
                      <h2 style={{ color: "#D73135" }}>Privacy & Policy </h2>
                      <div className="mb-50">
                        <div
                          className="mt-5"
                          dangerouslySetInnerHTML={{
                            __html: Settings.privacyPolicy,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Footer />{" "}
        </>
      )}
    </div>
  );
}

export default TermsAndConditions;
