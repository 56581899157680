import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Cart() {
  const [Products, setProducts] = useState([]);

  const [Price, setPrice] = useState([]);

  var navigate = useNavigate();

  useEffect(() => {
    const userid = sessionStorage.getItem("token");
    if (userid == null) {
      navigate("/myprofile");
    } else {
      datas();
    }
  }, []);

  const tokens = sessionStorage.getItem("token");

  const datas = () => {
    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/cart/getallcarts",
        {},
        {
          headers: { Authorization: `Bearer ${tokens}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setProducts(res.data.cartitems);
            const productlength = res.data.cartitems.length;
            const productlengths = parseFloat(productlength);
            sessionStorage.setItem("cartitems", productlengths);
            if (res.data.cartitems.length == 0) {
              sessionStorage.setItem("cartitems", "0");
            }
            setPrice(res.data);
            localStorage.setItem("totalprice", res.data.subTotal);
            localStorage.setItem("coupon_Id", "");
            localStorage.setItem("couponAmount", "");
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("Data was not getting");
          }
        }
      );
  };
  const api = "https://api.odcards.com/";

  const manageDelete = (data) => {
    const confirmBox = window.confirm("Do you really want to Delete?");
    if (confirmBox === true) {
      deletes(data);
    }
  };

  const deletes = (data) => {
    var remid = data._id;
    axios
      .delete(
        `https://api.odcards.com/v1/odcardsapi/web/cart/deletecart/${remid}`,
        {
          headers: { Authorization: `Bearer ${tokens}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            localStorage.setItem("coupon_Id", "");
            localStorage.setItem("couponAmount", "");
            datas();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const carts = sessionStorage.getItem("cartitems");

  return (
    <div>
      {" "}
      <Header />
      <section className="cp-cart-area pt-50 pb-50">
        <div className="container">
          {carts == "0" ? (
            <>
              <div className="text-center">
                <img src="assets/img/emty.jpg" height="500px"></img>
              </div>
            </>
          ) : (
            <div className="row wow fadeInUp animated" data-wow-duration="1.5s">
              <div className="col-xl-8">
                <h3>My Cart</h3>
                <div className="cp-cart-left mb-40 mr-10">
                  {Products.map((data, i) => (
                    <div className="card mt-30" key={i}>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-4">
                            <div
                              className="cp-services-item t-center  wow fadeInUp animated"
                              data-wow-duration="1.5s"
                              data-wow-delay=".3s"
                            >
                              <div className="cp-services-img w-img" id="toaps">
                                {data.image.map((file, index) => (
                                  <div key={index}>
                                    <img
                                      src={api + file}
                                      alt="img not found"
                                      style={{ height: "160px" }}
                                      className="mt-1 text-center"
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-8">
                            <h2 className="mt-80">{data.productName}</h2>
                            <div className="row mt-40">
                              <div className="col">
                                <p>Quantity :  <span style={{fontWeight:"bold",color:"black"}}>{data.quantity}</span></p>
                              </div>
                              <div className="col">
                                <p>Price : ₹  <span style={{fontWeight:"bold",color:"black"}}>{data.price} /-</span></p>
                              </div>
                            </div>
                            <div className="row mt-20">
                              <div className="col">
                                <p
                                  style={{
                                    textDecoration: "underline",
                                    color: "blue",
                                    cursor: "pointer",
                                  }}
                                ></p>
                              </div>
                              <div className="col">
                                <button
                                  className="btn-danger btn"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    manageDelete(data);
                                  }}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="col-xl-4 pt-40" style={{ background: "#F9F9F9" }}>
                <div className="card">
                  <div className="card-body">
                    <div className="m-3">
                      <h4 className="cp-cart-subtotal">Price Details</h4>
                      <div className="cp-cart-total d-flex align-items-center justify-content-between mb-20">
                        <h5>Price ({Products.length} Item)</h5>
                        <span>₹ {Price.subTotal}</span>
                      </div>

                      <div className="cp-cart-free d-flex align-items-center justify-content-between">
                        <h5>Delivery Charges</h5>
                        <span>₹ + {Price.deliveryCharges}</span>
                      </div>
                      <div className="cp-cart-f-total d-flex align-items-center justify-content-between mb-20">
                        <h6>Total</h6>
                        <span>₹ {Price.subTotal + Price.deliveryCharges}</span>
                      </div>

                      <div className="cp-cart-checkout-btn mt-30">
                        <NavLink
                          to="/Checkout"
                          className="cp-border-btn"
                          style={{ width: "100%" }}
                        >
                          Checkout
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      <ToastContainer />
      <Footer />
    </div>
  );
}

export default Cart;
