import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

function Products() {
  const [show, setShow] = useState(false);

  var navigate = useNavigate();

  const [form1, setform1] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    let myUser = { ...form1 };
    myUser[e.target.name] = e.target.value;
    setform1(myUser);
  };

  const databyid = (data) => {
    setform1(data);
    handleShow();
  };

  const formsubmit = (e) => {
    e.preventDefault();
    const uuid = Date.now();
    sessionStorage.setItem("uuid", parseFloat(uuid));
    Add();
  };

  const Add = () => {
    const carts = sessionStorage.getItem("cartitems");
    if (carts == "0" || carts == null) {
      const dataArray = {
        uniqueid: sessionStorage.getItem("uuid"),
        productId: form1._id,
        orders: form1.nooforders,
      };

      axios
        .post(
          "https://api.odcards.com/v1/odcardsapi/web/addcartitem",
          dataArray
        )

        .then(
          (res) => {
            if (res.status === 200) {
              sessionStorage.setItem("cartitems", "0");
              navigate(
                "/productdetails",
                sessionStorage.setItem("subproductid", form1._id)
              );
            }
          },
          (error) => {
            if (error.response && error.response.status === 400) {
              toast(error.response.data.message);
            }
          }
        );
    } else {
      toast("Your Cart Was Already Added");
    }
  };

  const token = sessionStorage.getItem("token");

  const [isLoading, setIsLoading] = useState(false);

  const [Category, setCategory] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const HealthScheme = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    GetProductDetails(selectedOptions.value);
  };

  useEffect(() => {
    const userid = sessionStorage.getItem("token");
    if (userid == null) {
      navigate("/myprofile");
    } else {
      datas();
    }
  }, []);

  const datas = () => {
    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/getproduct?searchQuery=",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setCategory(res.data.products);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            setIsLoading(false);
          }
        }
      );
  };

  const optionss = Category.map((response) => ({
    value: response._id,
    label: response.name,
  }));

  const [Price, setPrice] = useState([]);

  const GetProductDetails = (data) => {
    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/getvariations",
        { productId: data },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setPrice(res.data.variations);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("Data was not getting");
          }
        }
      );
  };

  const [form2, setform2] = useState([]);

  const handleChange2 = (e) => {
    let myUser = { ...form2 };
    myUser[e.target.name] = e.target.value;
    setform2(myUser);
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <div id="preloader">
            <div id="ctn-preloader" class="ctn-preloader">
              <div class="animation-preloader">
                <div class="spinner"></div>
                <div class="txt-loading">
                  <span data-text-preloader="O" class="letters-loading">
                    O
                  </span>
                  <span data-text-preloader="D" class="letters-loading">
                    D
                  </span>
                  <span data-text-preloader="C" class="letters-loading">
                    C
                  </span>
                  <span data-text-preloader="A" class="letters-loading">
                    A
                  </span>
                  <span data-text-preloader="R" class="letters-loading">
                    R
                  </span>
                  <span data-text-preloader="D" class="letters-loading">
                    D
                  </span>
                  <span data-text-preloader="S" class="letters-loading">
                    S
                  </span>
                </div>
              </div>
              <div class="loader">
                <div class="row">
                  <div class="col-3 loader-section section-left">
                    <div class="bg"></div>
                  </div>
                  <div class="col-3 loader-section section-left">
                    <div class="bg"></div>
                  </div>
                  <div class="col-3 loader-section section-right">
                    <div class="bg"></div>
                  </div>
                  <div class="col-3 loader-section section-right">
                    <div class="bg"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Header />
          <main>
            <section
              className="page-title-area breadcrumb-spacing cp-bg-14"
              id="fdd"
            >
              <div className="container">
                <div className="row justify-content-start">
                  <div className="col-md-5">
                    <div
                      className="page-title-wrapper t-center blurred"
                      id="tor3"
                    >
                      <h2 className=" mb-10 text-white" id="tor">
                        {"Price Calculator"}
                      </h2>
                      <p className="text-white"></p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="pricing__area cp-bg-19 pt-40 pb-60">
              <div className="container ">
                <h2 className="text-center pb-20">Price Calculator </h2>

                <div className="row justify-content-center align-items-center">
                  <div className=" col-md-6">
                    <div className="cp-plan2-item white-bg mb-40">
                      <div className="mb-3 ">
                        <label for="basicpill-firstname-input1">
                          Product <span className="text-danger">*</span>
                        </label>
                        <Select
                          options={optionss}
                          placeholder="Search A Product"
                          value={selectedOptions}
                          onChange={HealthScheme}
                          isSearchable={true}
                        />
                      </div>

                      <div className="col-md-12 mt-30">
                        <label for="basicpill-firstname-input1">
                          Quantity <span className="text-danger">*</span>
                        </label>
                        <select
                          name="priceId2"
                          required
                          className="form-select"
                          value={form2.priceId2}
                          onChange={(e) => {
                            handleChange2(e);
                          }}
                        >
                          <option value="">Select Quantity</option>
                          {Price.map((data, key) => {
                            return (
                              <option key={key} value={data.price}>
                                {data.value} Quantity
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      <div className="col-md-12 mt-50">
                        <button


                          type="submit"
                          className="cp-border-btn"
                          style={{ width: "100%",zIndex:"-999999999999999" }}
                        >
                          Price : {form2.priceId2}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>

          <Modal show={show} onHide={handleClose} size="md" centered>
            <Modal.Header closeButton>
              <Modal.Title>No Of Orders </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <form
                onSubmit={(e) => {
                  formsubmit(e);
                }}
              >
                <div className="col-md-12 mt-20">
                  <select
                    name="nooforders"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={form1.nooforders}
                    required
                    className="form-select mt-30"
                  >
                    <option value="">Select </option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>

                <div className="cp-input-field sign-flex checkbox d-flex align-items-center justify-content-between"></div>
                <div className="cp-sign-btn mt-35 mb-30">
                  <button
                    type="submit"
                    className="cp-border-btn"
                    style={{ width: "100%" }}
                  >
                    Proceed
                  </button>
                </div>
              </form>
            </Modal.Body>
          </Modal>
          <ToastContainer />
          <Footer />
        </>
      )}
    </div>
  );
}

export default Products;
