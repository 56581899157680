import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

function Cart() {
  const [Products, setProducts] = useState([]);

  const [Price, setPrice] = useState([]);

  var navigate = useNavigate();

  useEffect(() => {
    const userid = sessionStorage.getItem("token");
    if (userid == null) {
      navigate("/myprofile");
    } else {
      datas();
    }
  }, []);

  const tokens = sessionStorage.getItem("token");

  const datas = () => {
    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/cart/getallcarts",
        {},
        {
          headers: { Authorization: `Bearer ${tokens}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setProducts(res.data.cartitems);
            if (res.data.cartitems.length == 0) {
              navigate("/");
            }
            setPrice(res.data);
            localStorage.setItem("totalprice", res.data.subTotal);
            localStorage.setItem("coupon_Id", "");
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("Data was not getting");
          }
        }
      );
  };

  const token = sessionStorage.getItem("token");

  const [show, setshow] = useState(false);

  const [form, setform] = useState([]);

  const [form3, setform3] = useState([]);
  const formsubmit3 = (e) => {
    e.preventDefault();
    Add3();
  };

  const handleChange3 = (e) => {
    let myUser = { ...form3 };
    myUser[e.target.name] = e.target.value;
    setform3(myUser);
  };

  const Add3 = () => {
    const dataArray = {
      businessName: form3.businessName,
      gstNumber: form3.gstNumber,
      addressType: form3.addressType,
      alternatePhone: form3.alternatePhone,
      landmark: form3.landmark,
      city: form3.city,
      pincode: form3.pincode,
      address: form3.address,
    };

    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/address/adduseraddress",
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )

      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            setshow(false);
            GetProfile();
            setform3({
              businessName: "",
              gstNumber: "",
              addressType: "",
              alternatePhone: "",
              landmark: "",
              city: "",
              pincode: "",
              address: "",
            });
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  useEffect(() => {
    const userid = sessionStorage.getItem("token");
    if (userid != null) {
      GetProfile();
    }
  }, []);

  const GetProfile = () => {
    const token = sessionStorage.getItem("token");

    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/address/getalluseraddress",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setform(res.data.userAddresses);
        }
      });
  };

  const [selectedId, setSelectedId] = useState(null);

  console.log(selectedId);

  const handleCheckboxChange = (id) => {
    setSelectedId(id === selectedId ? null : id);
  };

  const databyid = () => {
    if (selectedId !== null) {
      sessionStorage.setItem("Adddressid", selectedId);
      navigate("/shipping");
    } else {
      toast("Please Select Address");
    }
  };

  return (
    <div>
      <Header />
      <section className="cp-cart-area pt-50 pb-50">
        <div className="container">
          <div className="row wow fadeInUp animated" data-wow-duration="1.5s">
            <div className="col-xl-8">
              <h3>Add Address</h3>
              {show == true ? (
                <div className="col-md-12">
                  <div
                    className="row wow fadeInUp animated"
                    data-wow-duration="1.5s"
                  >
                    <div className="col-md-12">
                      <div className="card mt-20">
                        <div className="card-body">
                          <div className="cp-checkout-left mb-30 mr-10">
                            <div className="cp-checkout-field-area">
                              <div className="cp-checkout-box mb-30">
                                <h6 className="cp-checkout-title">
                                  Add Address Details
                                </h6>
                                <form
                                  onSubmit={(e) => {
                                    formsubmit3(e);
                                  }}
                                >
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="cp-input-field">
                                        <label htmlFor="phone">
                                          Business Name
                                        </label>
                                        <input
                                          type="text"
                                          name="businessName"
                                          placeholder="Enter Business Name"
                                          onChange={(e) => {
                                            handleChange3(e);
                                          }}
                                          value={form3.businessName}
                                          id="businessName"
                                        />
                                      </div>
                                    </div>

                                    <div className="col-lg-6">
                                      <div className="cp-input-field">
                                        <label htmlFor="LandMark">
                                          Gst Number
                                        </label>
                                        <input
                                          type="text"
                                          name="gstNumber"
                                          placeholder="Enter Gst Number"
                                          onChange={(e) => {
                                            handleChange3(e);
                                          }}
                                          value={form3.gstNumber}
                                          id="gstNumber"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="cp-input-field ">
                                        <label htmlFor="LandMark">
                                          Address Type
                                        </label>
                                        <select
                                          value={form3.addressType}
                                          name="addressType"
                                          onChange={(e) => {
                                            handleChange3(e);
                                          }}
                                          className="form-selects"
                                        >
                                          <option value="">Select </option>
                                          <option value="office">Office</option>
                                          <option value="home">Home</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="cp-input-field">
                                        <label htmlFor="phone">
                                          Alternate Phone Number
                                        </label>
                                        <input
                                          type="text"
                                          maxlength="10"
                                          minLength="10"
                                          pattern="[0-9]{10}"
                                          name="alternatePhone"
                                          placeholder="Enter Alternate Phone Number"
                                          onChange={(e) => {
                                            handleChange3(e);
                                          }}
                                          value={form3.alternatePhone}
                                          id="alternatePhone"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="cp-input-field">
                                        <label htmlFor="LandMark">
                                          LandMark
                                        </label>
                                        <input
                                          type="text"
                                          required
                                          name="landmark"
                                          placeholder="Enter LandMark"
                                          onChange={(e) => {
                                            handleChange3(e);
                                          }}
                                          value={form3.landmark}
                                          id="landmark"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="cp-input-field">
                                        <label htmlFor="address">Address</label>
                                        <input
                                          type="text"
                                          placeholder="Enter Address"
                                          name="address"
                                          onChange={(e) => {
                                            handleChange3(e);
                                          }}
                                          required
                                          value={form3.address}
                                          id="address"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="cp-input-field">
                                        <label htmlFor="city">
                                          Town / City
                                        </label>
                                        <input
                                          type="text"
                                          name="city"
                                          placeholder="Town / City"
                                          required
                                          onChange={(e) => {
                                            handleChange3(e);
                                          }}
                                          value={form3.city}
                                          id="city"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="cp-input-field">
                                        <label htmlFor="zip">Pin Code </label>
                                        <input
                                          type="text"
                                          name="pincode"
                                          placeholder="ENTER Pin Code"
                                          onChange={(e) => {
                                            handleChange3(e);
                                          }}
                                          maxlength="6"
                                          minLength="6"
                                          pattern="[0-9]{6}"
                                          required
                                          value={form3.pincode}
                                          id="pincode"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <button
                                        type="submit"
                                        style={{ float: "right" }}
                                        className="cp-border-btn  mt-30 mb-30"
                                      >
                                        Submit
                                        <span className="cp-border-btn__inner">
                                          <span className="cp-border-btn__blobs">
                                            <span className="cp-border-btn__blob" />
                                            <span className="cp-border-btn__blob" />
                                            <span className="cp-border-btn__blob" />
                                            <span className="cp-border-btn__blob" />
                                          </span>
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="col-md-12">
                <div className="card mt-20">
                  <div className="card-body">
                    <div className="row m-1">
                      <div
                        className="col-md-1"
                        type="button"
                        onClick={() => {
                          setshow(!show);
                        }}
                      >
                        <h4>
                          <i class="fa fa-plus" aria-hidden="true"></i>
                        </h4>
                      </div>
                      <div className="col-md-10">
                        <h4>Add Address</h4>
                      </div>
                    </div>
                  </div>
                </div>

                {form.map((data, i) => (
                  <div key={i}>
                    <div className="card mt-4">
                      <div className="card-body">
                        <div className="row m-1">
                          <div className="col-md-8">
                            <button
                              className="btn-sm btn btn-warning"
                              style={{ color: "white" }}
                            >
                              {data.addressType}
                            </button>
                          </div>

                          <div className="col-md-4">
                            <div style={{ float: "right" }}>
                              <label>Select Address</label> <br></br>
                              <input
                                type="checkbox"
                                className="form-check-input"
                                style={{width:"20px" ,height:"20px" }}
                                checked={selectedId === data._id}
                                onChange={() => handleCheckboxChange(data._id)}
                              />
                            </div>
                          </div>

                          <div className="col-md-12 pt-20">
                            <p>
                              <div className="row">
                                <div className="col-md-6">
                                  Business Name :
                                  <span style={{ color: "#D83135" }}>
                                    {data.businessName}
                                  </span>
                                </div>
                                <div className="col-md-6 ">
                                  Gst Number :
                                  <span style={{ color: "#D83135" }}>
                                    {data.gstNumber}
                                  </span>
                                </div>
                                <div className="col-md-6 mt-4">
                                  Alternate Phone Number :
                                  <span style={{ color: "#D83135" }}>
                                    {data.alternatePhone}
                                  </span>
                                </div>
                              </div>
                            </p>
                            <p>
                              LandMark :
                              <span style={{ color: "#D83135" }}>
                                {data.landmark}
                              </span>
                              , City :
                              <span style={{ color: "#D83135" }}>
                                {data.city}
                              </span>
                               - Pincode :
                              <span style={{ color: "#D83135" }}>
                                {data.pincode}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-xl-4 pt-40" style={{ background: "#F9F9F9" }}>
              <div className="card">
                <div className="card-body">
                  <div className="m-3">
                    <h4 className="cp-cart-subtotal">Price Details</h4>
                    <div className="cp-cart-total d-flex align-items-center justify-content-between mb-20">
                      <h5>Price ({Products.length} Item)</h5>
                      <span>₹ {Price.subTotal}</span>
                    </div>

                    <div className="cp-cart-free d-flex align-items-center justify-content-between">
                      <h5>Delivery Charges</h5>
                      <span>₹ + {Price.deliveryCharges}</span>
                    </div>
                    <div className="cp-cart-f-total d-flex align-items-center justify-content-between mb-20">
                      <h6>Total</h6>
                      <span>₹ {Price.subTotal + Price.deliveryCharges}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cp-cart-checkout-btn mt-30">
                <button
                  type="button"
                  className="cp-border-btn"
                  style={{ width: "100%" }}
                  onClick={() => {
                    databyid();
                  }}
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
      <Footer />
    </div>
  );
}

export default Cart;
