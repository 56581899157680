import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";
import { toast, ToastContainer } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Products() {
  const [isLoading, setIsLoading] = useState(true);

  const [Plans, setPlans] = useState([]);

  const [Plans1, setPlans1] = useState([]);

  const token = sessionStorage.getItem("token");

  useEffect(() => {
    datas();
    datas1();
  }, []);

  const datas = () => {
    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/subscription/getsubscription",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setPlans(res.data.data);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("Data was not getting");
          }
        }
      );
  };

  const datas1 = () => {
    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/subscription/checksubscribe",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setPlans1(res.data.status);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("Data was not getting");
          }
        }
      );
  };

  const addPlan = (data) => {
    Plan(data);
  };

  const Plan = (data) => {
    let totals = parseFloat(data.price) * 100;

    const options = {
      key: "rzp_test_HJG5Rtuy8Xh2NB",
      amount: totals,
      currency: "INR",
      name: "Od Cards",
      description: "Od Cards Transaction",
      image: "Od Cards",

      handler: function (response) {
        let tranid = response.razorpay_payment_id;

        var remid = { planId: data._id, transactionId: tranid };
        axios
          .post(
            "https://api.odcards.com/v1/odcardsapi/web/subscription/subscribe",
            remid,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then(
            (res) => {
              if (res.status === 200) {
                toast(res.data.message);
                datas();
                datas1();
              }
            },
            (error) => {
              if (error.response && error.response.status === 400) {
                toast(error.response.data.message);
              }
            }
          );
      },
      prefill: {
        name: sessionStorage.getItem("name"),
        email: sessionStorage.getItem("email"),
        contact: sessionStorage.getItem("phone"),
      },
      notes: {
        address: "",
      },
      theme: {
        color: "#015A65",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const [ins, setins] = useState(false);
  const [Instructions, setInstructions] = useState([]);
  const [Instructions1, setInstructions1] = useState([]);
  const [Instructions2, setInstructions2] = useState([]);

  function inst() {
    setins(!ins);
  }

  const getinc = (data) => {
    setInstructions(data);
    setInstructions1(data.products);
    setInstructions2(data.benefits);
    inst();
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <div id="preloader">
            <div id="ctn-preloader" class="ctn-preloader">
              <div class="animation-preloader">
                <div class="spinner"></div>
                <div class="txt-loading">
                  <span data-text-preloader="O" class="letters-loading">
                    O
                  </span>
                  <span data-text-preloader="D" class="letters-loading">
                    D
                  </span>
                  <span data-text-preloader="C" class="letters-loading">
                    C
                  </span>
                  <span data-text-preloader="A" class="letters-loading">
                    A
                  </span>
                  <span data-text-preloader="R" class="letters-loading">
                    R
                  </span>
                  <span data-text-preloader="D" class="letters-loading">
                    D
                  </span>
                  <span data-text-preloader="S" class="letters-loading">
                    S
                  </span>
                </div>
              </div>
              <div class="loader">
                <div class="row">
                  <div class="col-3 loader-section section-left">
                    <div class="bg"></div>
                  </div>
                  <div class="col-3 loader-section section-left">
                    <div class="bg"></div>
                  </div>
                  <div class="col-3 loader-section section-right">
                    <div class="bg"></div>
                  </div>
                  <div class="col-3 loader-section section-right">
                    <div class="bg"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Header />
          <main>
            <section className="pricing__area cp-bg-19 pt-40 pb-40">
              <div className="container">
                <h2 className="text-center pb-20">Choose your Plan</h2>
                <h4 className="text-center pb-20">
                  Select a plan to Continue the free deliveries and extra offers
                  in your bookings
                </h4>
                <div className="row justify-content-center align-items-center">
                  {Plans.map((data, key) => {
                    return (
                      <div className="col-xl-4 col-md-6" key={key}>
                        <div className="cp-plan2-item white-bg mb-40">
                          <div
                            className="cp-plan-item-img"
                            data-background="assets/img/plan/plan.png"
                          />
                          <div className="cp-plan2-header">
                            <div className="cp-plan2-icon">
                              <i className="far fa-crown" />
                            </div>
                            <div className="cp-plan2-duration">
                              <h3 className="cp-plan2-title">{data.name} </h3>
                              <h5 className="cp-plan2-title mt-3 text-center">
                                <button
                                  type="button"
                                  className="btn-sm btn-dark"
                                  onClick={() => {
                                    getinc(data);
                                  }}
                                >
                                  planDetails
                                </button>
                              </h5>
                            </div>
                          </div>
                          <div className="cp-plan2-body">
                            <div className="cp-plan2-list">
                              <ul>
                                {data.benefits.map((datas, i) => (
                                  <li key={i}>{datas.benefits}</li>
                                ))}
                              </ul>
                            </div>
                          </div>

                          <h3 className="text-center">
                            Plan Price: ₹ {data.price} /-
                          </h3>

                          <div className="cp-plan-btn pt-4">
                            <button
                              type="button"
                              className="cp-border-btn"
                              style={{
                                width: "100%",
                                background: Plans1 == true ? "gray" : "",
                              }}
                              disabled={Plans1 == true}
                              onClick={() => {
                                addPlan(data);
                              }}
                            >
                              {Plans1 == true ? "Subscribed" : "Subscribe Plan"}

                              <span className="cp-border-btn__inner">
                                <span className="cp-border-btn__blobs">
                                  <span className="cp-border-btn__blob" />
                                  <span className="cp-border-btn__blob" />
                                  <span className="cp-border-btn__blob" />
                                  <span className="cp-border-btn__blob" />
                                </span>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          </main>

          <Modal show={ins} size="lg" centered>
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="mySmallModalLabel">
                View Plan Details :-
              </h5>
              <button
                onClick={() => {
                  setins(false);
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <div>
                  <Row>
                    <Col lg={12}>
                      <div className="table-rep-plugin table-responsive">
                        <Table hover bordered responsive>
                          <tbody>
                            <tr className="text-center">
                              <th>Plan Name</th>
                              <td>{Instructions.name}</td>
                            </tr>
                            <tr className="text-center">
                              <th>Plan Validity</th>
                              <td>{Instructions.validity}</td>
                            </tr>

                            <tr className="text-center">
                              <th>Plan Price</th>
                              <td>{Instructions.price}</td>
                            </tr>
                            <tr className="text-center">
                              <th>Discount</th>
                              <td>
                                ₹
                                {Instructions.discountType == "percentage" ? (
                                  <>{Instructions.discount} % </>
                                ) : (
                                  <>{Instructions.discount} /-</>
                                )}
                              </td>
                            </tr>
                            <tr className="text-center">
                              <th>Products </th>
                              <td>
                                {Instructions1.map((datas, i) => (
                                  <p key={i}>
                                    {i + 1}){datas.label}
                                  </p>
                                ))}
                              </td>
                            </tr>
                            <tr className="text-center">
                              <th>Plan Benefits</th>
                              <td>
                                {Instructions2.map((datas, i) => (
                                  <p key={i}>{datas.benefits}</p>
                                ))}
                              </td>
                            </tr>
                            <tr className="text-center">
                              <th>Description</th>
                              <td>{Instructions.description}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Modal>
          <ToastContainer />
          <Footer />
        </>
      )}
    </div>
  );
}

export default Products;
