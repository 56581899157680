import Home from "./Comonents/Home";
import Faqs from "./Comonents/Faqs";
import Cart from "./Comonents/Cart";
import Plans from "./Comonents/Plans";
import Offers from "./Comonents/Offers";
import Product from "./Comonents/Product";
import AboutUs from "./Comonents/AboutUs";
import CheckOut from "./Comonents/CheckOut";
import Products from "./Comonents/Products";
import Shipping from "./Comonents/Shipping";
import Contactus from "./Comonents/Contactus";
import MyProfile from "./Comonents/MyProfile";
import { Routes, Route } from "react-router-dom";
import SubProducts from "./Comonents/SubProducts";
import NewArrivals from "./Comonents/NewArrivals";
import RefundPolicy from "./Comonents/RefundPolicy";
import SearchProduct from "./Comonents/SearchProduct";
import ProductDetails from "./Comonents/ProductDetails";
import PrivacyPolicys from "./Comonents/PrivacyPolicys";
import TermsAndConditions from "./Comonents/TermsAndConditions";
import OurMostPopularProducts from "./Comonents/OurMostPopularProducts";
import Whatsapp from "./Comonents/Whatsapp";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/newarrivals" element={<NewArrivals />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route
          path="/Ourmostpopularproducts"
          element={<OurMostPopularProducts />}
        />
        <Route path="/plans" element={<Plans />} />
        <Route path="/product" element={<Product />} />
        <Route path="/offers" element={<Offers />} />
        <Route path="/searchproduct" element={<SearchProduct />} />
        <Route path="/products" element={<Products />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/contactus" element={<Contactus />} />
        <Route path="/myprofile" element={<MyProfile />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/shipping" element={<Shipping />} />
        <Route path="/checkout" element={<CheckOut />} />
        <Route path="/productdetails" element={<ProductDetails />} />
        <Route path="/subproducts" element={<SubProducts />} />
        <Route path="/termsandconditions" element={<TermsAndConditions />} />
        <Route path="/refundpolicy" element={<RefundPolicy />} />
        <Route path="/privacypolicy" element={<PrivacyPolicys />} />
      </Routes>
    </div>
  );
}

export default App;
