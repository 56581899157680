import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";

function Products() {
  const [show, setShow] = useState(false);

  var navigate = useNavigate();

  const [form1, setform1] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    let myUser = { ...form1 };
    myUser[e.target.name] = e.target.value;
    setform1(myUser);
  };

  const databyid = (data) => {
    setform1(data);
    handleShow();
  };

  const formsubmit = (e) => {
    e.preventDefault();
    const uuid = Date.now();
    sessionStorage.setItem("uuid", parseFloat(uuid));
    Add();
  };

  const Add = () => {
    const carts = sessionStorage.getItem("cartitems");
    if (carts == "0" || carts == null) {
      const dataArray = {
        uniqueid: sessionStorage.getItem("uuid"),
        productId: form1._id,
        orders: form1.nooforders,
      };

      axios
        .post(
          "https://api.odcards.com/v1/odcardsapi/web/addcartitem",
          dataArray
        )

        .then(
          (res) => {
            if (res.status === 200) {
              sessionStorage.setItem("cartitems", "0");
              navigate(
                "/productdetails",
                sessionStorage.setItem("subproductid", form1._id)
              );
            }
          },
          (error) => {
            if (error.response && error.response.status === 400) {
              toast(error.response.data.message);
            }
          }
        );
    } else {
      toast("Your Cart Was Already Added");
    }
  };

  const [isLoading, setIsLoading] = useState(true);

  const [ProductsCat, setProductsCat] = useState([]);

  useEffect(() => {
    datas();
  }, []);

  const datas = () => {
    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setProductsCat(res.data.productoffers);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            setIsLoading(true);
          }
        }
      );
  };

  const api = "https://api.odcards.com/";

  return (
    <div>
      {isLoading == true ? (
        <>
          <div id="preloader">
            <div id="ctn-preloader" class="ctn-preloader">
              <div class="animation-preloader">
                <div class="spinner"></div>
                <div class="txt-loading">
                  <span data-text-preloader="O" class="letters-loading">
                    O
                  </span>
                  <span data-text-preloader="D" class="letters-loading">
                    D
                  </span>
                  <span data-text-preloader="C" class="letters-loading">
                    C
                  </span>
                  <span data-text-preloader="A" class="letters-loading">
                    A
                  </span>
                  <span data-text-preloader="R" class="letters-loading">
                    R
                  </span>
                  <span data-text-preloader="D" class="letters-loading">
                    D
                  </span>
                  <span data-text-preloader="S" class="letters-loading">
                    S
                  </span>
                </div>
              </div>
              <div class="loader">
                <div class="row">
                  <div class="col-3 loader-section section-left">
                    <div class="bg"></div>
                  </div>
                  <div class="col-3 loader-section section-left">
                    <div class="bg"></div>
                  </div>
                  <div class="col-3 loader-section section-right">
                    <div class="bg"></div>
                  </div>
                  <div class="col-3 loader-section section-right">
                    <div class="bg"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Header />
          <main>
            <section
              className="page-title-area breadcrumb-spacing cp-bg-14"
              id="fdd"
            >
              <div className="container">
                <div className="row justify-content-start">
                  <div className="col-md-5">
                    <div
                      className="page-title-wrapper t-center blurred"
                      id="tor3"
                    >
                      <h2 className=" mb-10 text-white" id="tor">
                        Offers
                      </h2>
                      <p className="text-white"></p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="product-area pt-30 ">
              <div className="container">
                <div
                  className="cp-product-wrap mb-20 wow fadeInUp animated"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.3s"
                >
                  <div className="row align-items-center mb-20">
                    <div className=" col-md-12">
                      <h2 style={{ color: "#D73135" }}>Offers</h2>
                    </div>
                  </div>
                  <div className="row">
                    {ProductsCat?.map((data, i) => (
                      <div className="col-xl-3 col-lg-4 col-md-6 p-3" key={i}>
                        <div
                          className="cp-services-item t-center mb-30 wow fadeInUp animated"
                          data-wow-duration="1.5s"
                          data-wow-delay=".3s"
                        >
                          <div
                            className="cp-services-img w-img"
                            id="toapss"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              databyid(data);
                            }}
                          >
                            <img
                              src={api + data.image}
                              alt="img not found"
                              style={{ height: "320px" }}
                            />
                          </div>
                          <div className="product-description">
                            <h4 className="product-name">{data.name}</h4>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </main>

          <Modal show={show} onHide={handleClose} size="md" centered>
            <Modal.Header closeButton>
              <Modal.Title>No Of Orders </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <form
                onSubmit={(e) => {
                  formsubmit(e);
                }}
              >
                <div className="col-md-12 mt-20">
                  <select
                    name="nooforders"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={form1.nooforders}
                    required
                    className="form-select mt-30"
                  >
                    <option value="">Select </option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>

                <div className="cp-input-field sign-flex checkbox d-flex align-items-center justify-content-between"></div>
                <div className="cp-sign-btn mt-35 mb-30">
                  <button
                    type="submit"
                    className="cp-border-btn"
                    style={{ width: "100%" }}
                  >
                    Proceed
                  </button>
                </div>
              </form>
            </Modal.Body>
          </Modal>
          <ToastContainer />
          <Footer />
        </>
      )}
    </div>
  );
}

export default Products;
