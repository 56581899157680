import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import { NavLink } from "react-router-dom";

function ProductDetails() {
  const [Product, setProduct] = useState([]);
  const [Information, setInformation] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const emaildid = sessionStorage.getItem("eamilotpid");
  const emailotp = sessionStorage.getItem("eamilVerfied");

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const api = "https://api.odcards.com/";

  const ids = sessionStorage.getItem("subproductid");

  const [ProductsCat, setProductsCat] = useState([]);

  const [Price, setPrice] = useState([]);
  const [Price1, setPrice1] = useState([]);
  const [Price2, setPrice2] = useState([]);
  const [Price3, setPrice3] = useState([]);
  const [Price4, setPrice4] = useState([]);

  const tokens = sessionStorage.getItem("token");

  const uniqueid = sessionStorage.getItem("uuid");

  const [uuids, setuuid] = useState([]);

  const [Checks, setChecks] = useState([]);

  useEffect(() => {
    const js = sessionStorage.getItem("cartitems");
    GetOrders();
    GetProductDetails();
    AllModules();
    if (js == "0") {
    } else {
      CartDetails();
    }
  }, []);

  const [YES, setYES] = useState([]);

  const [siziesprice, setsiziesprice] = useState({
    height1: "",
    width1: "",
    height2: "",
    width2: "",
    height3: "",
    width3: "",
    height4: "",
    width4: "",
    height5: "",
    width5: "",
  });

  const userid = sessionStorage.getItem("userid");

  const handleprices = (e) => {
    const { name, value } = e.target;
    setsiziesprice((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleprices1 = (e) => {
    const { value, name } = e.target;
    
    setsiziesprice((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    axios
      .post("https://api.odcards.com/v1/odcardsapi/web/getvariationprice", {
        productId: ids,
        height: siziesprice.height1,
        width: value,
        userid:userid
      })
      .then(
        (res) => {
          if (res.status === 200) {
            setPrice(res.data.variations);
          }
        },
        (error) => {
          if (error.response && error.response.status === 404) {
            toast(error.response.data.message);
            setsiziesprice({
              height1: "",
              width1: "",
            });
            setPrice([]);
          }
        }
      );
  };

  const handleprices2 = (e) => {
    const { value, name } = e.target;
    setsiziesprice((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    axios
      .post("https://api.odcards.com/v1/odcardsapi/web/getvariationprice", {
        productId: ids,
        height: siziesprice.height2,
        width: value,
        userid:userid
      })
      .then(
        (res) => {
          if (res.status === 200) {
            setPrice1(res.data.variations);
          }
        },
        (error) => {
          if (error.response && error.response.status === 404) {
            toast(error.response.data.message);
            setsiziesprice({
              height2: "",
              width2: "",
            });
            setPrice1([]);
          }
        }
      );
  };

  const handleprices3 = (e) => {
    const { value, name } = e.target;
    setsiziesprice((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    axios
      .post("https://api.odcards.com/v1/odcardsapi/web/getvariationprice", {
        productId: ids,
        height: siziesprice.height3,
        width: value,
        userid:userid
      })
      .then(
        (res) => {
          if (res.status === 200) {
            setPrice2(res.data.variations);
          }
        },
        (error) => {
          if (error.response && error.response.status === 404) {
            toast(error.response.data.message);
            setsiziesprice({
              height3: "",
              width3: "",
            });
            setPrice2([]);
          }
        }
      );
  };

  const handleprices4 = (e) => {
    const { value, name } = e.target;
    setsiziesprice((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    axios
      .post("https://api.odcards.com/v1/odcardsapi/web/getvariationprice", {
        productId: ids,
        height: siziesprice.height4,
        width: value,
        userid:userid
      })
      .then(
        (res) => {
          if (res.status === 200) {
            setPrice3(res.data.variations);
          }
        },
        (error) => {
          if (error.response && error.response.status === 404) {
            toast(error.response.data.message);
            setsiziesprice({
              height4: "",
              width4: "",
            });
            setPrice3([]);
          }
        }
      );
  };

  const handleprices5 = (e) => {
    const { value, name } = e.target;
    setsiziesprice((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    axios
      .post("https://api.odcards.com/v1/odcardsapi/web/getvariationprice", {
        productId: ids,
        height: siziesprice.height5,
        width: value,
        userid:userid
      })
      .then(
        (res) => {
          if (res.status === 200) {
            setPrice4(res.data.variations);
          }
        },
        (error) => {
          if (error.response && error.response.status === 404) {
            toast(error.response.data.message);
            setsiziesprice({
              height5: "",
              width5: "",
            });
            setPrice4([]);
          }
        }
      );
  };

  const GetOrders = () => {
    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/getallcartitems",
        { uniqueid: uniqueid, productId: ids },
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setuuid(res.data.cartitems[0]);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("Data was not getting");
          }
        }
      );
  };

  const GetProductDetails = () => {
    const userid = sessionStorage.getItem("userid");
    axios
      .post("https://api.odcards.com/v1/odcardsapi/web/getproductbyid", {
        id: ids,
        userid: userid,
      })
      .then(
        (res) => {
          if (res.status === 200) {
            setProduct(res.data?.product);
            setInformation(res.data?.product?.information);
            setYES(res.data?.variations[0]?.sizes);
            const cheacking = res.data?.variations[0]?.sizes;
            if (cheacking == "NO") {
              setPrice(res.data?.variations);
            }

            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("Data was not getting");
          }
        }
      );
  };

  const AllModules = () => {
    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setProductsCat(res.data.categorys);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("Data was not getting");
          }
        }
      );
  };

  const databyid = (data) => {
    sessionStorage.setItem("productid", data._id);
    window.location.href = "/Products";
  };

  const [files, setFiles] = useState([]);
  const [files1, setFiles1] = useState([]);
  const [files2, setFiles2] = useState([]);
  const [files3, setFiles3] = useState([]);
  const [files4, setFiles4] = useState([]);

  const changeHandler = (e) => {
    const selectedFiles = e.target.files;
    const supportedFormats = [
      "JPEG",
      "TIFF",
      "PSD",
      "CDR",
      "PDF",
      "EPS",
      "PNG",
      "JPG",
      "TIF",

      "Jpeg",
      "Tiff",
      "Psd",
      "Cdr",
      "Pdf",
      "Eps",
      "Png",
      "Jpg",
      "Tif",

      "jpeg",
      "tiff",
      "psd",
      "cdr",
      "pdf",
      "eps",
      "png",
      "jpg",
      "tif",
    ];
    const maxFilesAllowed = Product.noOfCount;
    const maxFileSizeMB = 500;

    if (files.length + selectedFiles.length > maxFilesAllowed) {
      toast(`You can upload a maximum of ${maxFilesAllowed} files.`);
      return;
    }

    let totalSize = 0;
    for (let file of selectedFiles) {
      const ext = file.name.split(".").pop().toLowerCase();

      const fileSizeMB = file.size / (1024 * 1024);

      if (!supportedFormats.includes(ext)) {
        toast("File format not supported");
        return;
      }

      if (fileSizeMB > maxFileSizeMB) {
        toast("File size exceeds the limit of 500 MB");
        return;
      }

      totalSize += fileSizeMB;
      if (totalSize > maxFileSizeMB) {
        toast("Total selected files exceed the limit of 500 MB");
        return;
      }

      setFiles((prevFiles) => [...prevFiles, file]);
    }
  };

  const changeHandler1 = (e) => {
    const selectedFiles1 = e.target.files;
    const supportedFormats1 = [
      "JPEG",
      "TIFF",
      "PSD",
      "CDR",
      "PDF",
      "EPS",
      "PNG",
      "JPG",
      "TIF",

      "Jpeg",
      "Tiff",
      "Psd",
      "Cdr",
      "Pdf",
      "Eps",
      "Png",
      "Jpg",
      "Tif",

      "jpeg",
      "tiff",
      "psd",
      "cdr",
      "pdf",
      "eps",
      "png",
      "jpg",
      "tif",
    ];
    // const maxFilesAllowed1 = Product.noOfCount;
    // const maxFileSizeMB1 = 500;

    // if (files1.length + selectedFiles1.length > maxFilesAllowed1) {
    //   toast(`You can upload a maximum of ${maxFilesAllowed1} files.`);
    //   return;
    // }

    // for (let file1 of selectedFiles1) {
    //   const ext1 = file1.name.split(".").pop().toLowerCase();

    //   if (supportedFormats1.includes(ext1)) {
    //     setFiles1((prevFiles1) => [...prevFiles1, file1]);
    //   } else {
    //     toast("File format not supported");
    //     return;
    //   }
    // }

    const maxFilesAllowed1 = Product.noOfCount;
    const maxFileSizeMB1 = 500;

    if (files1.length + selectedFiles1.length > maxFilesAllowed1) {
      toast(`You can upload a maximum of ${maxFilesAllowed1} files.`);
      return;
    }

    let totalSize1 = 0;
    for (let file1 of selectedFiles1) {
      const ext1 = file1.name.split(".").pop().toLowerCase();

      const fileSizeMB1 = file1.size / (1024 * 1024);

      if (!supportedFormats1.includes(ext1)) {
        toast("File format not supported");
        return;
      }

      if (fileSizeMB1 > maxFileSizeMB1) {
        toast("File size exceeds the limit of 500 MB");
        return;
      }

      totalSize1 += fileSizeMB1;
      if (totalSize1 > maxFileSizeMB1) {
        toast("Total selected files exceed the limit of 500 MB");
        return;
      }

      setFiles1((prevFiles1) => [...prevFiles1, file1]);
    }
  };

  const changeHandler2 = (e) => {
    const selectedFiles2 = e.target.files;
    const supportedFormats2 = [
      "JPEG",
      "TIFF",
      "PSD",
      "CDR",
      "PDF",
      "EPS",
      "PNG",
      "JPG",
      "TIF",

      "Jpeg",
      "Tiff",
      "Psd",
      "Cdr",
      "Pdf",
      "Eps",
      "Png",
      "Jpg",
      "Tif",

      "jpeg",
      "tiff",
      "psd",
      "cdr",
      "pdf",
      "eps",
      "png",
      "jpg",
      "tif",
    ];

    const maxFilesAllowed2 = Product.noOfCount;
    const maxFileSizeMB2 = 500;

    if (files2.length + selectedFiles2.length > maxFilesAllowed2) {
      toast(`You can upload a maximum of ${maxFilesAllowed2} files.`);
      return;
    }

    let totalSize2 = 0;
    for (let file2 of selectedFiles2) {
      const ext2 = file2.name.split(".").pop().toLowerCase();

      const fileSizeMB2 = file2.size / (1024 * 1024);

      if (!supportedFormats2.includes(ext2)) {
        toast("File format not supported");
        return;
      }

      if (fileSizeMB2 > maxFileSizeMB2) {
        toast("File size exceeds the limit of 500 MB");
        return;
      }

      totalSize2 += fileSizeMB2;
      if (totalSize2 > maxFileSizeMB2) {
        toast("Total selected files exceed the limit of 500 MB");
        return;
      }

      setFiles2((prevFiles2) => [...prevFiles2, file2]);
    }
  };

  const changeHandler3 = (e) => {
    const selectedFiles3 = e.target.files;
    const supportedFormats3 = [
      "JPEG",
      "TIFF",
      "PSD",
      "CDR",
      "PDF",
      "EPS",
      "PNG",
      "JPG",
      "TIF",

      "Jpeg",
      "Tiff",
      "Psd",
      "Cdr",
      "Pdf",
      "Eps",
      "Png",
      "Jpg",
      "Tif",

      "jpeg",
      "tiff",
      "psd",
      "cdr",
      "pdf",
      "eps",
      "png",
      "jpg",
      "tif",
    ];

    const maxFilesAllowed3 = Product.noOfCount;
    const maxFileSizeMB3 = 500;

    if (files3.length + selectedFiles3.length > maxFilesAllowed3) {
      toast(`You can upload a maximum of ${maxFilesAllowed3} files.`);
      return;
    }

    let totalSize3 = 0;
    for (let file3 of selectedFiles3) {
      const ext3 = file3.name.split(".").pop().toLowerCase();

      const fileSizeMB3 = file3.size / (1024 * 1024);

      if (!supportedFormats3.includes(ext3)) {
        toast("File format not supported");
        return;
      }

      if (fileSizeMB3 > maxFileSizeMB3) {
        toast("File size exceeds the limit of 500 MB");
        return;
      }

      totalSize3 += fileSizeMB3;
      if (totalSize3 > maxFileSizeMB3) {
        toast("Total selected files exceed the limit of 500 MB");
        return;
      }

      setFiles3((prevFiles3) => [...prevFiles3, file3]);
    }
  };

  const changeHandler4 = (e) => {
    const selectedFiles4 = e.target.files;
    const supportedFormats4 = [
      "JPEG",
      "TIFF",
      "PSD",
      "CDR",
      "PDF",
      "EPS",
      "PNG",
      "JPG",
      "TIF",

      "Jpeg",
      "Tiff",
      "Psd",
      "Cdr",
      "Pdf",
      "Eps",
      "Png",
      "Jpg",
      "Tif",

      "jpeg",
      "tiff",
      "psd",
      "cdr",
      "pdf",
      "eps",
      "png",
      "jpg",
      "tif",
    ];

    const maxFilesAllowed4 = Product.noOfCount;
    const maxFileSizeMB4 = 500;

    if (files4.length + selectedFiles4.length > maxFilesAllowed4) {
      toast(`You can upload a maximum of ${maxFilesAllowed4} files.`);
      return;
    }

    let totalSize4 = 0;
    for (let file4 of selectedFiles4) {
      const ext4 = file4.name.split(".").pop().toLowerCase();

      const fileSizeMB4 = file4.size / (1024 * 1024);

      if (!supportedFormats4.includes(ext4)) {
        toast("File format not supported");
        return;
      }

      if (fileSizeMB4 > maxFileSizeMB4) {
        toast("File size exceeds the limit of 500 MB");
        return;
      }

      totalSize4 += fileSizeMB4;
      if (totalSize4 > maxFileSizeMB4) {
        toast("Total selected files exceed the limit of 500 MB");
        return;
      }

      setFiles4((prevFiles4) => [...prevFiles4, file4]);
    }
  };

  const [form5, setform5] = useState([]);

  const handleChange5 = (e) => {
    let myUser = { ...form5 };
    myUser[e.target.name] = e.target.value;
    setform5(myUser);
  };

  const formsubmit5 = (e) => {
    if (tokens == null) {
      handleShow();
    }
    e.preventDefault();
    Add5();
  };

  const Add5 = () => {
    const userid = sessionStorage.getItem("userid");
    const dataArray = new FormData();
    dataArray.append("userId", userid);
    dataArray.append("productId", ids);
    dataArray.append("priceId", form5.priceId);

    for (let i = 0; i < files.length; i++) {
      dataArray.append("image", files[i]);
    }

    setIsLoading(true);
    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/cart/addcart",
        dataArray,
        { headers: { Authorization: `Bearer ${tokens}` } }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            CartDetails();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
            setIsLoading(false);
          }
        }
      );
  };

  const formsubmit6 = (e) => {
    if (tokens == null) {
      handleShow();
    }
    e.preventDefault();
    Add6();
  };

  const Add6 = () => {
    const userid = sessionStorage.getItem("userid");
    const dataArray = new FormData();
    dataArray.append("userId", userid);
    dataArray.append("productId", ids);
    dataArray.append("priceId", form5.priceId1);

    for (let i = 0; i < files1.length; i++) {
      dataArray.append("image", files1[i]);
    }
    setIsLoading(true);
    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/cart/addcart",
        dataArray,
        { headers: { Authorization: `Bearer ${tokens}` } }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            CartDetails();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
            setIsLoading(false);
          }
        }
      );
  };

  const formsubmit7 = (e) => {
    if (tokens == null) {
      handleShow();
    }
    e.preventDefault();
    Add7();
  };

  const Add7 = () => {
    const userid = sessionStorage.getItem("userid");
    const dataArray = new FormData();
    dataArray.append("userId", userid);
    dataArray.append("productId", ids);
    dataArray.append("priceId", form5.priceId2);

    for (let i = 0; i < files2.length; i++) {
      dataArray.append("image", files2[i]);
    }
    setIsLoading(true);
    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/cart/addcart",
        dataArray,
        { headers: { Authorization: `Bearer ${tokens}` } }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            CartDetails();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
            setIsLoading(false);
          }
        }
      );
  };

  const formsubmit8 = (e) => {
    if (tokens == null) {
      handleShow();
    }
    e.preventDefault();
    Add8();
  };

  const Add8 = () => {
    const userid = sessionStorage.getItem("userid");
    const dataArray = new FormData();
    dataArray.append("userId", userid);
    dataArray.append("productId", ids);
    dataArray.append("priceId", form5.priceId3);

    for (let i = 0; i < files3.length; i++) {
      dataArray.append("image", files3[i]);
    }
    setIsLoading(true);
    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/cart/addcart",
        dataArray,
        { headers: { Authorization: `Bearer ${tokens}` } }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            CartDetails();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
            setIsLoading(false);
          }
        }
      );
  };

  const formsubmit9 = (e) => {
    if (tokens == null) {
      handleShow();
    }
    e.preventDefault();
    Add9();
  };

  const Add9 = () => {
    const userid = sessionStorage.getItem("userid");
    const dataArray = new FormData();
    dataArray.append("userId", userid);
    dataArray.append("productId", ids);
    dataArray.append("priceId", form5.priceId4);

    for (let i = 0; i < files4.length; i++) {
      dataArray.append("image", files4[i]);
    }
    setIsLoading(true);
    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/cart/addcart",
        dataArray,
        { headers: { Authorization: `Bearer ${tokens}` } }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            CartDetails();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
            setIsLoading(false);
          }
        }
      );
  };

  const CartDetails = () => {
    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/cart/getallcarts",
        {},
        {
          headers: { Authorization: `Bearer ${tokens}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setIsLoading(false);
            const productlength = res.data.cartitems.length;
            const productlengths = parseFloat(productlength);
            sessionStorage.setItem("cartitems", productlengths);
            setChecks(productlengths);

            var kl = res.data.cartitems;

            if (productlengths == 1) {
              const c = { ...form5 };
              c["priceId"] = kl[0].priceId;
              setform5(c);

              setFiles(kl[0].image);
            }

            if (productlength == 2) {
              const c = { ...form5 };
              c["priceId"] = kl[0].priceId;
              c["priceId1"] = kl[1].priceId;
              setform5(c);

              setFiles(kl[0].image);

              setFiles1(kl[1].image);
            }

            if (productlength == 3) {
              const c = { ...form5 };
              c["priceId"] = kl[0].priceId;
              c["priceId1"] = kl[1].priceId;
              c["priceId2"] = kl[2].priceId;
              setform5(c);

              setFiles(kl[0].image);

              setFiles1(kl[1].image);

              setFiles2(kl[2].image);
            }

            if (productlength == 4) {
              const c = { ...form5 };
              c["priceId"] = kl[0].priceId;
              c["priceId1"] = kl[1].priceId;
              c["priceId2"] = kl[2].priceId;
              c["priceId3"] = kl[3].priceId;
              setform5(c);

              setFiles(kl[0].image);

              setFiles1(kl[1].image);

              setFiles2(kl[2].image);

              setFiles3(kl[3].image);
            }

            if (productlength == 5) {
              const c = { ...form5 };
              c["priceId"] = kl[0].priceId;
              c["priceId1"] = kl[1].priceId;
              c["priceId2"] = kl[2].priceId;
              c["priceId3"] = kl[3].priceId;
              c["priceId4"] = kl[4].priceId;
              setform5(c);

              setFiles(kl[0].image);

              setFiles1(kl[1].image);

              setFiles2(kl[2].image);

              setFiles3(kl[3].image);

              setFiles4(kl[4].image);
            }
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("Data was not getting");
          }
        }
      );
  };

  const token = sessionStorage.getItem("token");

  const [showOtpForm, setShowOtpForm] = useState(false);

  const [form, setform] = useState([]);

  const formsubmit = (e) => {
    e.preventDefault();
    Add();
  };

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const Add = () => {
    const dataArray = {
      phone: form.phone,
    };

    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/auth/logincheck",
        dataArray
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            sessionStorage.setItem("eamilotpid", res.data.data._id);
            sessionStorage.setItem("eamilVerfied", res.data.data.new_user);
            sessionStorage.setItem("mobilenos", res.data.data.phone);
            setform({
              phone: "",
            });
            setShowOtpForm(true);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const [form2, setform2] = useState([]);

  const formsubmit2 = (e) => {
    e.preventDefault();
    Add2();
  };

  const handleChange2 = (e) => {
    let myUser = { ...form2 };
    myUser[e.target.name] = e.target.value;
    setform2(myUser);
  };

  const Add2 = () => {
    const dataArray = {
      emailOtp: form2.otp,
      _id: emaildid,
    };

    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/auth/compareotp",
        dataArray
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            sessionStorage.setItem("token", res.data.token);
            sessionStorage.setItem("userid", res.data.data._id);
            setShow(false);
            if (emailotp == "yes") {
              sessionStorage.setItem("token", res.data.token);
              handleShow1();
            }
            GetProfile();
            setform({
              otp: "",
            });
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const [form3, setform3] = useState([]);

  const phone = sessionStorage.getItem("mobilenos");

  useEffect(() => {
    const emailotp = sessionStorage.getItem("eamilVerfied");
    if (emailotp == "no") {
      GetProfile();
    }
  }, []);

  const GetProfile = () => {
    const userid = sessionStorage.getItem("userid");

    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/auth/getuserprofile",
        { userId: userid },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setform3(res.data.userData);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("Data was not getting");
          }
        }
      );
  };

  const formsubmit3 = (e) => {
    e.preventDefault();
    Add3();
  };

  const handleChange3 = (e) => {
    let myUser = { ...form3 };
    myUser[e.target.name] = e.target.value;
    setform3(myUser);
  };

  const Add3 = () => {
    const dataArray = {
      firstName: form3.firstName,
      lastName: form3.lastName,
      phone: phone,
      address: form3.address,
      email: form3.email,
    };

    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/auth/userRegistration",
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )

      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            handleClose1();
            setform({
              firstName: "",
              lastName: "",
              phone: "",
              address: "",
              email: "",
            });
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const databyids = () => {
    window.location.href = "/cart";
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <div id="preloader">
            <div id="ctn-preloader" class="ctn-preloader">
              <div class="animation-preloader">
                <div class="spinner"></div>
                <div class="txt-loading">
                  <span data-text-preloader="O" class="letters-loading">
                    O
                  </span>
                  <span data-text-preloader="D" class="letters-loading">
                    D
                  </span>
                  <span data-text-preloader="C" class="letters-loading">
                    C
                  </span>
                  <span data-text-preloader="A" class="letters-loading">
                    A
                  </span>
                  <span data-text-preloader="R" class="letters-loading">
                    R
                  </span>
                  <span data-text-preloader="D" class="letters-loading">
                    D
                  </span>
                  <span data-text-preloader="S" class="letters-loading">
                    S
                  </span>
                </div>
              </div>
              <div class="loader">
                <div class="row">
                  <div class="col-3 loader-section section-left">
                    <div class="bg"></div>
                  </div>
                  <div class="col-3 loader-section section-left">
                    <div class="bg"></div>
                  </div>
                  <div class="col-3 loader-section section-right">
                    <div class="bg"></div>
                  </div>
                  <div class="col-3 loader-section section-right">
                    <div class="bg"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Header />
          <main>
            <section
              className="page-title-area breadcrumb-spacing cp-bg-14"
              id="fdd"
            >
              <div className="container">
                <div className="row justify-content-start">
                  <div className="col-md-5">
                    <div
                      className="page-title-wrapper t-center blurred"
                      id="tor3"
                    >
                      <h2 className=" mb-10 text-white" id="tor">
                        {Product.name}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="product-area pt-30 ">
              <div className="container">
                <div
                  className="cp-product-wrap mb-60 wow fadeInUp animated"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.3s"
                >
                  <div className="row align-items-center mb-20">
                    <div className="col-md-12">
                      <h2 style={{ color: "#D73135" }}>{Product.name}</h2>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-5">
                      <div
                        className="cp-faq-img-wrap mb-20 wow fadeInLeft animated"
                        data-wow-delay="0.3s"
                      >
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="cp-faq-img-item p-relative w-img mb-30 js-tilt">
                              <div className="cp-img-overlay wow" />
                              <div
                                className="cp-img-blur "
                                style={{ width: "100%" }}
                              >
                                <img src={api + Product.image} alt="faq" />
                                <img src={api + Product.image} alt="faq" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-7">
                      {Information.map((data, i) => (
                        <p key={i} className="mt-4">
                          <span class="m-2 ">
                            <img src="assets/img/i1.png" height="20px"></img>
                          </span>
                          {data.information}
                        </p>
                      ))}
                    </div>

                    <div className="col-md-5 mt-20">{Product.description}</div>
                    <div className="col-md-7 mt-20">
                      {uuids.orders == "1" ? (
                        <>
                          {/* form 1 */}
                          <form
                            onSubmit={(e) => {
                              formsubmit5(e);
                            }}
                          >
                            <div className="row">
                              <div className="col-md-4 mt-20">
                                <div className="cp-cart-checkout-btn mt-30">
                                  <div>
                                    <label
                                      className="cp-border-btn"
                                      style={{
                                        width: "100%",
                                        position: "relative",
                                      }}
                                    >
                                      Upload Design
                                      <i
                                        className="fa fa-upload"
                                        aria-hidden="true"
                                      ></i>
                                      <input
                                        name="avatar"
                                        type="file"
                                        onChange={changeHandler}
                                        accept="image/*"
                                        required
                                        multiple
                                        disabled={
                                          Checks == "1" ||
                                          Checks == "2" ||
                                          Checks == "3" ||
                                          Checks == "4" ||
                                          Checks == "5"
                                        }
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          left: 0,
                                          width: "100%",
                                          height: "100%",
                                          opacity: 0,
                                          cursor: "pointer",
                                        }}
                                      />
                                    </label>
                                    <small
                                      style={{ color: "red", fontSize: "10px" }}
                                    >
                                      Note : (JPEG ,TIFF ,PSD ,CDR ,PDF, EPS
                                      ,PNG ,JPG) upload have one of these
                                      extensions
                                    </small>
                                  </div>

                                  {Checks == "1" ||
                                  Checks == "2" ||
                                  Checks == "3" ||
                                  Checks == "4" ||
                                  Checks == "5" ? (
                                    <>
                                      <div className="row">
                                        {files.map((file, index) => (
                                          <div key={index} className="col-6">
                                            <img
                                              src={api + file}
                                              height="80px"
                                              width="100%"
                                              className="mt-3 text-center"
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {files.length > 0 && (
                                        <div className="row">
                                          {files.map((file, index) => (
                                            <div key={index} className="col-6">
                                              <img
                                                src={URL.createObjectURL(file)}
                                                alt={`Uploaded ${index}`}
                                                height="80px"
                                                width="100%"
                                                className="mt-3 text-center"
                                              />
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-6 mt-20">
                                {YES == "NO" ? (
                                  <>
                                    <select
                                      name="priceId"
                                      onChange={(e) => {
                                        handleChange5(e);
                                      }}
                                      value={form5.priceId}
                                      required
                                      disabled={
                                        Checks == "1" ||
                                        Checks == "2" ||
                                        Checks == "3" ||
                                        Checks == "4" ||
                                        Checks == "5"
                                      }
                                      className="form-select mt-30"
                                    >
                                      <option value="">
                                        Select Quantity & Price
                                      </option>
                                      {Price.map((data, key) => {
                                        return (
                                          <option key={key} value={data._id}>
                                            Quantity {data.value} ( ₹
                                            {data.price})
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </>
                                ) : (
                                  <>
                                    <div className="row">
                                      <div className="col-md-6 ">
                                        <label>Height</label>
                                        <span className="text-danger">*</span>
                                        <input
                                          type="number"
                                          className="form-control"
                                          name="height1"
                                          required
                                          placeholder="Enter height"
                                          onChange={(e) => {
                                            handleprices(e);
                                          }}
                                          disabled={
                                            Checks == "1" ||
                                            Checks == "2" ||
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                          value={siziesprice.height1}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <label>Width</label>
                                        <span className="text-danger">*</span>
                                        <input
                                          type="number"
                                          className="form-control "
                                          name="width1"
                                          required
                                          placeholder="Enter width"
                                          onChange={(e) => {
                                            handleprices1(e);
                                          }}
                                          disabled={
                                            Checks == "1" ||
                                            Checks == "2" ||
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                          value={siziesprice.width1}
                                        />
                                      </div>

                                      <div className="col-md-12">
                                        <select
                                          name="priceId"
                                          onChange={(e) => {
                                            handleChange5(e);
                                          }}
                                          value={form5.priceId}
                                          required
                                          disabled={
                                            Checks == "1" ||
                                            Checks == "2" ||
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                          className="form-select mt-3"
                                        >
                                          <option value="">
                                            Select Quantity & Price
                                          </option>
                                          {Price.map((data, key) => {
                                            return (
                                              <option
                                                key={key}
                                                value={data._id}
                                              >
                                                Quantity {data.value} ( ₹
                                                {data.price})
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>

                              <div className="col-md-2 mt-20">
                                <button
                                  className="cp-border-btn mt-30"
                                  type="submit"
                                  style={{
                                    width: "100%",
                                    background:
                                      Checks == "1" ||
                                      Checks == "2" ||
                                      Checks == "3" ||
                                      Checks == "4" ||
                                      Checks == "5"
                                        ? "gray"
                                        : "",
                                  }}
                                  disabled={
                                    Checks == "1" ||
                                    Checks == "2" ||
                                    Checks == "3" ||
                                    Checks == "4" ||
                                    Checks == "5"
                                  }
                                >
                                  Add
                                  <i
                                    className="fa fa-cart-arrow-down"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </form>
                        </>
                      ) : (
                        <></>
                      )}

                      {uuids.orders == "2" ? (
                        <>
                          {/* form 1 */}
                          <form
                            onSubmit={(e) => {
                              formsubmit5(e);
                            }}
                          >
                            <div className="row">
                              <div className="col-md-4 mt-20">
                                <div className="cp-cart-checkout-btn mt-30">
                                  <div>
                                    <label
                                      className="cp-border-btn"
                                      style={{
                                        width: "100%",
                                        position: "relative",
                                      }}
                                    >
                                      Upload Design
                                      <i
                                        className="fa fa-upload"
                                        aria-hidden="true"
                                      ></i>
                                      <input
                                        name="avatar"
                                        type="file"
                                        onChange={changeHandler}
                                        accept="image/*"
                                        required
                                        multiple
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          left: 0,
                                          width: "100%",
                                          height: "100%",
                                          opacity: 0,
                                          cursor: "pointer",
                                        }}
                                        disabled={
                                          Checks == "1" ||
                                          Checks == "2" ||
                                          Checks == "3" ||
                                          Checks == "4" ||
                                          Checks == "5"
                                        }
                                      />
                                    </label>
                                    <small
                                      style={{ color: "red", fontSize: "10px" }}
                                    >
                                      Note : (JPEG ,TIFF ,PSD ,CDR ,PDF, EPS
                                      ,PNG ,JPG) upload have one of these
                                      extensions
                                    </small>
                                  </div>

                                  {Checks == "1" ||
                                  Checks == "2" ||
                                  Checks == "3" ||
                                  Checks == "4" ||
                                  Checks == "5" ? (
                                    <>
                                      <div className="row">
                                        {files.map((file, index) => (
                                          <div key={index} className="col-6">
                                            <img
                                              src={api + file}
                                              height="80px"
                                              width="100%"
                                              className="mt-3 text-center"
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {files.length > 0 && (
                                        <div className="row">
                                          {files.map((file, index) => (
                                            <div key={index} className="col-6">
                                              <img
                                                src={URL.createObjectURL(file)}
                                                alt={`Uploaded ${index}`}
                                                height="80px"
                                                width="100%"
                                                className="mt-3 text-center"
                                              />
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-6 mt-20">
                                {YES == "NO" ? (
                                  <>
                                    <select
                                      name="priceId"
                                      onChange={(e) => {
                                        handleChange5(e);
                                      }}
                                      value={form5.priceId}
                                      required
                                      disabled={
                                        Checks == "1" ||
                                        Checks == "2" ||
                                        Checks == "3" ||
                                        Checks == "4" ||
                                        Checks == "5"
                                      }
                                      className="form-select mt-30"
                                    >
                                      <option value="">
                                        Select Quantity & Price
                                      </option>
                                      {Price.map((data, key) => {
                                        return (
                                          <option key={key} value={data._id}>
                                            Quantity {data.value} ( ₹
                                            {data.price})
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </>
                                ) : (
                                  <>
                                    <div className="row">
                                      <div className="col-md-6 ">
                                        <label>Height</label>
                                        <span className="text-danger">*</span>
                                        <input
                                          type="number"
                                          className="form-control"
                                          name="height1"
                                          required
                                          placeholder="Enter height"
                                          onChange={(e) => {
                                            handleprices(e);
                                          }}
                                          disabled={
                                            Checks == "1" ||
                                            Checks == "2" ||
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                          value={siziesprice.height1}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <label>Width</label>
                                        <span className="text-danger">*</span>
                                        <input
                                          type="number"
                                          className="form-control "
                                          name="width1"
                                          required
                                          placeholder="Enter width"
                                          onChange={(e) => {
                                            handleprices1(e);
                                          }}
                                          disabled={
                                            Checks == "1" ||
                                            Checks == "2" ||
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                          value={siziesprice.width1}
                                        />
                                      </div>

                                      <div className="col-md-12">
                                        <select
                                          name="priceId"
                                          onChange={(e) => {
                                            handleChange5(e);
                                          }}
                                          value={form5.priceId}
                                          required
                                          disabled={
                                            Checks == "1" ||
                                            Checks == "2" ||
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                          className="form-select mt-3"
                                        >
                                          <option value="">
                                            Select Quantity & Price
                                          </option>
                                          {Price.map((data, key) => {
                                            return (
                                              <option
                                                key={key}
                                                value={data._id}
                                              >
                                                Quantity {data.value} ( ₹
                                                {data.price})
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>

                              <div className="col-md-2 mt-20">
                                <button
                                  className="cp-border-btn mt-30"
                                  type="submit"
                                  style={{
                                    width: "100%",
                                    background:
                                      Checks == "1" ||
                                      Checks == "2" ||
                                      Checks == "3" ||
                                      Checks == "4" ||
                                      Checks == "5"
                                        ? "gray"
                                        : "",
                                  }}
                                  disabled={
                                    Checks == "1" ||
                                    Checks == "2" ||
                                    Checks == "3" ||
                                    Checks == "4" ||
                                    Checks == "5"
                                  }
                                >
                                  Add
                                  <i
                                    className="fa fa-cart-arrow-down"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </form>

                          {/* form 2 */}
                          <form
                            onSubmit={(e) => {
                              formsubmit6(e);
                            }}
                          >
                            <div className="row">
                              <div className="col-md-4 mt-20">
                                <div className="cp-cart-checkout-btn mt-30">
                                  <div>
                                    <label
                                      className="cp-border-btn"
                                      style={{
                                        width: "100%",
                                        position: "relative",
                                      }}
                                    >
                                      Upload Design
                                      <i
                                        className="fa fa-upload"
                                        aria-hidden="true"
                                      ></i>
                                      <input
                                        name="avatar"
                                        type="file"
                                        onChange={changeHandler1}
                                        accept="image/*"
                                        required
                                        multiple
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          left: 0,
                                          width: "100%",
                                          height: "100%",
                                          opacity: 0,
                                          cursor: "pointer",
                                        }}
                                        disabled={
                                          Checks == "2" ||
                                          Checks == "3" ||
                                          Checks == "4" ||
                                          Checks == "5"
                                        }
                                      />
                                    </label>
                                  </div>

                                  {Checks == "2" ||
                                  Checks == "3" ||
                                  Checks == "4" ||
                                  Checks == "5" ? (
                                    <>
                                      <div className="row">
                                        {files1.map((file, index) => (
                                          <div key={index} className="col-6">
                                            <img
                                              src={api + file}
                                              height="80px"
                                              width="100%"
                                              className="mt-3 text-center"
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {files1.length > 0 && (
                                        <div className="row">
                                          {files1.map((file, index) => (
                                            <div key={index} className="col-6">
                                              <img
                                                src={URL.createObjectURL(file)}
                                                alt={`Uploaded ${index}`}
                                                height="80px"
                                                width="100%"
                                                className="mt-3 text-center"
                                              />
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6 mt-20">
                                {YES == "NO" ? (
                                  <>
                                    <select
                                      name="priceId1"
                                      onChange={(e) => {
                                        handleChange5(e);
                                      }}
                                      value={form5.priceId1}
                                      required
                                      className="form-select mt-30"
                                      disabled={
                                        Checks == "2" ||
                                        Checks == "3" ||
                                        Checks == "4" ||
                                        Checks == "5"
                                      }
                                    >
                                      <option value="">
                                        Select Quantity & Price
                                      </option>
                                      {Price.map((data, key) => {
                                        return (
                                          <option key={key} value={data._id}>
                                            Quantity {data.value} ( ₹
                                            {data.price})
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </>
                                ) : (
                                  <>
                                    <div className="row">
                                      <div className="col-md-6 ">
                                        <label>Height</label>
                                        <span className="text-danger">*</span>
                                        <input
                                          type="number"
                                          className="form-control"
                                          name="height2"
                                          required
                                          placeholder="Enter height"
                                          onChange={(e) => {
                                            handleprices(e);
                                          }}
                                          disabled={
                                            Checks == "2" ||
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                          value={siziesprice.height2}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <label>Width</label>
                                        <span className="text-danger">*</span>
                                        <input
                                          type="number"
                                          className="form-control "
                                          name="width2"
                                          required
                                          placeholder="Enter width"
                                          onChange={(e) => {
                                            handleprices2(e);
                                          }}
                                          disabled={
                                            Checks == "2" ||
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                          value={siziesprice.width2}
                                        />
                                      </div>

                                      <div className="col-md-12">
                                        <select
                                          name="priceId1"
                                          onChange={(e) => {
                                            handleChange5(e);
                                          }}
                                          value={form5.priceId1}
                                          required
                                          className="form-select mt-30"
                                          disabled={
                                            Checks == "2" ||
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                        >
                                          <option value="">
                                            Select Quantity & Price
                                          </option>
                                          {Price1.map((data, key) => {
                                            return (
                                              <option
                                                key={key}
                                                value={data._id}
                                              >
                                                Quantity {data.value} ( ₹
                                                {data.price})
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="col-md-2 mt-20">
                                <button
                                  className="cp-border-btn mt-30"
                                  type="submit"
                                  style={{
                                    width: "100%",
                                    background:
                                      Checks == "2" ||
                                      Checks == "3" ||
                                      Checks == "4" ||
                                      Checks == "5"
                                        ? "gray"
                                        : "",
                                  }}
                                  disabled={
                                    Checks == "2" ||
                                    Checks == "3" ||
                                    Checks == "4" ||
                                    Checks == "5"
                                  }
                                >
                                  Add
                                  <i
                                    className="fa fa-cart-arrow-down"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </form>
                        </>
                      ) : (
                        <></>
                      )}

                      {uuids.orders == "3" ? (
                        <>
                          {/* form 1 */}
                          <form
                            onSubmit={(e) => {
                              formsubmit5(e);
                            }}
                          >
                            <div className="row">
                              <div className="col-md-4 mt-20">
                                <div className="cp-cart-checkout-btn mt-30">
                                  <div>
                                    <label
                                      className="cp-border-btn"
                                      style={{
                                        width: "100%",
                                        position: "relative",
                                      }}
                                    >
                                      Upload Design
                                      <i
                                        className="fa fa-upload"
                                        aria-hidden="true"
                                      ></i>
                                      <input
                                        name="avatar"
                                        type="file"
                                        onChange={changeHandler}
                                        accept="image/*"
                                        required
                                        multiple
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          left: 0,
                                          width: "100%",
                                          height: "100%",
                                          opacity: 0,
                                          cursor: "pointer",
                                        }}
                                        disabled={
                                          Checks == "1" ||
                                          Checks == "2" ||
                                          Checks == "3" ||
                                          Checks == "4" ||
                                          Checks == "5"
                                        }
                                      />
                                    </label>
                                    <small
                                      style={{ color: "red", fontSize: "10px" }}
                                    >
                                      Note : (JPEG ,TIFF ,PSD ,CDR ,PDF, EPS
                                      ,PNG ,JPG) upload have one of these
                                      extensions
                                    </small>
                                  </div>

                                  {Checks == "1" ||
                                  Checks == "2" ||
                                  Checks == "3" ||
                                  Checks == "4" ||
                                  Checks == "5" ? (
                                    <>
                                      <div className="row">
                                        {files.map((file, index) => (
                                          <div key={index} className="col-6">
                                            <img
                                              src={api + file}
                                              height="80px"
                                              width="100%"
                                              className="mt-3 text-center"
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {files.length > 0 && (
                                        <div className="row">
                                          {files.map((file, index) => (
                                            <div key={index} className="col-6">
                                              <img
                                                src={URL.createObjectURL(file)}
                                                alt={`Uploaded ${index}`}
                                                height="80px"
                                                width="100%"
                                                className="mt-3 text-center"
                                              />
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-6 mt-20">
                                {YES == "NO" ? (
                                  <>
                                    <select
                                      name="priceId"
                                      onChange={(e) => {
                                        handleChange5(e);
                                      }}
                                      value={form5.priceId}
                                      required
                                      disabled={
                                        Checks == "1" ||
                                        Checks == "2" ||
                                        Checks == "3" ||
                                        Checks == "4" ||
                                        Checks == "5"
                                      }
                                      className="form-select mt-30"
                                    >
                                      <option value="">
                                        Select Quantity & Price
                                      </option>
                                      {Price.map((data, key) => {
                                        return (
                                          <option key={key} value={data._id}>
                                            Quantity {data.value} ( ₹
                                            {data.price})
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </>
                                ) : (
                                  <>
                                    <div className="row">
                                      <div className="col-md-6 ">
                                        <label>Height</label>
                                        <span className="text-danger">*</span>
                                        <input
                                          type="number"
                                          className="form-control"
                                          name="height1"
                                          required
                                          placeholder="Enter height"
                                          onChange={(e) => {
                                            handleprices(e);
                                          }}
                                          disabled={
                                            Checks == "1" ||
                                            Checks == "2" ||
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                          value={siziesprice.height1}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <label>Width</label>
                                        <span className="text-danger">*</span>
                                        <input
                                          type="number"
                                          className="form-control "
                                          name="width1"
                                          required
                                          placeholder="Enter width"
                                          onChange={(e) => {
                                            handleprices1(e);
                                          }}
                                          disabled={
                                            Checks == "1" ||
                                            Checks == "2" ||
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                          value={siziesprice.width1}
                                        />
                                      </div>

                                      <div className="col-md-12">
                                        <select
                                          name="priceId"
                                          onChange={(e) => {
                                            handleChange5(e);
                                          }}
                                          value={form5.priceId}
                                          required
                                          disabled={
                                            Checks == "1" ||
                                            Checks == "2" ||
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                          className="form-select mt-3"
                                        >
                                          <option value="">
                                            Select Quantity & Price
                                          </option>
                                          {Price.map((data, key) => {
                                            return (
                                              <option
                                                key={key}
                                                value={data._id}
                                              >
                                                Quantity {data.value} ( ₹
                                                {data.price})
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>

                              <div className="col-md-2 mt-20">
                                <button
                                  className="cp-border-btn mt-30"
                                  type="submit"
                                  style={{
                                    width: "100%",
                                    background:
                                      Checks == "1" ||
                                      Checks == "2" ||
                                      Checks == "3" ||
                                      Checks == "4" ||
                                      Checks == "5"
                                        ? "gray"
                                        : "",
                                  }}
                                  disabled={
                                    Checks == "1" ||
                                    Checks == "2" ||
                                    Checks == "3" ||
                                    Checks == "4" ||
                                    Checks == "5"
                                  }
                                >
                                  Add
                                  <i
                                    className="fa fa-cart-arrow-down"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </form>

                          {/* form 2 */}
                          <form
                            onSubmit={(e) => {
                              formsubmit6(e);
                            }}
                          >
                            <div className="row">
                              <div className="col-md-4 mt-20">
                                <div className="cp-cart-checkout-btn mt-30">
                                  <div>
                                    <label
                                      className="cp-border-btn"
                                      style={{
                                        width: "100%",
                                        position: "relative",
                                      }}
                                    >
                                      Upload Design
                                      <i
                                        className="fa fa-upload"
                                        aria-hidden="true"
                                      ></i>
                                      <input
                                        name="avatar"
                                        type="file"
                                        onChange={changeHandler1}
                                        accept="image/*"
                                        multiple
                                        required
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          left: 0,
                                          width: "100%",
                                          height: "100%",
                                          opacity: 0,
                                          cursor: "pointer",
                                        }}
                                        disabled={
                                          Checks == "2" ||
                                          Checks == "3" ||
                                          Checks == "4" ||
                                          Checks == "5"
                                        }
                                      />
                                    </label>
                                  </div>

                                  {Checks == "2" ||
                                  Checks == "3" ||
                                  Checks == "4" ||
                                  Checks == "5" ? (
                                    <>
                                      <div className="row">
                                        {files1.map((file, index) => (
                                          <div key={index} className="col-6">
                                            <img
                                              src={api + file}
                                              height="80px"
                                              width="100%"
                                              className="mt-3 text-center"
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {files1.length > 0 && (
                                        <div className="row">
                                          {files1.map((file, index) => (
                                            <div key={index} className="col-6">
                                              <img
                                                src={URL.createObjectURL(file)}
                                                alt={`Uploaded ${index}`}
                                                height="80px"
                                                width="100%"
                                                className="mt-3 text-center"
                                              />
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6 mt-20">
                                {YES == "NO" ? (
                                  <>
                                    <select
                                      name="priceId1"
                                      onChange={(e) => {
                                        handleChange5(e);
                                      }}
                                      value={form5.priceId1}
                                      required
                                      className="form-select mt-30"
                                      disabled={
                                        Checks == "2" ||
                                        Checks == "3" ||
                                        Checks == "4" ||
                                        Checks == "5"
                                      }
                                    >
                                      <option value="">
                                        Select Quantity & Price
                                      </option>
                                      {Price.map((data, key) => {
                                        return (
                                          <option key={key} value={data._id}>
                                            Quantity {data.value} ( ₹
                                            {data.price})
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </>
                                ) : (
                                  <>
                                    <div className="row">
                                      <div className="col-md-6 ">
                                        <label>Height</label>
                                        <span className="text-danger">*</span>
                                        <input
                                          type="number"
                                          className="form-control"
                                          name="height2"
                                          required
                                          placeholder="Enter height"
                                          onChange={(e) => {
                                            handleprices(e);
                                          }}
                                          disabled={
                                            Checks == "2" ||
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                          value={siziesprice.height2}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <label>Width</label>
                                        <span className="text-danger">*</span>
                                        <input
                                          type="number"
                                          className="form-control "
                                          name="width2"
                                          required
                                          placeholder="Enter width"
                                          onChange={(e) => {
                                            handleprices2(e);
                                          }}
                                          disabled={
                                            Checks == "2" ||
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                          value={siziesprice.width2}
                                        />
                                      </div>

                                      <div className="col-md-12">
                                        <select
                                          name="priceId1"
                                          onChange={(e) => {
                                            handleChange5(e);
                                          }}
                                          value={form5.priceId1}
                                          required
                                          className="form-select mt-30"
                                          disabled={
                                            Checks == "2" ||
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                        >
                                          <option value="">
                                            Select Quantity & Price
                                          </option>
                                          {Price1.map((data, key) => {
                                            return (
                                              <option
                                                key={key}
                                                value={data._id}
                                              >
                                                Quantity {data.value} ( ₹
                                                {data.price})
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="col-md-2 mt-20">
                                <button
                                  className="cp-border-btn mt-30"
                                  type="submit"
                                  style={{
                                    width: "100%",
                                    background:
                                      Checks == "2" ||
                                      Checks == "3" ||
                                      Checks == "4" ||
                                      Checks == "5"
                                        ? "gray"
                                        : "",
                                  }}
                                  disabled={
                                    Checks == "2" ||
                                    Checks == "3" ||
                                    Checks == "4" ||
                                    Checks == "5"
                                  }
                                >
                                  Add
                                  <i
                                    className="fa fa-cart-arrow-down"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </form>

                          {/* form 3 */}
                          <form
                            onSubmit={(e) => {
                              formsubmit7(e);
                            }}
                          >
                            <div className="row">
                              <div className="col-md-4 mt-20">
                                <div className="cp-cart-checkout-btn mt-30">
                                  <div>
                                    <label
                                      className="cp-border-btn"
                                      style={{
                                        width: "100%",
                                        position: "relative",
                                      }}
                                    >
                                      Upload Design
                                      <i
                                        className="fa fa-upload"
                                        aria-hidden="true"
                                      ></i>
                                      <input
                                        name="avatar"
                                        type="file"
                                        disabled={
                                          Checks == "3" ||
                                          Checks == "4" ||
                                          Checks == "5"
                                        }
                                        onChange={changeHandler2}
                                        multiple
                                        accept="image/*"
                                        required
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          left: 0,
                                          width: "100%",
                                          height: "100%",
                                          opacity: 0,
                                          cursor: "pointer",
                                        }}
                                      />
                                    </label>
                                  </div>

                                  {Checks == "3" ||
                                  Checks == "4" ||
                                  Checks == "5" ? (
                                    <>
                                      <div className="row">
                                        {files2.map((file, index) => (
                                          <div key={index} className="col-6">
                                            <img
                                              src={api + file}
                                              height="80px"
                                              width="100%"
                                              className="mt-3 text-center"
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {files2.length > 0 && (
                                        <div className="row">
                                          {files2.map((file, index) => (
                                            <div key={index} className="col-6">
                                              <img
                                                src={URL.createObjectURL(file)}
                                                alt={`Uploaded ${index}`}
                                                height="80px"
                                                width="100%"
                                                className="mt-3 text-center"
                                              />
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6 mt-20">
                                {YES == "NO" ? (
                                  <>
                                    <select
                                      name="priceId2"
                                      onChange={(e) => {
                                        handleChange5(e);
                                      }}
                                      value={form5.priceId2}
                                      required
                                      className="form-select mt-30"
                                      disabled={
                                        Checks == "3" ||
                                        Checks == "4" ||
                                        Checks == "5"
                                      }
                                    >
                                      <option value="">
                                        Select Quantity & Price
                                      </option>
                                      {Price.map((data, key) => {
                                        return (
                                          <option key={key} value={data._id}>
                                            Quantity {data.value} ( ₹
                                            {data.price})
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </>
                                ) : (
                                  <>
                                    <div className="row">
                                      <div className="col-md-6 ">
                                        <label>Height</label>
                                        <span className="text-danger">*</span>
                                        <input
                                          type="number"
                                          className="form-control"
                                          name="height3"
                                          required
                                          placeholder="Enter height"
                                          onChange={(e) => {
                                            handleprices(e);
                                          }}
                                          disabled={
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                          value={siziesprice.height3}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <label>Width</label>
                                        <span className="text-danger">*</span>
                                        <input
                                          type="number"
                                          className="form-control "
                                          name="width3"
                                          required
                                          placeholder="Enter width"
                                          onChange={(e) => {
                                            handleprices3(e);
                                          }}
                                          disabled={
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                          value={siziesprice.width3}
                                        />
                                      </div>

                                      <div className="col-md-12">
                                        <select
                                          name="priceId2"
                                          onChange={(e) => {
                                            handleChange5(e);
                                          }}
                                          value={form5.priceId2}
                                          required
                                          className="form-select mt-30"
                                          disabled={
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                        >
                                          <option value="">
                                            Select Quantity & Price
                                          </option>
                                          {Price2.map((data, key) => {
                                            return (
                                              <option
                                                key={key}
                                                value={data._id}
                                              >
                                                Quantity {data.value} ( ₹
                                                {data.price})
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="col-md-2 mt-20">
                                <button
                                  className="cp-border-btn mt-30"
                                  type="submit"
                                  style={{
                                    width: "100%",
                                    background:
                                      Checks == "3" ||
                                      Checks == "4" ||
                                      Checks == "5"
                                        ? "gray"
                                        : "",
                                  }}
                                  disabled={
                                    Checks == "3" ||
                                    Checks == "4" ||
                                    Checks == "5"
                                  }
                                >
                                  Add
                                  <i
                                    className="fa fa-cart-arrow-down"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </form>
                        </>
                      ) : (
                        <></>
                      )}

                      {uuids.orders == "4" ? (
                        <>
                          {/* form 1 */}
                          <form
                            onSubmit={(e) => {
                              formsubmit5(e);
                            }}
                          >
                            <div className="row">
                              <div className="col-md-4 mt-20">
                                <div className="cp-cart-checkout-btn mt-30">
                                  <div>
                                    <label
                                      className="cp-border-btn"
                                      style={{
                                        width: "100%",
                                        position: "relative",
                                      }}
                                    >
                                      Upload Design
                                      <i
                                        className="fa fa-upload"
                                        aria-hidden="true"
                                      ></i>
                                      <input
                                        name="avatar"
                                        type="file"
                                        onChange={changeHandler}
                                        multiple
                                        accept="image/*"
                                        required
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          left: 0,
                                          width: "100%",
                                          height: "100%",
                                          opacity: 0,
                                          cursor: "pointer",
                                        }}
                                        disabled={
                                          Checks == "1" ||
                                          Checks == "2" ||
                                          Checks == "3" ||
                                          Checks == "4" ||
                                          Checks == "5"
                                        }
                                      />
                                    </label>
                                    <small
                                      style={{ color: "red", fontSize: "10px" }}
                                    >
                                      Note : (JPEG ,TIFF ,PSD ,CDR ,PDF, EPS
                                      ,PNG ,JPG) upload have one of these
                                      extensions
                                    </small>
                                  </div>

                                  {Checks == "1" ||
                                  Checks == "2" ||
                                  Checks == "3" ||
                                  Checks == "4" ||
                                  Checks == "5" ? (
                                    <>
                                      <div className="row">
                                        {files.map((file, index) => (
                                          <div key={index} className="col-6">
                                            <img
                                              src={api + file}
                                              height="80px"
                                              width="100%"
                                              className="mt-3 text-center"
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {files.length > 0 && (
                                        <div className="row">
                                          {files.map((file, index) => (
                                            <div key={index} className="col-6">
                                              <img
                                                src={URL.createObjectURL(file)}
                                                alt={`Uploaded ${index}`}
                                                height="80px"
                                                width="100%"
                                                className="mt-3 text-center"
                                              />
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-6 mt-20">
                                {YES == "NO" ? (
                                  <>
                                    <select
                                      name="priceId"
                                      onChange={(e) => {
                                        handleChange5(e);
                                      }}
                                      value={form5.priceId}
                                      required
                                      disabled={
                                        Checks == "1" ||
                                        Checks == "2" ||
                                        Checks == "3" ||
                                        Checks == "4" ||
                                        Checks == "5"
                                      }
                                      className="form-select mt-30"
                                    >
                                      <option value="">
                                        Select Quantity & Price
                                      </option>
                                      {Price.map((data, key) => {
                                        return (
                                          <option key={key} value={data._id}>
                                            Quantity {data.value} ( ₹
                                            {data.price})
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </>
                                ) : (
                                  <>
                                    <div className="row">
                                      <div className="col-md-6 ">
                                        <label>Height</label>
                                        <span className="text-danger">*</span>
                                        <input
                                          type="number"
                                          className="form-control"
                                          name="height1"
                                          required
                                          placeholder="Enter height"
                                          onChange={(e) => {
                                            handleprices(e);
                                          }}
                                          disabled={
                                            Checks == "1" ||
                                            Checks == "2" ||
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                          value={siziesprice.height1}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <label>Width</label>
                                        <span className="text-danger">*</span>
                                        <input
                                          type="number"
                                          className="form-control "
                                          name="width1"
                                          required
                                          placeholder="Enter width"
                                          onChange={(e) => {
                                            handleprices1(e);
                                          }}
                                          disabled={
                                            Checks == "1" ||
                                            Checks == "2" ||
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                          value={siziesprice.width1}
                                        />
                                      </div>

                                      <div className="col-md-12">
                                        <select
                                          name="priceId"
                                          onChange={(e) => {
                                            handleChange5(e);
                                          }}
                                          value={form5.priceId}
                                          required
                                          disabled={
                                            Checks == "1" ||
                                            Checks == "2" ||
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                          className="form-select mt-3"
                                        >
                                          <option value="">
                                            Select Quantity & Price
                                          </option>
                                          {Price.map((data, key) => {
                                            return (
                                              <option
                                                key={key}
                                                value={data._id}
                                              >
                                                Quantity {data.value} ( ₹
                                                {data.price})
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>

                              <div className="col-md-2 mt-20">
                                <button
                                  className="cp-border-btn mt-30"
                                  type="submit"
                                  style={{
                                    width: "100%",
                                    background:
                                      Checks == "1" ||
                                      Checks == "2" ||
                                      Checks == "3" ||
                                      Checks == "4" ||
                                      Checks == "5"
                                        ? "gray"
                                        : "",
                                  }}
                                  disabled={
                                    Checks == "1" ||
                                    Checks == "2" ||
                                    Checks == "3" ||
                                    Checks == "4" ||
                                    Checks == "5"
                                  }
                                >
                                  Add
                                  <i
                                    className="fa fa-cart-arrow-down"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </form>

                          {/* form 2 */}
                          <form
                            onSubmit={(e) => {
                              formsubmit6(e);
                            }}
                          >
                            <div className="row">
                              <div className="col-md-4 mt-20">
                                <div className="cp-cart-checkout-btn mt-30">
                                  <div>
                                    <label
                                      className="cp-border-btn"
                                      style={{
                                        width: "100%",
                                        position: "relative",
                                      }}
                                    >
                                      Upload Design
                                      <i
                                        className="fa fa-upload"
                                        aria-hidden="true"
                                      ></i>
                                      <input
                                        name="avatar"
                                        type="file"
                                        onChange={changeHandler1}
                                        multiple
                                        accept="image/*"
                                        required
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          left: 0,
                                          width: "100%",
                                          height: "100%",
                                          opacity: 0,
                                          cursor: "pointer",
                                        }}
                                        disabled={
                                          Checks == "2" ||
                                          Checks == "3" ||
                                          Checks == "4" ||
                                          Checks == "5"
                                        }
                                      />
                                    </label>
                                  </div>

                                  {Checks == "2" ||
                                  Checks == "3" ||
                                  Checks == "4" ||
                                  Checks == "5" ? (
                                    <>
                                      <div className="row">
                                        {files1.map((file, index) => (
                                          <div key={index} className="col-6">
                                            <img
                                              src={api + file}
                                              height="80px"
                                              width="100%"
                                              className="mt-3 text-center"
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {files1.length > 0 && (
                                        <div className="row">
                                          {files1.map((file, index) => (
                                            <div key={index} className="col-6">
                                              <img
                                                src={URL.createObjectURL(file)}
                                                alt={`Uploaded ${index}`}
                                                height="80px"
                                                width="100%"
                                                className="mt-3 text-center"
                                              />
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6 mt-20">
                                {YES == "NO" ? (
                                  <>
                                    <select
                                      name="priceId1"
                                      onChange={(e) => {
                                        handleChange5(e);
                                      }}
                                      value={form5.priceId1}
                                      required
                                      className="form-select mt-30"
                                      disabled={
                                        Checks == "2" ||
                                        Checks == "3" ||
                                        Checks == "4" ||
                                        Checks == "5"
                                      }
                                    >
                                      <option value="">
                                        Select Quantity & Price
                                      </option>
                                      {Price.map((data, key) => {
                                        return (
                                          <option key={key} value={data._id}>
                                            Quantity {data.value} ( ₹
                                            {data.price})
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </>
                                ) : (
                                  <>
                                    <div className="row">
                                      <div className="col-md-6 ">
                                        <label>Height</label>
                                        <span className="text-danger">*</span>
                                        <input
                                          type="number"
                                          className="form-control"
                                          name="height2"
                                          required
                                          placeholder="Enter height"
                                          onChange={(e) => {
                                            handleprices(e);
                                          }}
                                          disabled={
                                            Checks == "2" ||
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                          value={siziesprice.height2}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <label>Width</label>
                                        <span className="text-danger">*</span>
                                        <input
                                          type="number"
                                          className="form-control "
                                          name="width2"
                                          required
                                          placeholder="Enter width"
                                          onChange={(e) => {
                                            handleprices2(e);
                                          }}
                                          disabled={
                                            Checks == "2" ||
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                          value={siziesprice.width2}
                                        />
                                      </div>

                                      <div className="col-md-12">
                                        <select
                                          name="priceId1"
                                          onChange={(e) => {
                                            handleChange5(e);
                                          }}
                                          value={form5.priceId1}
                                          required
                                          className="form-select mt-30"
                                          disabled={
                                            Checks == "2" ||
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                        >
                                          <option value="">
                                            Select Quantity & Price
                                          </option>
                                          {Price1.map((data, key) => {
                                            return (
                                              <option
                                                key={key}
                                                value={data._id}
                                              >
                                                Quantity {data.value} ( ₹
                                                {data.price})
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="col-md-2 mt-20">
                                <button
                                  className="cp-border-btn mt-30"
                                  type="submit"
                                  style={{
                                    width: "100%",
                                    background:
                                      Checks == "2" ||
                                      Checks == "3" ||
                                      Checks == "4" ||
                                      Checks == "5"
                                        ? "gray"
                                        : "",
                                  }}
                                  disabled={
                                    Checks == "2" ||
                                    Checks == "3" ||
                                    Checks == "4" ||
                                    Checks == "5"
                                  }
                                >
                                  Add
                                  <i
                                    className="fa fa-cart-arrow-down"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </form>

                          {/* form 3 */}
                          <form
                            onSubmit={(e) => {
                              formsubmit7(e);
                            }}
                          >
                            <div className="row">
                              <div className="col-md-4 mt-20">
                                <div className="cp-cart-checkout-btn mt-30">
                                  <div>
                                    <label
                                      className="cp-border-btn"
                                      style={{
                                        width: "100%",
                                        position: "relative",
                                      }}
                                    >
                                      Upload Design
                                      <i
                                        className="fa fa-upload"
                                        aria-hidden="true"
                                      ></i>
                                      <input
                                        name="avatar"
                                        type="file"
                                        disabled={
                                          Checks == "3" ||
                                          Checks == "4" ||
                                          Checks == "5"
                                        }
                                        onChange={changeHandler2}
                                        multiple
                                        accept="image/*"
                                        required
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          left: 0,
                                          width: "100%",
                                          height: "100%",
                                          opacity: 0,
                                          cursor: "pointer",
                                        }}
                                      />
                                    </label>
                                  </div>

                                  {Checks == "3" ||
                                  Checks == "4" ||
                                  Checks == "5" ? (
                                    <>
                                      <div className="row">
                                        {files2.map((file, index) => (
                                          <div key={index} className="col-6">
                                            <img
                                              src={api + file}
                                              height="80px"
                                              width="100%"
                                              className="mt-3 text-center"
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {files2.length > 0 && (
                                        <div className="row">
                                          {files2.map((file, index) => (
                                            <div key={index} className="col-6">
                                              <img
                                                src={URL.createObjectURL(file)}
                                                alt={`Uploaded ${index}`}
                                                height="80px"
                                                width="100%"
                                                className="mt-3 text-center"
                                              />
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6 mt-20">
                                {YES == "NO" ? (
                                  <>
                                    <select
                                      name="priceId2"
                                      onChange={(e) => {
                                        handleChange5(e);
                                      }}
                                      value={form5.priceId2}
                                      required
                                      className="form-select mt-30"
                                      disabled={
                                        Checks == "3" ||
                                        Checks == "4" ||
                                        Checks == "5"
                                      }
                                    >
                                      <option value="">
                                        Select Quantity & Price
                                      </option>
                                      {Price.map((data, key) => {
                                        return (
                                          <option key={key} value={data._id}>
                                            Quantity {data.value} ( ₹
                                            {data.price})
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </>
                                ) : (
                                  <>
                                    <div className="row">
                                      <div className="col-md-6 ">
                                        <label>Height</label>
                                        <span className="text-danger">*</span>
                                        <input
                                          type="number"
                                          className="form-control"
                                          name="height3"
                                          required
                                          placeholder="Enter height"
                                          onChange={(e) => {
                                            handleprices(e);
                                          }}
                                          disabled={
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                          value={siziesprice.height3}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <label>Width</label>
                                        <span className="text-danger">*</span>
                                        <input
                                          type="number"
                                          className="form-control "
                                          name="width3"
                                          required
                                          placeholder="Enter width"
                                          onChange={(e) => {
                                            handleprices3(e);
                                          }}
                                          disabled={
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                          value={siziesprice.width3}
                                        />
                                      </div>

                                      <div className="col-md-12">
                                        <select
                                          name="priceId2"
                                          onChange={(e) => {
                                            handleChange5(e);
                                          }}
                                          value={form5.priceId2}
                                          required
                                          className="form-select mt-30"
                                          disabled={
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                        >
                                          <option value="">
                                            Select Quantity & Price
                                          </option>
                                          {Price2.map((data, key) => {
                                            return (
                                              <option
                                                key={key}
                                                value={data._id}
                                              >
                                                Quantity {data.value} ( ₹
                                                {data.price})
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="col-md-2 mt-20">
                                <button
                                  className="cp-border-btn mt-30"
                                  type="submit"
                                  style={{
                                    width: "100%",
                                    background:
                                      Checks == "3" ||
                                      Checks == "4" ||
                                      Checks == "5"
                                        ? "gray"
                                        : "",
                                  }}
                                  disabled={
                                    Checks == "3" ||
                                    Checks == "4" ||
                                    Checks == "5"
                                  }
                                >
                                  Add
                                  <i
                                    className="fa fa-cart-arrow-down"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </form>

                          {/* form 4 */}
                          <form
                            onSubmit={(e) => {
                              formsubmit8(e);
                            }}
                          >
                            <div className="row">
                              <div className="col-md-4 mt-20">
                                <div className="cp-cart-checkout-btn mt-30">
                                  <div>
                                    <label
                                      className="cp-border-btn"
                                      style={{
                                        width: "100%",
                                        position: "relative",
                                      }}
                                    >
                                      Upload Design
                                      <i
                                        className="fa fa-upload"
                                        aria-hidden="true"
                                      ></i>
                                      <input
                                        name="avatar"
                                        type="file"
                                        disabled={
                                          Checks == "4" || Checks == "5"
                                        }
                                        onChange={changeHandler3}
                                        multiple
                                        accept="image/*"
                                        required
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          left: 0,
                                          width: "100%",
                                          height: "100%",
                                          opacity: 0,
                                          cursor: "pointer",
                                        }}
                                      />
                                    </label>
                                  </div>

                                  {Checks == "4" || Checks == "5" ? (
                                    <>
                                      <div className="row">
                                        {files3.map((file, index) => (
                                          <div key={index} className="col-6">
                                            <img
                                              src={api + file}
                                              height="80px"
                                              width="100%"
                                              className="mt-3 text-center"
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {files.length > 0 && (
                                        <div className="row">
                                          {files3.map((file, index) => (
                                            <div key={index} className="col-6">
                                              <img
                                                src={URL.createObjectURL(file)}
                                                alt={`Uploaded ${index}`}
                                                height="80px"
                                                width="100%"
                                                className="mt-3 text-center"
                                              />
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6 mt-20">
                                {YES == "NO" ? (
                                  <>
                                    <select
                                      name="priceId3"
                                      onChange={(e) => {
                                        handleChange5(e);
                                      }}
                                      value={form5.priceId3}
                                      required
                                      disabled={Checks == "4" || Checks == "5"}
                                      className="form-select mt-30"
                                    >
                                      <option value="">
                                        Select Quantity & Price
                                      </option>
                                      {Price.map((data, key) => {
                                        return (
                                          <option key={key} value={data._id}>
                                            Quantity {data.value} ( ₹
                                            {data.price})
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </>
                                ) : (
                                  <>
                                    <div className="row">
                                      <div className="col-md-6 ">
                                        <label>Height</label>
                                        <span className="text-danger">*</span>
                                        <input
                                          type="number"
                                          className="form-control"
                                          name="height4"
                                          required
                                          placeholder="Enter height"
                                          onChange={(e) => {
                                            handleprices(e);
                                          }}
                                          disabled={
                                            Checks == "4" || Checks == "5"
                                          }
                                          value={siziesprice.height4}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <label>Width</label>
                                        <span className="text-danger">*</span>
                                        <input
                                          type="number"
                                          className="form-control "
                                          name="width4"
                                          required
                                          placeholder="Enter width"
                                          onChange={(e) => {
                                            handleprices4(e);
                                          }}
                                          disabled={
                                            Checks == "4" || Checks == "5"
                                          }
                                          value={siziesprice.width4}
                                        />
                                      </div>

                                      <div className="col-md-12">
                                        <select
                                          name="priceId3"
                                          onChange={(e) => {
                                            handleChange5(e);
                                          }}
                                          value={form5.priceId3}
                                          required
                                          disabled={
                                            Checks == "4" || Checks == "5"
                                          }
                                          className="form-select mt-30"
                                        >
                                          <option value="">
                                            Select Quantity & Price
                                          </option>
                                          {Price3.map((data, key) => {
                                            return (
                                              <option
                                                key={key}
                                                value={data._id}
                                              >
                                                Quantity {data.value} ( ₹
                                                {data.price})
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="col-md-2 mt-20">
                                <button
                                  className="cp-border-btn mt-30"
                                  type="submit"
                                  style={{
                                    width: "100%",
                                    background:
                                      Checks == "4" || Checks == "5"
                                        ? "gray"
                                        : "",
                                  }}
                                  disabled={Checks == "4" || Checks == "5"}
                                >
                                  Add
                                  <i
                                    className="fa fa-cart-arrow-down"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </form>
                        </>
                      ) : (
                        <></>
                      )}

                      {uuids.orders == "5" ? (
                        <>
                          {/* form 1 */}
                          <form
                            onSubmit={(e) => {
                              formsubmit5(e);
                            }}
                          >
                            <div className="row">
                              <div className="col-md-4 mt-20">
                                <div className="cp-cart-checkout-btn mt-30">
                                  <div>
                                    <label
                                      className="cp-border-btn"
                                      style={{
                                        width: "100%",
                                        position: "relative",
                                      }}
                                    >
                                      Upload Design
                                      <i
                                        className="fa fa-upload"
                                        aria-hidden="true"
                                      ></i>
                                      <input
                                        name="avatar"
                                        type="file"
                                        onChange={changeHandler}
                                        multiple
                                        accept="image/*"
                                        required
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          left: 0,
                                          width: "100%",
                                          height: "100%",
                                          opacity: 0,
                                          cursor: "pointer",
                                        }}
                                        disabled={
                                          Checks == "1" ||
                                          Checks == "2" ||
                                          Checks == "3" ||
                                          Checks == "4" ||
                                          Checks == "5"
                                        }
                                      />
                                    </label>
                                    <small
                                      style={{ color: "red", fontSize: "10px" }}
                                    >
                                      Note : (JPEG ,TIFF ,PSD ,CDR ,PDF, EPS
                                      ,PNG ,JPG) upload have one of these
                                      extensions
                                    </small>
                                  </div>

                                  {Checks == "1" ||
                                  Checks == "2" ||
                                  Checks == "3" ||
                                  Checks == "4" ||
                                  Checks == "5" ? (
                                    <>
                                      <div className="row">
                                        {files.map((file, index) => (
                                          <div key={index} className="col-6">
                                            <img
                                              src={api + file}
                                              height="80px"
                                              width="100%"
                                              className="mt-3 text-center"
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {files.length > 0 && (
                                        <div className="row">
                                          {files.map((file, index) => (
                                            <div key={index} className="col-6">
                                              <img
                                                src={URL.createObjectURL(file)}
                                                alt={`Uploaded ${index}`}
                                                height="80px"
                                                width="100%"
                                                className="mt-3 text-center"
                                              />
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-6 mt-20">
                                {YES == "NO" ? (
                                  <>
                                    <select
                                      name="priceId"
                                      onChange={(e) => {
                                        handleChange5(e);
                                      }}
                                      value={form5.priceId}
                                      required
                                      disabled={
                                        Checks == "1" ||
                                        Checks == "2" ||
                                        Checks == "3" ||
                                        Checks == "4" ||
                                        Checks == "5"
                                      }
                                      className="form-select mt-30"
                                    >
                                      <option value="">
                                        Select Quantity & Price
                                      </option>
                                      {Price.map((data, key) => {
                                        return (
                                          <option key={key} value={data._id}>
                                            Quantity {data.value} ( ₹
                                            {data.price})
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </>
                                ) : (
                                  <>
                                    <div className="row">
                                      <div className="col-md-6 ">
                                        <label>Height</label>
                                        <span className="text-danger">*</span>
                                        <input
                                          type="number"
                                          className="form-control"
                                          name="height1"
                                          required
                                          placeholder="Enter height"
                                          onChange={(e) => {
                                            handleprices(e);
                                          }}
                                          disabled={
                                            Checks == "1" ||
                                            Checks == "2" ||
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                          value={siziesprice.height1}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <label>Width</label>
                                        <span className="text-danger">*</span>
                                        <input
                                          type="number"
                                          className="form-control "
                                          name="width1"
                                          required
                                          placeholder="Enter width"
                                          onChange={(e) => {
                                            handleprices1(e);
                                          }}
                                          disabled={
                                            Checks == "1" ||
                                            Checks == "2" ||
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                          value={siziesprice.width1}
                                        />
                                      </div>

                                      <div className="col-md-12">
                                        <select
                                          name="priceId"
                                          onChange={(e) => {
                                            handleChange5(e);
                                          }}
                                          value={form5.priceId}
                                          required
                                          disabled={
                                            Checks == "1" ||
                                            Checks == "2" ||
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                          className="form-select mt-3"
                                        >
                                          <option value="">
                                            Select Quantity & Price
                                          </option>
                                          {Price.map((data, key) => {
                                            return (
                                              <option
                                                key={key}
                                                value={data._id}
                                              >
                                                Quantity {data.value} ( ₹
                                                {data.price})
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>

                              <div className="col-md-2 mt-20">
                                <button
                                  className="cp-border-btn mt-30"
                                  type="submit"
                                  style={{
                                    width: "100%",
                                    background:
                                      Checks == "1" ||
                                      Checks == "2" ||
                                      Checks == "3" ||
                                      Checks == "4" ||
                                      Checks == "5"
                                        ? "gray"
                                        : "",
                                  }}
                                  disabled={
                                    Checks == "1" ||
                                    Checks == "2" ||
                                    Checks == "3" ||
                                    Checks == "4" ||
                                    Checks == "5"
                                  }
                                >
                                  Add
                                  <i
                                    className="fa fa-cart-arrow-down"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </form>

                          {/* form 2 */}
                          <form
                            onSubmit={(e) => {
                              formsubmit6(e);
                            }}
                          >
                            <div className="row">
                              <div className="col-md-4 mt-20">
                                <div className="cp-cart-checkout-btn mt-30">
                                  <div>
                                    <label
                                      className="cp-border-btn"
                                      style={{
                                        width: "100%",
                                        position: "relative",
                                      }}
                                    >
                                      Upload Design
                                      <i
                                        className="fa fa-upload"
                                        aria-hidden="true"
                                      ></i>
                                      <input
                                        name="avatar"
                                        type="file"
                                        onChange={changeHandler1}
                                        multiple
                                        accept="image/*"
                                        required
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          left: 0,
                                          width: "100%",
                                          height: "100%",
                                          opacity: 0,
                                          cursor: "pointer",
                                        }}
                                        disabled={
                                          Checks == "2" ||
                                          Checks == "3" ||
                                          Checks == "4" ||
                                          Checks == "5"
                                        }
                                      />
                                    </label>
                                  </div>

                                  {Checks == "2" ||
                                  Checks == "3" ||
                                  Checks == "4" ||
                                  Checks == "5" ? (
                                    <>
                                      <div className="row">
                                        {files1.map((file, index) => (
                                          <div key={index} className="col-6">
                                            <img
                                              src={api + file}
                                              height="80px"
                                              width="100%"
                                              className="mt-3 text-center"
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {files1.length > 0 && (
                                        <div className="row">
                                          {files1.map((file, index) => (
                                            <div key={index} className="col-6">
                                              <img
                                                src={URL.createObjectURL(file)}
                                                alt={`Uploaded ${index}`}
                                                height="80px"
                                                width="100%"
                                                className="mt-3 text-center"
                                              />
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6 mt-20">
                                {YES == "NO" ? (
                                  <>
                                    <select
                                      name="priceId1"
                                      onChange={(e) => {
                                        handleChange5(e);
                                      }}
                                      value={form5.priceId1}
                                      required
                                      className="form-select mt-30"
                                      disabled={
                                        Checks == "2" ||
                                        Checks == "3" ||
                                        Checks == "4" ||
                                        Checks == "5"
                                      }
                                    >
                                      <option value="">
                                        Select Quantity & Price
                                      </option>
                                      {Price.map((data, key) => {
                                        return (
                                          <option key={key} value={data._id}>
                                            Quantity {data.value} ( ₹
                                            {data.price})
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </>
                                ) : (
                                  <>
                                    <div className="row">
                                      <div className="col-md-6 ">
                                        <label>Height</label>
                                        <span className="text-danger">*</span>
                                        <input
                                          type="number"
                                          className="form-control"
                                          name="height2"
                                          required
                                          placeholder="Enter height"
                                          onChange={(e) => {
                                            handleprices(e);
                                          }}
                                          disabled={
                                            Checks == "2" ||
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                          value={siziesprice.height2}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <label>Width</label>
                                        <span className="text-danger">*</span>
                                        <input
                                          type="number"
                                          className="form-control "
                                          name="width2"
                                          required
                                          placeholder="Enter width"
                                          onChange={(e) => {
                                            handleprices2(e);
                                          }}
                                          disabled={
                                            Checks == "2" ||
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                          value={siziesprice.width2}
                                        />
                                      </div>

                                      <div className="col-md-12">
                                        <select
                                          name="priceId1"
                                          onChange={(e) => {
                                            handleChange5(e);
                                          }}
                                          value={form5.priceId1}
                                          required
                                          className="form-select mt-30"
                                          disabled={
                                            Checks == "2" ||
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                        >
                                          <option value="">
                                            Select Quantity & Price
                                          </option>
                                          {Price1.map((data, key) => {
                                            return (
                                              <option
                                                key={key}
                                                value={data._id}
                                              >
                                                Quantity {data.value} ( ₹
                                                {data.price})
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="col-md-2 mt-20">
                                <button
                                  className="cp-border-btn mt-30"
                                  type="submit"
                                  style={{
                                    width: "100%",
                                    background:
                                      Checks == "2" ||
                                      Checks == "3" ||
                                      Checks == "4" ||
                                      Checks == "5"
                                        ? "gray"
                                        : "",
                                  }}
                                  disabled={
                                    Checks == "2" ||
                                    Checks == "3" ||
                                    Checks == "4" ||
                                    Checks == "5"
                                  }
                                >
                                  Add
                                  <i
                                    className="fa fa-cart-arrow-down"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </form>

                          {/* form 3 */}
                          <form
                            onSubmit={(e) => {
                              formsubmit7(e);
                            }}
                          >
                            <div className="row">
                              <div className="col-md-4 mt-20">
                                <div className="cp-cart-checkout-btn mt-30">
                                  <div>
                                    <label
                                      className="cp-border-btn"
                                      style={{
                                        width: "100%",
                                        position: "relative",
                                      }}
                                    >
                                      Upload Design
                                      <i
                                        className="fa fa-upload"
                                        aria-hidden="true"
                                      ></i>
                                      <input
                                        name="avatar"
                                        type="file"
                                        disabled={
                                          Checks == "3" ||
                                          Checks == "4" ||
                                          Checks == "5"
                                        }
                                        onChange={changeHandler2}
                                        multiple
                                        accept="image/*"
                                        required
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          left: 0,
                                          width: "100%",
                                          height: "100%",
                                          opacity: 0,
                                          cursor: "pointer",
                                        }}
                                      />
                                    </label>
                                  </div>

                                  {Checks == "3" ||
                                  Checks == "4" ||
                                  Checks == "5" ? (
                                    <>
                                      <div className="row">
                                        {files2.map((file, index) => (
                                          <div key={index} className="col-6">
                                            <img
                                              src={api + file}
                                              height="80px"
                                              width="100%"
                                              className="mt-3 text-center"
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {files2.length > 0 && (
                                        <div className="row">
                                          {files2.map((file, index) => (
                                            <div key={index} className="col-6">
                                              <img
                                                src={URL.createObjectURL(file)}
                                                alt={`Uploaded ${index}`}
                                                height="80px"
                                                width="100%"
                                                className="mt-3 text-center"
                                              />
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6 mt-20">
                                {YES == "NO" ? (
                                  <>
                                    <select
                                      name="priceId2"
                                      onChange={(e) => {
                                        handleChange5(e);
                                      }}
                                      value={form5.priceId2}
                                      required
                                      className="form-select mt-30"
                                      disabled={
                                        Checks == "3" ||
                                        Checks == "4" ||
                                        Checks == "5"
                                      }
                                    >
                                      <option value="">
                                        Select Quantity & Price
                                      </option>
                                      {Price.map((data, key) => {
                                        return (
                                          <option key={key} value={data._id}>
                                            Quantity {data.value} ( ₹
                                            {data.price})
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </>
                                ) : (
                                  <>
                                    <div className="row">
                                      <div className="col-md-6 ">
                                        <label>Height</label>
                                        <span className="text-danger">*</span>
                                        <input
                                          type="number"
                                          className="form-control"
                                          name="height3"
                                          required
                                          placeholder="Enter height"
                                          onChange={(e) => {
                                            handleprices(e);
                                          }}
                                          disabled={
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                          value={siziesprice.height3}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <label>Width</label>
                                        <span className="text-danger">*</span>
                                        <input
                                          type="number"
                                          className="form-control "
                                          name="width3"
                                          required
                                          placeholder="Enter width"
                                          onChange={(e) => {
                                            handleprices3(e);
                                          }}
                                          disabled={
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                          value={siziesprice.width3}
                                        />
                                      </div>

                                      <div className="col-md-12">
                                        <select
                                          name="priceId2"
                                          onChange={(e) => {
                                            handleChange5(e);
                                          }}
                                          value={form5.priceId2}
                                          required
                                          className="form-select mt-30"
                                          disabled={
                                            Checks == "3" ||
                                            Checks == "4" ||
                                            Checks == "5"
                                          }
                                        >
                                          <option value="">
                                            Select Quantity & Price
                                          </option>
                                          {Price2.map((data, key) => {
                                            return (
                                              <option
                                                key={key}
                                                value={data._id}
                                              >
                                                Quantity {data.value} ( ₹
                                                {data.price})
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="col-md-2 mt-20">
                                <button
                                  className="cp-border-btn mt-30"
                                  type="submit"
                                  style={{
                                    width: "100%",
                                    background:
                                      Checks == "3" ||
                                      Checks == "4" ||
                                      Checks == "5"
                                        ? "gray"
                                        : "",
                                  }}
                                  disabled={
                                    Checks == "3" ||
                                    Checks == "4" ||
                                    Checks == "5"
                                  }
                                >
                                  Add
                                  <i
                                    className="fa fa-cart-arrow-down"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </form>

                          {/* form 4 */}
                          <form
                            onSubmit={(e) => {
                              formsubmit8(e);
                            }}
                          >
                            <div className="row">
                              <div className="col-md-4 mt-20">
                                <div className="cp-cart-checkout-btn mt-30">
                                  <div>
                                    <label
                                      className="cp-border-btn"
                                      style={{
                                        width: "100%",
                                        position: "relative",
                                      }}
                                    >
                                      Upload Design
                                      <i
                                        className="fa fa-upload"
                                        aria-hidden="true"
                                      ></i>
                                      <input
                                        name="avatar"
                                        type="file"
                                        disabled={
                                          Checks == "4" || Checks == "5"
                                        }
                                        onChange={changeHandler3}
                                        multiple
                                        accept="image/*"
                                        required
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          left: 0,
                                          width: "100%",
                                          height: "100%",
                                          opacity: 0,
                                          cursor: "pointer",
                                        }}
                                      />
                                    </label>
                                  </div>

                                  {Checks == "4" || Checks == "5" ? (
                                    <>
                                      <div className="row">
                                        {files3.map((file, index) => (
                                          <div key={index} className="col-6">
                                            <img
                                              src={api + file}
                                              height="80px"
                                              width="100%"
                                              className="mt-3 text-center"
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {files3.length > 0 && (
                                        <div className="row">
                                          {files3.map((file, index) => (
                                            <div key={index} className="col-6">
                                              <img
                                                src={URL.createObjectURL(file)}
                                                alt={`Uploaded ${index}`}
                                                height="80px"
                                                width="100%"
                                                className="mt-3 text-center"
                                              />
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6 mt-20">
                                {YES == "NO" ? (
                                  <>
                                    <select
                                      name="priceId3"
                                      onChange={(e) => {
                                        handleChange5(e);
                                      }}
                                      value={form5.priceId3}
                                      required
                                      disabled={Checks == "4" || Checks == "5"}
                                      className="form-select mt-30"
                                    >
                                      <option value="">
                                        Select Quantity & Price
                                      </option>
                                      {Price.map((data, key) => {
                                        return (
                                          <option key={key} value={data._id}>
                                            Quantity {data.value} ( ₹
                                            {data.price})
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </>
                                ) : (
                                  <>
                                    <div className="row">
                                      <div className="col-md-6 ">
                                        <label>Height</label>
                                        <span className="text-danger">*</span>
                                        <input
                                          type="number"
                                          className="form-control"
                                          name="height4"
                                          required
                                          placeholder="Enter height"
                                          onChange={(e) => {
                                            handleprices(e);
                                          }}
                                          disabled={
                                            Checks == "4" || Checks == "5"
                                          }
                                          value={siziesprice.height4}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <label>Width</label>
                                        <span className="text-danger">*</span>
                                        <input
                                          type="number"
                                          className="form-control "
                                          name="width4"
                                          required
                                          placeholder="Enter width"
                                          onChange={(e) => {
                                            handleprices4(e);
                                          }}
                                          disabled={
                                            Checks == "4" || Checks == "5"
                                          }
                                          value={siziesprice.width4}
                                        />
                                      </div>

                                      <div className="col-md-12">
                                        <select
                                          name="priceId3"
                                          onChange={(e) => {
                                            handleChange5(e);
                                          }}
                                          value={form5.priceId3}
                                          required
                                          disabled={
                                            Checks == "4" || Checks == "5"
                                          }
                                          className="form-select mt-30"
                                        >
                                          <option value="">
                                            Select Quantity & Price
                                          </option>
                                          {Price3.map((data, key) => {
                                            return (
                                              <option
                                                key={key}
                                                value={data._id}
                                              >
                                                Quantity {data.value} ( ₹
                                                {data.price})
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="col-md-2 mt-20">
                                <button
                                  className="cp-border-btn mt-30"
                                  type="submit"
                                  style={{
                                    width: "100%",
                                    background:
                                      Checks == "4" || Checks == "5"
                                        ? "gray"
                                        : "",
                                  }}
                                  disabled={Checks == "4" || Checks == "5"}
                                >
                                  Add
                                  <i
                                    className="fa fa-cart-arrow-down"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </form>

                          {/* form 5 */}
                          <form
                            onSubmit={(e) => {
                              formsubmit9(e);
                            }}
                          >
                            <div className="row">
                              <div className="col-md-4 mt-20">
                                <div className="cp-cart-checkout-btn mt-30">
                                  <div>
                                    <label
                                      className="cp-border-btn"
                                      style={{
                                        width: "100%",
                                        position: "relative",
                                      }}
                                    >
                                      Upload Design
                                      <i
                                        className="fa fa-upload"
                                        aria-hidden="true"
                                      ></i>
                                      <input
                                        name="avatar"
                                        type="file"
                                        onChange={changeHandler4}
                                        multiple
                                        accept="image/*"
                                        disabled={Checks == "5"}
                                        required
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          left: 0,
                                          width: "100%",
                                          height: "100%",
                                          opacity: 0,
                                          cursor: "pointer",
                                        }}
                                      />
                                    </label>
                                  </div>

                                  {Checks == "5" ? (
                                    <>
                                      <div className="row">
                                        {files4.map((file, index) => (
                                          <div key={index} className="col-6">
                                            <img
                                              src={api + file}
                                              height="80px"
                                              width="100%"
                                              className="mt-3 text-center"
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {files4.length > 0 && (
                                        <div className="row">
                                          {files4.map((file, index) => (
                                            <div key={index} className="col-6">
                                              <img
                                                src={URL.createObjectURL(file)}
                                                alt={`Uploaded ${index}`}
                                                height="80px"
                                                width="100%"
                                                className="mt-3 text-center"
                                              />
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6 mt-20">
                                {YES == "NO" ? (
                                  <>
                                    <select
                                      name="priceId4"
                                      onChange={(e) => {
                                        handleChange5(e);
                                      }}
                                      disabled={Checks == "5"}
                                      value={form5.priceId4}
                                      required
                                      className="form-select mt-30"
                                    >
                                      <option value="">
                                        Select Quantity & Price
                                      </option>
                                      {Price.map((data, key) => {
                                        return (
                                          <option key={key} value={data._id}>
                                            Quantity {data.value} ( ₹
                                            {data.price})
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </>
                                ) : (
                                  <>
                                    <div className="row">
                                      <div className="col-md-6 ">
                                        <label>Height</label>
                                        <span className="text-danger">*</span>
                                        <input
                                          type="number"
                                          className="form-control"
                                          name="height5"
                                          required
                                          placeholder="Enter height"
                                          onChange={(e) => {
                                            handleprices(e);
                                          }}
                                          disabled={Checks == "5"}
                                          value={siziesprice.height5}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <label>Width</label>
                                        <span className="text-danger">*</span>
                                        <input
                                          type="number"
                                          className="form-control "
                                          name="width5"
                                          required
                                          placeholder="Enter width"
                                          onChange={(e) => {
                                            handleprices5(e);
                                          }}
                                          disabled={Checks == "5"}
                                          value={siziesprice.width5}
                                        />
                                      </div>

                                      <div className="col-md-12">
                                        <select
                                          name="priceId4"
                                          onChange={(e) => {
                                            handleChange5(e);
                                          }}
                                          disabled={Checks == "5"}
                                          value={form5.priceId4}
                                          required
                                          className="form-select mt-30"
                                        >
                                          <option value="">
                                            Select Quantity & Price
                                          </option>
                                          {Price4.map((data, key) => {
                                            return (
                                              <option
                                                key={key}
                                                value={data._id}
                                              >
                                                Quantity {data.value} ( ₹
                                                {data.price})
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="col-md-2 mt-20">
                                <button
                                  className="cp-border-btn mt-30"
                                  type="submit"
                                  style={{
                                    width: "100%",
                                    background: Checks == "5" ? "gray" : "",
                                  }}
                                  disabled={Checks == "5"}
                                >
                                  Add
                                  <i
                                    className="fa fa-cart-arrow-down"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </form>
                        </>
                      ) : (
                        <></>
                      )}

                      {uuids.orders == Checks ? (
                        <>
                          <div
                            className="col-md-4 mt-50"
                            style={{ float: "right" }}
                          >
                            <div className="cp-cart-checkout-btn mt-30">
                              <button
                                type="submit"
                                className="cp-border-btn"
                                style={{ width: "100%" }}
                                onClick={() => {
                                  databyids();
                                }}
                              >
                                Proceed
                              </button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="col-md-4 mt-50"
                            style={{ float: "right" }}
                          >
                            <div className="cp-cart-checkout-btn mt-30">
                              <button
                                type="submit"
                                className="cp-border-btn"
                                style={{ width: "100%", background: "gray" }}
                                onClick={() => {
                                  databyids();
                                }}
                                disabled
                              >
                                Proceed
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <section className="mt-100">
                      <div className="container">
                        <div className="row">
                          <div className="row justify-content-center">
                            <div className="col-sm-12">
                              <div className="cp-case-study-title mb-60 t-center">
                                <div className="cp-section-title">
                                  <h3 style={{ color: "#D73135" }}>
                                    Related Products
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            {ProductsCat?.slice(0, 4).map((data, i) => (
                              <>
                                <div
                                  className="col-md-3  p-2"
                                  id={i == 0 ? "kklp1" : "kklp"}
                                  key={i}
                                >
                                  <div className="card" id="ops">
                                    <div className="card-body"></div>
                                    <p
                                      className="text-center"
                                      style={{
                                        color: "black",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {data.name.slice(0, 20)}...
                                      <span className="text-danger">
                                        <img
                                          src="assets/right.png"
                                          alt="logo"
                                          onClick={() => {
                                            databyid(data);
                                          }}
                                          style={{
                                            paddingLeft: "10px",
                                            height: "32px",
                                            cursor: "pointer",
                                          }}
                                        />
                                      </span>
                                    </p>
                                  </div>
                                  <div
                                    className="cp-services-item t-center mb-30 wow fadeInUp animated"
                                    id="opsd"
                                    data-wow-duration="1.5s"
                                    data-wow-delay=".4s"
                                  >
                                    <div className="float-right">
                                      <img
                                        src={api + data.image}
                                        alt="img not found"
                                        style={{
                                          height: "250px",
                                          width: "250px",
                                          cursor: "pointer",
                                        }}
                                        id="toaps"
                                        onClick={() => {
                                          databyid(data);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </main>

          <Modal show={show} size="lg" centered>
            <div className="container">
              <div className="row">
                <div className="col-md-6" id="opp">
                  <h2
                    className="text-white"
                    style={{ paddingTop: "80px", paddingLeft: "50px" }}
                  >
                    Login
                  </h2>
                  <p className="text-white" style={{ paddingLeft: "50px" }}>
                    Get access to your Orders, Wishlist and Recommendations
                  </p>
                  <img
                    src="assets/img/jkl.png"
                    style={{ width: "70%", margin: "50px" }}
                  ></img>
                </div>
                <div className="col-md-6" id="opps">
                  <div className="cp-signin-wrap">
                    {!showOtpForm && (
                      <form
                        onSubmit={(e) => {
                          formsubmit(e);
                        }}
                      >
                        <div className=" mt-40">
                          <label htmlFor="name">Enter Mobile Number</label>
                          <input
                            type="text"
                            className="form-control"
                            name="phone"
                            required
                            maxlength="10"
                            minLength="10"
                            pattern="[0-9]{10}"
                            placeholder="Enter Mobile Number"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            value={form.phone}
                          />

                          <small
                            style={{ fontSize: "11px", marginTop: "20px" }}
                          >
                            By continuing, you to OD Card’s
                            <span
                              style={{
                                color: "blue",
                              }}
                            >
                              <NavLink
                                to="/termsandconditions"
                                style={{
                                  margin: "2px",
                                }}
                              >
                                Terms & Conditions
                              </NavLink>
                            </span>
                            <br></br>
                            and
                            <span
                              style={{
                                color: "blue",
                                margin: "2px",
                              }}
                            >
                              <NavLink to="/privacypolicy">
                                Privacy policy
                              </NavLink>
                            </span>
                          </small>
                        </div>

                        <div className="cp-input-field sign-flex checkbox d-flex align-items-center justify-content-between"></div>
                        <div className="cp-sign-btn mt-35 mb-30">
                          <button
                            type="submit"
                            className="cp-border-btn"
                            style={{ width: "100%" }}
                          >
                            Request OTP
                          </button>
                        </div>
                      </form>
                    )}

                    {showOtpForm && (
                      <form
                        onSubmit={(e) => {
                          formsubmit2(e);
                        }}
                      >
                        <div className=" mt-40">
                          <label htmlFor="name">Enter OTP</label>
                          <input
                            type="text"
                            className="form-control"
                            name="otp"
                            required
                            maxlength="6"
                            minLength="6"
                            pattern="[0-9]{6}"
                            placeholder="Enter OTP"
                            onChange={(e) => {
                              handleChange2(e);
                            }}
                            value={form2.otp}
                          />

                          <small
                            style={{ fontSize: "11px", marginTop: "20px" }}
                          >
                            By continuing, you to OD Card’s
                            <span
                              style={{
                                color: "blue",
                              }}
                            >
                              <NavLink
                                to="/termsandconditions"
                                style={{
                                  margin: "2px",
                                }}
                              >
                                Terms & Conditions
                              </NavLink>
                            </span>
                            <br></br>
                            and
                            <span
                              style={{
                                color: "blue",
                                margin: "2px",
                              }}
                            >
                              <NavLink to="/privacypolicy">
                                Privacy policy
                              </NavLink>
                            </span>
                          </small>
                        </div>

                        <div className="cp-input-field sign-flex checkbox d-flex align-items-center justify-content-between"></div>
                        <div className="cp-sign-btn mt-35 mb-30">
                          <button
                            type="submit"
                            className="cp-border-btn"
                            style={{ width: "100%" }}
                          >
                            Verify
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <Modal show={show1} size="lg" centered>
            <div className="container">
              <div className="row">
                <div className="col-md-6" id="opp">
                  <h2
                    className="text-white"
                    style={{ paddingTop: "80px", paddingLeft: "50px" }}
                  >
                    Register
                  </h2>
                  <p className="text-white" style={{ paddingLeft: "50px" }}>
                    Get access to your Orders, Wishlist and Recommendations
                  </p>
                  <img
                    src="assets/img/jkl.png"
                    style={{ width: "70%", margin: "50px" }}
                  ></img>
                </div>
                <div className="col-md-6" id="opps">
                  <div className="cp-signin-wrap">
                    <form
                      onSubmit={(e) => {
                        formsubmit3(e);
                      }}
                    >
                      <div className=" mt-40">
                        <div className="mb-10">
                          <label htmlFor="name">Enter First Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="firstName"
                            required
                            placeholder="Enter First Name"
                            onChange={(e) => {
                              handleChange3(e);
                            }}
                            value={form3.firstName}
                          />
                        </div>

                        <div className="mb-10">
                          <label htmlFor="name">Enter Last Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="lastName"
                            required
                            placeholder="Enter Last Name"
                            onChange={(e) => {
                              handleChange3(e);
                            }}
                            value={form3.lastName}
                          />
                        </div>

                        <div className="mb-10">
                          <label htmlFor="name">Enter Mobile Number</label>
                          <input
                            type="text"
                            className="form-control"
                            name="phone"
                            required
                            maxlength="10"
                            minLength="10"
                            pattern="[0-9]{10}"
                            placeholder="Enter Mobile Number"
                            disabled
                            value={phone}
                          />
                        </div>

                        <div className="mb-10">
                          <label htmlFor="name">Enter Email</label>
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            required
                            placeholder="Enter Email"
                            onChange={(e) => {
                              handleChange3(e);
                            }}
                            value={form3.email}
                          />
                        </div>

                        <div className="mb-10">
                          <label htmlFor="name">Enter Address</label>
                          <input
                            type="text"
                            className="form-control"
                            name="address"
                            required
                            placeholder="Enter Address"
                            onChange={(e) => {
                              handleChange3(e);
                            }}
                            value={form3.address}
                          />
                        </div>

                        <small style={{ fontSize: "11px", marginTop: "20px" }}>
                          By continuing, you to OD Card’s
                          <span
                            style={{
                              color: "blue",
                            }}
                          >
                            <NavLink
                              to="/termsandconditions"
                              style={{
                                margin: "2px",
                              }}
                            >
                              Terms & Conditions
                            </NavLink>
                          </span>
                          <br></br>
                          and
                          <span
                            style={{
                              color: "blue",
                              margin: "2px",
                            }}
                          >
                            <NavLink to="/privacypolicy">
                              Privacy policy
                            </NavLink>
                          </span>
                        </small>
                      </div>

                      <div className="cp-input-field sign-flex checkbox d-flex align-items-center justify-content-between"></div>
                      <div className="cp-sign-btn mt-35 mb-30">
                        <button
                          type="submit"
                          className="cp-border-btn"
                          style={{ width: "100%" }}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Footer />
          <ToastContainer />
        </>
      )}
    </div>
  );
}

export default ProductDetails;
