import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

function Cart() {
  const [Products, setProducts] = useState([]);

  const [Price, setPrice] = useState([]);

  useEffect(() => {
    datas();
  }, []);

  const token = sessionStorage.getItem("token");

  const datas = () => {
    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/cart/getallcarts",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setProducts(res.data.cartitems);
            setPrice(res.data);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("Data was not getting");
          }
        }
      );
  };

  const productsvalueprice = localStorage.getItem("totalprice");

  var coupon_Id = localStorage.getItem("coupon_Id");

  const formsubmit1 = (e) => {
    e.preventDefault();
    Add1();
  };

  const couponAmount = localStorage.getItem("couponAmount");

  const [form1, setform1] = useState([]);

  const handleChange1 = (e) => {
    let myUser = { ...form1 };
    myUser[e.target.name] = e.target.value;
    setform1(myUser);
  };

  const Add1 = () => {
    const dataArray = {
      couponId: form1.couponCode,
      amount: productsvalueprice,
    };

    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/couponcheckstatusapi",
        dataArray,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            localStorage.setItem("couponCode", form1.couponCode);
            localStorage.setItem("couponAmount", res.data.couponAmount);
            localStorage.setItem("coupon_Id", res.data.coupon_Id);
            const couponAmount = Number(res.data.couponAmount);
            const Amount = Number(localStorage.getItem("totalprice"));
            const dis = Amount - couponAmount;
            localStorage.setItem("totalprice", dis);
            window.location.reload();
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const [form3, setform3] = useState([]);
  const [form4, setform4] = useState([]);

  const phone = sessionStorage.getItem("mobilenos");

  useEffect(() => {
    GetProfile();
    GetAddress();
  }, []);

  const GetProfile = () => {
    const userid = sessionStorage.getItem("userid");

    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/auth/getuserprofile",
        { userId: userid },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setform3(res.data.userData);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            //console.log("Data was not getting")
          }
        }
      );
  };

  const addressid = sessionStorage.getItem("Adddressid");

  const GetAddress = () => {
    const userid = sessionStorage.getItem("Adddressid");

    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/address/getuseraddressbyid",
        { id: userid },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setform4(res.data.userAddress);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            //console.log("Data was not getting")
          }
        }
      );
  };

  const navigate = useNavigate();

  const formsubmit3 = (e) => {
    e.preventDefault();
    Add3();
  };

  const Add3 = () => {
    let df = Price.deliveryCharges + Number(productsvalueprice);

    let dis = localStorage.getItem("couponAmount");

    let totalss = parseFloat(df) * 100;

    const options = {
      key: "rzp_test_HJG5Rtuy8Xh2NB",
      amount: totalss,
      currency: "INR",
      name: "Od Cards",
      description: "Od Cards Transaction",
      image: "Od Cards",

      handler: function (response) {
        let tranid = response.razorpay_payment_id;

        const dataArray = {
          userId: form3._id,
          userAddressId: addressid,
          amount: df,
          couponId: coupon_Id,
          couponDiscount: dis == "" ? 0 : dis,
        };

        axios
          .post(
            "https://api.odcards.com/v1/odcardsapi/web/order/addorder",
            dataArray,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )

          .then(
            (res) => {
              if (res.status === 200) {
                toast(res.data.message);
                let orderid = res.data.order._id;
                var token = sessionStorage.getItem("token");

                const dataArrays = {
                  transactionId: tranid,
                  userId: form3._id,
                  orderid: orderid,
                  couponId: coupon_Id,
                };

                axios
                  .post(
                    "https://api.odcards.com/v1/odcardsapi/web/payment/addpayment",
                    dataArrays,
                    {
                      headers: { Authorization: `Bearer ${token}` },
                    }
                  )
                  .then(
                    (res) => {
                      if (res.status === 200) {
                        toast(res.data.message);
                        navigate("/");
                        localStorage.clear();
                        rzp1.close();
                      }
                    },
                    (error) => {
                      if (error.response && error.response.status === 400) {
                        toast(error.response.data.message);
                        rzp1.close();
                      }
                    }
                  );
              }
            },
            (error) => {
              if (error.response && error.response.status === 400) {
                toast(error.response.data.message);
              }
            }
          );
      },
      prefill: {
        name: sessionStorage.getItem("name"),
        email: sessionStorage.getItem("email"),
        contact: sessionStorage.getItem("phone"),
      },
      notes: {
        address: "",
      },
      theme: {
        color: "#015A65",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const removeCopon = () => {
    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/cart/getallcarts",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("totalprice", res.data.subTotal);
          localStorage.setItem("coupon_Id", "");
          localStorage.setItem("couponAmount", "");
          window.location.reload();
        }
      });
  };

  return (
    <div>
      <Header />
      <section className="cp-cart-area pt-50 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <p className="mb-4">Home / Checkout / Shipping details</p>
            </div>
            <div className="col-md-4">
              {coupon_Id == "" ? (
                <>
                  <form
                    onSubmit={(e) => {
                      formsubmit1(e);
                    }}
                  >
                    <div className="row">
                      <div className="col-md-10">
                        <div class="cp-input-field ">
                          <input
                            type="text"
                            placeholder="Coupon code"
                            onChange={(e) => {
                              handleChange1(e);
                            }}
                            name="couponCode"
                            value={form1.couponCode}
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="cp-cart-checkout-btn mt-2">
                          <button type="submit" className="cp-border-btn">
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </>
              ) : (
                <div className="row">
                  <div className="col-md-10">
                    <div class="cp-input-field ">
                      <input
                        type="text"
                        placeholder="Coupon code"
                        name="couponCode"
                        value={localStorage.getItem("couponCode")}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="cp-cart-checkout-btn mt-2">
                      <button
                        type="button"
                        className="cp-border-btn"
                        onClick={() => {
                          removeCopon();
                        }}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <form
            onSubmit={(e) => {
              formsubmit3(e);
            }}
          >
            <div className="row wow fadeInUp animated" data-wow-duration="1.5s">
              <div className="col-md-8">
                <div className="cp-cart-left mb-80 mr-10">
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        className="row wow fadeInUp animated"
                        data-wow-duration="1.5s"
                      >
                        <div className="col-md-12">
                          <div className="cp-checkout-left mb-30 mr-10">
                            <div className="cp-checkout-field-area">
                              <div className="cp-checkout-box mb-30">
                                <h4 className="cp-checkout-title">
                                  Address Details
                                </h4>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="cp-input-field">
                                      <label htmlFor="name">First name </label>
                                      <input
                                        type="text"
                                        id="firstName"
                                        disibled
                                        placeholder="Enter First Name"
                                        name="firstName"
                                        value={form3.firstName}
                                      />
                                      <i className="far fa-user" />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="cp-input-field">
                                      <label htmlFor="lname">Last Name </label>
                                      <input
                                        type="text"
                                        id="lname"
                                        placeholder="Enter Last Name"
                                        name="lastName"
                                        disibled
                                        value={form3.lastName}
                                      />
                                      <i className="far fa-user" />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="cp-input-field">
                                      <label htmlFor="phone">
                                        Phone Number
                                      </label>
                                      <input
                                        type="text"
                                        name="name"
                                        placeholder="Enter Phone Number"
                                        value={phone}
                                        disibled
                                        id="phone"
                                      />
                                      <i className="far fa-phone" />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="cp-input-field">
                                      <label htmlFor="phone">
                                        Alternate Phone Number
                                      </label>
                                      <input
                                        type="text"
                                        name="phone2"
                                        placeholder="Enter Alternate Phone Number"
                                        value={form4.alternatePhone}
                                        id="alternatePhone"
                                      />
                                      <i className="far fa-phone" />
                                    </div>
                                  </div>

                                  <div className="col-lg-6">
                                    <div className="cp-input-field">
                                      <label htmlFor="email">Email</label>
                                      <input
                                        placeholder="Enter Email "
                                        type="email"
                                        name="email"
                                        disabled
                                        value={form3.email}
                                        id="email"
                                      />
                                      <i className="far fa-envelope-open" />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="cp-input-field">
                                      <label htmlFor="LandMark">LandMark</label>
                                      <input
                                        type="text"
                                        disibled
                                        name="landmark"
                                        placeholder="Enter LandMark"
                                        value={form4.landmark}
                                        id="landmark"
                                      />
                                      <i className="far fa-map-marker-alt" />
                                    </div>
                                  </div>
                                  <div className="col-lg-12">
                                    <div className="cp-input-field">
                                      <label htmlFor="address">Address</label>
                                      <input
                                        type="text"
                                        placeholder="Enter Address"
                                        name="address"
                                        disibled
                                        value={form4.address}
                                        id="address"
                                      />
                                      <i className="far fa-map-marker-alt" />
                                    </div>
                                  </div>

                                  <div className="col-lg-6">
                                    <div className="cp-input-field">
                                      <label htmlFor="city">Town / City</label>
                                      <input
                                        type="text"
                                        name="city"
                                        placeholder="Town / City"
                                        disibled
                                        value={form4.city}
                                        id="city"
                                      />
                                      <i className="far fa-city" />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="cp-input-field">
                                      <label htmlFor="zip">Pin Code </label>
                                      <input
                                        type="text"
                                        name="pincode"
                                        placeholder="ENTER Pin Code"
                                        disibled
                                        value={form4.pincode}
                                        id="pincode"
                                      />
                                      <i className="far fa-file-archive" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 pt-80" style={{ background: "#F9F9F9" }}>
                <div className="card">
                  <div className="card-body">
                    <div className="m-3">
                      <h4 className="cp-cart-subtotal">Price Details</h4>
                      <div className="cp-cart-total d-flex align-items-center justify-content-between mb-20">
                        <h5>Price ({Products.length} Item)</h5>
                        <span>₹ {Price.subTotal}</span>
                      </div>
                      <div className="cp-cart-total d-flex align-items-center justify-content-between mb-20">
                        <h5>Discount</h5>
                        <span style={{ color: "#0FF90A" }}>
                          -₹
                          {couponAmount == "" ||
                          couponAmount == null ||
                          couponAmount == undefined ? (
                            <>0</>
                          ) : (
                            <>{couponAmount}</>
                          )}
                        </span>
                      </div>
                      <div className="cp-cart-free d-flex align-items-center justify-content-between">
                        <h5>Delivery Charges</h5>
                        <span>₹ {Price.deliveryCharges} </span>
                      </div>
                      <div className="cp-cart-f-total d-flex align-items-center justify-content-between mb-20">
                        <h6>Total</h6>
                        <span>
                          ₹ {Price.deliveryCharges + Number(productsvalueprice)}
                        </span>
                      </div>
                      <small style={{ color: "#0FF90A" }}>
                        You save ₹{" "}
                        {couponAmount == "" ||
                        couponAmount == null ||
                        couponAmount == undefined ? (
                          <>0</>
                        ) : (
                          <>{couponAmount}</>
                        )}{" "}
                        on this order
                      </small>

                      <div className="cp-cart-checkout-btn mt-30">
                        <button
                          type="submit"
                          className="cp-border-btn"
                          style={{ width: "100%" }}
                        >
                          Pay & Place order
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
      <ToastContainer />
      <Footer />
    </div>
  );
}

export default Cart;
