import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

function Contactus() {
  const [isLoading, setIsLoading] = useState(true);

  const [Contact, setContact] = useState([]);

  useEffect(() => {
    datas();
  }, []);

  const api = "https://api.odcards.com/";

  const datas = () => {
    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setContact(res.data.contactus[0]);
            setIsLoading(false);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("Data was not getting");
          }
        }
      );
  };

  const [form, setform] = useState([]);

  const formsubmit = (e) => {
    e.preventDefault();
    Add();
  };

  const handleChange = (e) => {
    let myUser = { ...form };
    myUser[e.target.name] = e.target.value;
    setform(myUser);
  };

  const Add = () => {
    const dataArray = {
      firstName: form.firstName,
      lastName: form.lastName,
      email: form.email,
      mobileNumber: form.mobileNumber,
      description: form.description,
    };

    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/enquiry/addenquiry",
        dataArray
      )
      .then(
        (res) => {
          if (res.status === 200) {
            toast("will get back to you");
            setform({
              firstName: "",
              lastName: "",
              email: "",
              mobileNumber: "",
              description: "",
            });
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  return (
    <div>
      {isLoading == true ? (
        <>
          <div id="preloader">
            <div id="ctn-preloader" class="ctn-preloader">
              <div class="animation-preloader">
                <div class="spinner"></div>
                <div class="txt-loading">
                  <span data-text-preloader="O" class="letters-loading">
                    O
                  </span>
                  <span data-text-preloader="D" class="letters-loading">
                    D
                  </span>
                  <span data-text-preloader="C" class="letters-loading">
                    C
                  </span>
                  <span data-text-preloader="A" class="letters-loading">
                    A
                  </span>
                  <span data-text-preloader="R" class="letters-loading">
                    R
                  </span>
                  <span data-text-preloader="D" class="letters-loading">
                    D
                  </span>
                  <span data-text-preloader="S" class="letters-loading">
                    S
                  </span>
                </div>
              </div>
              <div class="loader">
                <div class="row">
                  <div class="col-3 loader-section section-left">
                    <div class="bg"></div>
                  </div>
                  <div class="col-3 loader-section section-left">
                    <div class="bg"></div>
                  </div>
                  <div class="col-3 loader-section section-right">
                    <div class="bg"></div>
                  </div>
                  <div class="col-3 loader-section section-right">
                    <div class="bg"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <Header />
          <main>
            <section
              className="page-title-area breadcrumb-spacing cp-bg-14"
              id="fdd"
            >
              <div className="container">
                <div className="row justify-content-start">
                  <div className="col-md-5 col-sm-5">
                    <div
                      className="page-title-wrapper t-center blurred"
                      id="tor3"
                    >
                      <h2 className="mb-10 text-white" id="tor">
                        Contact Us
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="cp-contact-area pt-50 pb-30">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div
                      className="cp-contact-content wow fadeInUp animated"
                      data-wow-duration="1.5s"
                    >
                      <h3 className="cp-contact-title mb-20 pt-50">
                        Contact Us
                      </h3>

                      <div className="cp-contact-info mb-50">
                        <ul>
                          <li>
                            <i className="far fa-phone-alt" />
                            <a href={`tel:${Contact.contactNumber1}`}>
                              {Contact.contactNumber1}, {Contact.contactNumber2}
                            </a>
                          </li>

                          <li>
                            <i className="fal fa-envelope" />
                            <a href={`mailto:${Contact.email}`}>
                              {" "}
                              {Contact.email}
                            </a>
                          </li>
                          <li>
                            <i className="fal fa-home-lg-alt" />
                            <a
                              target="_blank"
                              href="https://www.google.com/maps/@23.7739014,90.3640911,17z"
                            >
                              {Contact.address}
                            </a>
                          </li>
                        </ul>
                      </div>

                      <h4 class="cp-contact-subtitle">Administrative Hours</h4>
                      <p class="cp-contact-text mb-50">
                        We’re available from {Contact.fromTime} –{" "}
                        {Contact.toTime}, <br />
                        06 days a week.
                      </p>

                      <div class="cp-footer-social">
                        <ul>
                          <li>
                            <a target="_blank" href={Contact.facebook}>
                              facebook <i class="fab fa-facebook-f"></i>
                            </a>
                          </li>
                          <li>
                            <a target="_blank" href={Contact.twitter}>
                              Twitter <i class="fab fa-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a target="_blank" href={Contact.instagram}>
                              Instagram <i class="fab fa-instagram"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-6 p-4"
                    style={{ background: "#F9F9F9" }}
                  >
                    <div
                      className="cp-contact-form-wrap mb-20 wow fadeInUp animated"
                      data-wow-duration="1.5s"
                    >
                      <div className="cp-contact-form">
                        <form
                          onSubmit={(e) => {
                            formsubmit(e);
                          }}
                        >
                          <div className="row">
                            <div className="col mt-3">
                              <div className="cp-input-field">
                                <input
                                  type="text"
                                  required=""
                                  id="name"
                                  placeholder="First Name"
                                  name="firstName"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  value={form.firstName}
                                />
                              </div>
                            </div>
                            <div className="col mt-3">
                              <div className="cp-input-field">
                                <input
                                  type="text"
                                  required=""
                                  id="name"
                                  placeholder="Last Name"
                                  name="lastName"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  value={form.lastName}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="cp-input-field">
                            <input
                              type="text"
                              required=""
                              id="email"
                              placeholder="Email"
                              name="email"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={form.email}
                            />
                          </div>
                          <div className="cp-input-field">
                            <input
                              type="text"
                              maxlength="10"
                              minLength="10"
                              pattern="[0-9]{10}"
                              required=""
                              id="email"
                              placeholder="Mobile Number"
                              name="mobileNumber"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={form.mobileNumber}
                            />
                          </div>

                          <div className="cp-input-field">
                            <textarea
                              className="form-control "
                              id="ous"
                              placeholder="Description"
                              rows={8}
                              name="description"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={form.description}
                              style={{background:"#E6E9EC"}}
                            />
                          </div>
                          <button type="submit" className="cp-border-btn mt-15">
                            Submit
                            <span className="cp-border-btn__inner">
                              <span className="cp-border-btn__blobs">
                                <span className="cp-border-btn__blob" />
                                <span className="cp-border-btn__blob" />
                                <span className="cp-border-btn__blob" />
                                <span className="cp-border-btn__blob" />
                              </span>
                            </span>
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="et-contact-map" style={{ marginBottom: "20px" }}>
              <iframe
                src={Contact.map}
                width={600}
                height={450}
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </main>
          <Footer />
        </>
      )}
      <ToastContainer />
    </div>
  );
}

export default Contactus;
