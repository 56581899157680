import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Header() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const [show, setshow] = useState(false);

  const shows1 = show == true ? "side-info info-open" : "side-info";

  const shows =
    show == true ? "offcanvas-overlay overlay-open" : "offcanvas-overlay ";

  const [ProductsCat, setProductsCat] = useState([]);

  const [ProductsSubCat, setProductsSubCat] = useState([]);

  const [ProductsSubCat1, setProductsSubCat1] = useState([]);

  const [Contact, setContact] = useState([]);

  useEffect(() => {
    datas();
    const userid = sessionStorage.getItem("token");
    if (userid != null) {
      getCart();
    }
  }, []);

  const tokens = sessionStorage.getItem("token");

  const getCart = () => {
    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/cart/getallcarts",
        {},
        {
          headers: { Authorization: `Bearer ${tokens}` },
        }
      )
      .then(
        (res) => {
          if (res.status === 200) {
            const productlength = res.data.cartitems.length;
            const productlengths = parseFloat(productlength);
            sessionStorage.setItem("cartitems", productlengths);
            if (res.data.cartitems.length == 0) {
              sessionStorage.setItem("cartitems", "0");
            }
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("Data was not getting");
          }
        }
      );
  };

  const datas = () => {
    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/getallmodules",
        {},
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setProductsCat(res.data.categorys);
            setContact(res.data.contactus[0]);
            axios
              .post(
                "https://api.odcards.com/v1/odcardsapi/web/getsubcategorydetails",
                { categoryId: res.data?.categorys[0]?._id },
                {}
              )
              .then(
                (res) => {
                  if (res.status === 200) {
                    setProductsSubCat(res.data.subcategory);
                  }
                },
                (error) => {
                  if (error.response && error.response.status === 400) {
                    console.log("Data was not getting");
                  }
                }
              );
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("Data was not getting");
          }
        }
      );
  };

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const [activeTabIndexs, setActiveTabIndexs] = useState(0);

  const getproducts = (data, i) => {
    setActiveTabIndexs(i);
    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/getsubcategorydetails",
        { categoryId: data._id },
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setProductsSubCat(res.data.subcategory);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("Data was not getting");
          }
        }
      );
  };

  const [showes, setShowes] = useState([]);

  const handleToggle = (index, data) => {
    setShowes((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));

    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/getsubcategorydetails",
        { categoryId: data._id },
        {}
      )
      .then(
        (res) => {
          if (res.status === 200) {
            setProductsSubCat1(res.data.subcategory);
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            console.log("Data was not getting");
          }
        }
      );
  };

  const [showd, setShowd] = useState(false);

  var navigate = useNavigate();

  const [form1, setform1] = useState([]);

  const handleClose = () => setShowd(false);
  const handleShow = () => setShowd(true);

  const handleChange = (e) => {
    let myUser = { ...form1 };
    myUser[e.target.name] = e.target.value;
    setform1(myUser);
  };

  const databyid = (data) => {
    setform1(data);
    handleShow();
  };

  const formsubmit = (e) => {
    e.preventDefault();
    const uuid = Date.now();
    sessionStorage.setItem("uuid", parseFloat(uuid));
    Add();
  };

  const Add = () => {
    const dataArray = {
      uniqueid: sessionStorage.getItem("uuid"),
      productId: form1._id,
      orders: form1.nooforders,
    };

    axios
      .post(
        "https://api.odcards.com/v1/odcardsapi/web/addcartitem",
        dataArray
      )

      .then(
        (res) => {
          if (res.status === 200) {
            sessionStorage.setItem("cartitems", "0");
            sessionStorage.setItem("subproductid", form1._id);
            window.location.href = "/productdetails";
            // navigate(
            //   "/productdetails",
            //   sessionStorage.setItem("subproductid", form1._id)
            // );
          }
        },
        (error) => {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message);
          }
        }
      );
  };

  const userid = sessionStorage.getItem("token");

  const api = "https://api.odcards.com/";

  const carts = sessionStorage.getItem("cartitems");

  return (
    <>
      <div
        className="mouseCursor cursor-outer d-none cursor-hover"
        style={{ visibility: "visible", transform: "translate(43px, 78px)" }}
      />
      <div
        className="mouseCursor cursor-inner d-none cursor-hover"
        style={{ visibility: "visible", transform: "translate(43px, 78px)" }}
      >
        <span>Drag</span>
      </div>

      <header>
        <div className="cp-header2">
          <div
            id="menu-show-hide"
            className="cp-header2-bottom mobile-space white-bg"
          >
            <div className="container-fluid">
              <div className="cp-header2-bottom-wrap">
                <div className="cp-header2-bottom-item">
                  <div className="logo">
                    <NavLink to="/">
                      <img src={api + Contact.logo} alt="logo" />
                    </NavLink>
                  </div>
                </div>
                <div className="cp-header2-bottom-item">
                  <div className="main-menu main-menu1">
                    <nav id="mobile-menu" className="loops">
                      <ul>
                        <li>
                          <NavLink to="/">Home </NavLink>
                        </li>
                        <li className="menu-item-has-children">
                          <a href="#">Products</a>
                          <ul className="sub-menu" style={{ width: "1160px" }}>
                            <div class="container">
                              <div
                                class="row wow fadeInUp animated mt-30"
                                data-wow-duration="1.5s"
                              >
                                <div className="col-md-4">
                                  <div className="mb-70">
                                    <div className="">
                                      <div className="cp-faq2-cat-list">
                                        <div
                                          className="nav"
                                          id="v-pills-tab"
                                          role="tablist"
                                          aria-orientation="vertical"
                                        >
                                          {ProductsCat.map((data, i) => (
                                            <>
                                              <span>
                                                <i
                                                  className={`fas ${
                                                    i === activeTabIndexs
                                                      ? "fa-angle-left text-danger"
                                                      : "fa-angle-right"
                                                  }`}
                                                  aria-hidden="true"
                                                  style={{ float: "right" }}
                                                ></i>
                                              </span>

                                              <button
                                                className={`nav-link ${
                                                  i === activeTabIndex
                                                    ? "active"
                                                    : ""
                                                }`}
                                                id={`v-pills-${data.name.toLowerCase()}-tab`}
                                                data-bs-toggle="pill"
                                                data-bs-target={`#v-pills-${data.name.toLowerCase()}`}
                                                type="button"
                                                role="tab"
                                                aria-controls={`v-pills-${data.name.toLowerCase()}`}
                                                aria-selected={
                                                  i === activeTabIndex
                                                    ? "true"
                                                    : "false"
                                                }
                                                key={i}
                                                onClick={() => {
                                                  getproducts(data, i);
                                                }}
                                              >
                                                {data.name}
                                              </button>

                                              <hr></hr>
                                            </>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-8">
                                  <div className="mb-70">
                                    <div
                                      className="tab-content"
                                      id="v-pills-tabContent"
                                    >
                                      {ProductsCat.map((data, i) => (
                                        <div
                                          className={`tab-pane fade show ${
                                            i === 0 ? "active" : ""
                                          }`}
                                          id={`v-pills-${data.name.toLowerCase()}`}
                                          role="tabpanel"
                                          aria-labelledby={`v-pills-${data.name.toLowerCase()}-tab`}
                                        >
                                          <div className="cp-faq2-right-content-wrap">
                                            <div className="row">
                                              {ProductsSubCat?.map(
                                                (data, i) => (
                                                  <>
                                                    <div
                                                      className="col-md-4 mt-3"
                                                      key={i}
                                                    >
                                                      <h5
                                                        className="text-danger"
                                                        style={{
                                                          paddingLeft: "20px",
                                                          paddingBottom: "10px",
                                                        }}
                                                      >
                                                        {data.products.length ==
                                                        activeTabIndex ? (
                                                          <></>
                                                        ) : (
                                                          <> {data.name}</>
                                                        )}
                                                      </h5>
                                                      {data.products?.map(
                                                        (datas1, is) => (
                                                          <ul>
                                                            <li
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                databyid(
                                                                  datas1
                                                                );
                                                              }}
                                                              key={is}
                                                            >
                                                              {datas1.name}
                                                            </li>
                                                          </ul>
                                                        )
                                                      )}
                                                    </div>
                                                  </>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ul>
                        </li>

                        <li>
                          <NavLink to="/offers">Offers</NavLink>
                        </li>

                        {userid != null ? (
                          <li>
                            <NavLink to="/plans">Plans</NavLink>
                          </li>
                        ) : (
                          ""
                        )}

                        <li>
                          <NavLink to="/aboutus">About Us</NavLink>
                        </li>

                        <li>
                          <NavLink to="/faqs">Faq's</NavLink>
                        </li>

                        <li>
                          <NavLink to="/contactus">Contact</NavLink>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>

                <div className="cp-header2-bottom-item">
                  <div className="cp-header2-action d-flex align-items-center justify-content-end ">
                    <div className="d-none d-md-block">
                      <ul>
                        {" "}
                        <li>
                          <NavLink
                            to="/searchproduct"
                            class="cp-search-btn"
                            id="rcorners3"
                            title="Calculator"
                          >
                            <i class="fas fa-calculator"></i>
                          </NavLink>{" "}
                        </li>
                        <li>
                          <NavLink to="/cart" id="rcorners3">
                            <i className="fa fa-shopping-bag" title="Cart" />
                            {carts == "0" || carts == null ? (
                              <> </>
                            ) : (
                              <span>{sessionStorage.getItem("cartitems")}</span>
                            )}
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/myprofile"
                            id="rcorners3"
                            title="MyAccount"
                          >
                            <i className="fas fa-user-alt" />
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                    <div className="cp-header-toggle-btn ml-35 mt--5 d-xl-none">
                      <ul>
                        <li>
                          <NavLink to="/searchproduct" title="Calculator">
                            <i className="fas fa-calculator" />
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/cart" title="Cart">
                            <i className="fa fa-shopping-bag" />
                            {carts == "0" || carts == null ? (
                              <> </>
                            ) : (
                              <span>{sessionStorage.getItem("cartitems")}</span>
                            )}
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/myprofile" title="MyAccount">
                            <i className="fas fa-user-alt" />
                          </NavLink>
                        </li>
                        <a
                          className="side-toggle menu-bar"
                          href="javascript:void(0)"
                          onClick={() => {
                            setshow(!show);
                          }}
                        >
                          <div className="bar-icon">
                            <span />
                            <span />
                            <span />
                          </div>
                        </a>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="fix">
        <div className={shows1}>
          <div className="side-info-content">
            <div className="offset-widget offset-logo mb-50">
              <div className="row align-items-center">
                <div className="col-9">
                  <img src={api + Contact.logo} alt="logo" />
                </div>
                <div className="col-3 text-end">
                  <button
                    className="side-info-close"
                    onClick={() => {
                      setshow(false);
                    }}
                  >
                    <i className="fal fa-times" />
                  </button>
                </div>
              </div>
            </div>
            <div className="mobile-menu2 fix mean-container">
              <div className="mean-bar">
                <a
                  href="#nav"
                  className="meanmenu-reveal"
                  style={{ right: 0, left: "auto", display: "inline" }}
                >
                  <span>
                    <span>
                      <span />
                    </span>
                  </span>
                </a>
                <nav className="mean-nav">
                  <ul style={{ display: "none" }}>
                    <li className="mean-last">
                      <NavLink to="/">Home </NavLink>
                    </li>

                    {ProductsCat.map((data, i) => (
                      <li className="menu-item-has-children" key={data._id}>
                        <a href="#">{data.name}</a>
                        <ul
                          className="sub-menu"
                          style={{ display: showes[i] ? "block" : "none" }}
                        >
                          {" "}
                          {ProductsSubCat1.map((datas) => (
                            <>
                              {datas.products?.map((datas1) => (
                                <li
                                  style={{ cursor: "pointer" }}
                                  key={datas1._id}
                                  onClick={() => {
                                    databyid(datas1);
                                  }}
                                >
                                  <a>{datas1.name}</a>
                                </li>
                              ))}
                            </>
                          ))}
                        </ul>
                        <a
                          className="mean-expand"
                          onClick={() => handleToggle(i, data)}
                          style={{ fontSize: 18 }}
                        >
                          <i
                            className={`fal ${
                              showes[i] ? "fa-minus" : "fa-plus"
                            }`}
                          />
                        </a>
                      </li>
                    ))}

                    <li className="mean-last">
                      <NavLink to="/offers">Offers</NavLink>
                    </li>

                    {userid != null ? (
                      <li className="mean-last">
                        <NavLink to="/plans">Plans</NavLink>
                      </li>
                    ) : (
                      ""
                    )}

                    <li className="mean-last">
                      <NavLink to="/aboutus">About Us</NavLink>
                    </li>

                    <li className="mean-last">
                      <NavLink to="/faqs">Faq's</NavLink>
                    </li>

                    <li className="mean-last">
                      <NavLink to="/contactus">Contact</NavLink>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="offset-widget offset-support mb-30">
              <h3 className="cp-offset-widget-title">Contact Info</h3>
              <div className="footer-support">
                <div className="irc-item support-meta">
                  <div className="irc-item-icon">
                    <i className="fal fa-phone-alt" />
                  </div>
                  <div className="irc-item-content">
                    <p>Call Now</p>
                    <div className="support-number">
                      <a href={`tel:${Contact.contactNumber1}`}>
                        {" "}
                        {Contact.contactNumber1}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="irc-item support-meta">
                  <div className="irc-item-icon">
                    <i className="fal fa-envelope" />
                  </div>
                  <div className="irc-item-content">
                    <p>Mail Us</p>
                    <div className="support-number">
                      <a href={`mailto:${Contact.email}`}> {Contact.email}</a>
                    </div>
                  </div>
                </div>
                <div className="irc-item support-meta">
                  <div className="irc-item-icon">
                    <i className="fal fa-map-marker-alt" />
                  </div>
                  <div className="irc-item-content">
                    <p>Location</p>
                    <div className="support-number">
                      <a href="#" target="_blank">
                        {Contact.address}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={shows} />
      <div className="offcanvas-overlay-white" />

      <Modal
        show={showd}
        onHide={handleClose}
        size="md"
        centered
        style={{ zIndex: "99999999999999999999999999999999999999999999" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>No Of Orders </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form
            onSubmit={(e) => {
              formsubmit(e);
            }}
          >
            <div className="col-md-12 mt-20">
              <select
                name="nooforders"
                onChange={(e) => {
                  handleChange(e);
                }}
                value={form1.nooforders}
                required
                className="form-select mt-30"
              >
                <option value="">Select </option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>

            <div className="cp-input-field sign-flex checkbox d-flex align-items-center justify-content-between"></div>
            <div className="cp-sign-btn mt-35 mb-30">
              <button
                type="submit"
                className="cp-border-btn"
                style={{ width: "100%" }}
              >
                Proceed
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Header;
